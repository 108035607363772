@charset "UTF-8";
@font-face { font-family: "Helvetica Neue"; src: local("Helvetica Neue"); unicode-range: U+0020; }

@font-face { font-family: "Arial"; src: local("Arial"); unicode-range: U+0020; }

/* noto-sans-kr-regular - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 400; src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-500 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 500; src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* noto-sans-kr-700 - latin_korean */
@font-face { font-family: "Noto Sans KR"; font-style: normal; font-weight: 700; src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-regular - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 400; src: local("Roboto"), local("Roboto-Regular"), url("/css/fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* roboto-700 - latin */
@font-face { font-family: "Roboto"; font-style: normal; font-weight: 700; src: local("Roboto Bold"), local("Roboto-Bold"), url("/css/fonts/roboto-v20-latin-700.woff2") format("woff2"), url("/css/fonts/roboto-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

@font-face { font-family: "11StreetGothic"; font-style: normal; font-weight: 400; src: url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff"); }

@font-face { font-family: "11StreetGothic"; src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff"); font-style: normal; font-weight: 700; }

@font-face { font-family: "11StreetGothicBold"; font-weight: bold; src: url("/css/fonts/11STREET_Gothic_bold.eot"); src: url("/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("/css/fonts/11STREET_Gothic_bold.ttf") format("truetype"); }

/* new lato-regular - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 400; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-700 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 700; src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

/* new lato-900 - latin */
@font-face { font-family: "Lato New"; font-style: normal; font-weight: 900; src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; }

*, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button { margin: 0; padding: 0; }

html { overflow-y: auto; width: 100%; height: 100%; background: #eee; }

body { width: 100%; height: 100%; }

dl, ul, ol, menu, li { list-style: none; }

img, fieldset, iframe { border: 0 none; }

img { vertical-align: top; }

input, select, textarea, button { vertical-align: middle; }

input::-ms-clear { display: none; }

button { border: 0 none; background-color: transparent; cursor: pointer; border-radius: 0; box-sizing: border-box; }

table { border-collapse: collapse; border-spacing: 0; empty-cells: show; }

caption, legend { position: absolute; top: -9999px; left: -9999px; font-size: 0px; line-height: 0; }

a { color: #666; text-decoration: none; }

a:hover { text-decoration: underline; }

a:active { background-color: transparent; }

body, h1, h2, h3, h4, th, td, input, select, textarea, button { font-size: 14px; line-height: 1.5; font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", Arial, "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif; color: #666; letter-spacing: -1px; }

hr { display: none; }

address, caption, cite, code, dfn, em, var { font-style: normal; font-weight: normal; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after { content: ""; content: none; }

q:before, q:after { content: ""; content: none; }

/* max-width: 1530px */
@media (max-width: 1530px) { .main-box__col:nth-child(1):nth-last-child(2), .main-box__col:nth-child(1):nth-last-child(2) ~ div { width: 100% !important; }
  .main-box__col:nth-child(1):nth-last-child(3), .main-box__col:nth-child(1):nth-last-child(3) ~ div { width: 50% !important; }
  .main-box__col:nth-child(1):nth-last-child(3).col__2, .main-box__col:nth-child(1):nth-last-child(3) ~ div.col__2 { width: 100% !important; }
  .main-box__col:nth-child(1):nth-last-child(4), .main-box__col:nth-child(1):nth-last-child(4) ~ div { width: 50% !important; } }

.main-tit .btn-link, .main-edu .main-edu__apply .top .link .btn-link { position: relative; margin-left: 23px; color: #999; font-size: 14px; letter-spacing: -1.2px; line-height: 24px; }

.main-tit .btn-link:after, .main-edu .main-edu__apply .top .link .btn-link:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 5px; }

.main-edu .main-edu__apply .top .title { color: #333; font-size: 15px; font-weight: bold; line-height: 22px; letter-spacing: -1.2px; }

/* main layout */
.main-box__col:nth-child(1):nth-last-child(2), .main-box__col:nth-child(1):nth-last-child(2) ~ div { width: 50%; }

.main-box__col:nth-child(1):nth-last-child(3), .main-box__col:nth-child(1):nth-last-child(3) ~ div { width: 25%; }

.main-box__col:nth-child(1):nth-last-child(3).col__2, .main-box__col:nth-child(1):nth-last-child(3) ~ div.col__2 { width: 50%; }

.main-box__col:nth-child(1):nth-last-child(4), .main-box__col:nth-child(1):nth-last-child(4) ~ div { width: 25%; }

.main_wrap { padding: 0; min-width: 956px; }

.main_wrap .inquiry-area { position: relative; z-index: 1; }

.main_wrap .inquiry-area .calculate .info { position: absolute; top: -15px; left: -20px; width: 210px; height: 34px; font-size: 11px; font-weight: bold; line-height: 28px; color: #ff0038; letter-spacing: -0.5px; text-align: center; z-index: 2; background: url("/img/selleroffice/bg_info_tooltip.png") no-repeat 2px 0; background-size: 100% auto; }

.main_wrap .btn-inquiry { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -143px -164px; width: 22px; height: 22px; background-size: 219px 188px; line-height: 200px; overflow: hidden; }

.main-box { display: block; width: 100%; font-size: 0; }

.main-box__col { display: inline-block; width: 100%; padding: 6px 6px; font-size: 13px; vertical-align: top; }

.main-box__col > div { position: relative; z-index: 1; border-radius: 2px; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); background-color: #fff; }

.main-box__col > div > .main-spinner { top: 60px; height: calc(100% - 60px); }

/* main common */
.main-tit { width: calc(100% - 60px); margin: 0 auto; zoom: 1; }

.main-tit:after { display: block; content: ""; clear: both; }

.main-tit .main-tit__title { float: left; }

.main-tit .main-tit__title .main-tooltip__btn { margin-bottom: -1px; }

.main-tit .main-tit__btn { position: relative; min-width: 99px; max-width: 55%; height: 24px; padding: 0 20px 0 10px; margin-left: 8px; border-radius: 13px; border: solid 1px rgba(244, 49, 66, 0.3); font-size: 12px; font-weight: bold; color: #ff0038; line-height: 22px; letter-spacing: 0px; }

.main-tit .main-tit__btn .count { display: inline-block; margin-left: 2px; max-width: 30%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-family: "Roboto", Tahoma, sans-serif; font-size: 15px; font-weight: bold; vertical-align: bottom; }

.main-tit .main-tit__btn:after { width: 6px; height: 6px; border: 1px solid #ff0038; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 10px; }

.main-tit .text { float: left; font-size: 18px; color: #111; font-weight: bold; line-height: 24px; letter-spacing: -1.4px; }

.main-tit .main-tit__right { float: right; }

.main-tit .main-tit__left { float: left; }

.main-tit .c-tooltip { vertical-align: middle; margin-left: 6px; }

.main-subtit { color: #333; font-size: 15px; font-weight: bold; line-height: 22px; letter-spacing: -1.2px; }

.main-tab { width: 100%; height: 43px; font-size: 0; zoom: 1; }

.main-tab:after { display: block; content: ""; clear: both; }

.main-tab li { position: relative; z-index: 1; float: left; height: 43px; border: 1px solid #eee; border-left: none; background-color: #fafafa; }

.main-tab li:first-child { border-left: none; }

.main-tab li:last-child { border-right: none; }

.main-tab li > a, .main-tab li > button { width: 100%; display: block; text-align: center; font-size: 14px; line-height: 43px; letter-spacing: -1.2px; }

.main-tab li > a:hover, .main-tab li > button:hover { text-decoration: none; }

.main-tab .active { z-index: 2; border-bottom: 1px solid #fff; background-color: #fff; }

.main-tab .active a, .main-tab .active button { color: #0b83e6; font-weight: bold; }

.main-box-tab { height: 42px; font-size: 0; zoom: 1; }

.main-box-tab:after { display: block; content: ""; clear: both; }

.main-box-tab li { position: relative; z-index: 1; float: left; height: 42px; border: 1px solid #eee; background-color: #fafafa; }

.main-box-tab li + li { margin-left: -1px; }

.main-box-tab li > button { width: 100%; display: block; text-align: center; font-size: 14px; letter-spacing: -1.2px; padding: 0 24px 2px; height: 42px; }

.main-box-tab li > button:hover { text-decoration: none; }

.main-box-tab .active { z-index: 2; border: 1px solid #92c8f3; background-color: #fff; }

.main-box-tab .active a, .main-box-tab .active button { color: #0b83e6; font-weight: bold; }

.main-info__1 { width: 100%; min-height: 44px; padding: 0 12px; margin-top: 8px; background-color: #f4f4f4; border-radius: 2px; color: #333; font-size: 12px; text-align: center; letter-spacing: -1.2px; line-height: 44px; }

.main-info__1 + .main-info__1 { margin-top: 12px; }

.main-info__1 .link { position: relative; z-index: 1; color: #0b83e6; font-size: 12px; margin-left: 6px; padding-right: 10px; }

.main-info__1 .link:after { display: inline-block; width: 6px; height: 6px; border: 1px solid #0b83e6; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); }

.main-info__1 .text { display: inline-block; max-width: 51%; padding: 0 3px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; vertical-align: middle; }

.main-info__1 .text + .link { display: inline-block; max-width: 40%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; vertical-align: middle; }

.main-info__1 .point { color: #0b83e6; }

.main-info__1 .vc { line-height: 1.5; }

.main-info__2 { position: relative; z-index: 1; width: 100%; min-height: 74px; padding: 17px 96px 17px 16px; margin-top: 14px; background-color: #f4f4f4; border-radius: 2px; color: #333; font-size: 13px; text-align: left; letter-spacing: -1.2px; line-height: 24px; }

.main-info__2 .link { position: absolute; top: 15px; right: 16px; z-index: 1; color: #0b83e6; font-size: 13px; line-height: 19px; }

.main-info__2 .link:after { display: inline-block; width: 6px; height: 6px; border: 1px solid #0b83e6; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 2px; }

.main-radio__tab { display: table; table-layout: fixed; }

.main-radio__tab .inp { position: absolute; display: inline-block; opacity: 0; width: 0; height: 0; }

.main-radio__tab .lab { display: table-cell; position: relative; z-index: 1; float: left; margin-left: -1px; padding: 3px 10px; min-width: 103px; border: 1px solid #ddd; background: #fff; font-size: 13px; line-height: 29px; text-align: center; text-overflow: ellipsis; white-space: nowrap; color: #333; cursor: pointer; overflow: hidden; }

.main-radio__tab .lab:first-child { margin-left: 0px; }

.main-radio__tab .active { z-index: 2; color: #0b83e6; border: 1px solid #0b83e6; }

.main-spinner { display: none; position: absolute; z-index: 3; top: 0; height: 0; width: 100%; height: 100%; font-size: 15px; color: #ff0038; letter-spacing: -1.2px; line-height: 22px; background: #fff; text-align: center; }

.main-spinner p { display: block; vertical-align: middle; }

.main-spinner p:last-child:before { display: inline-block; margin: -2px 10px 0 0; vertical-align: middle; overflow: hidden; content: ""; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -87px -164px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-spinner .loading { display: inline-block; vertical-align: middle; margin-top: 24px; }

.main-spinner.active { display: block; }

.main-tooltip { position: absolute; z-index: 50; box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24); background-color: #fff; }

.main-tooltip .tooltip-header { position: relative; z-index: 2; padding: 18px 49px 0px 20px; height: 60px; border-bottom: 1px solid #eee; text-align: center; background-color: #fff; }

.main-tooltip .tooltip-header .tooltip-header__title { font-weight: bold; font-size: 18px; color: #000; line-height: 1.44; letter-spacing: -0.6px; }

.main-tooltip .tooltip-body { padding: 20px 18px; }

.main-tooltip .tooltip-close { position: absolute; z-index: 2; top: 10px; right: 10px; width: 40px; height: 40px; overflow: hidden; line-height: 200px; }

.main-tooltip .tooltip-close:before, .main-tooltip .tooltip-close:after { position: absolute; top: 50%; left: 50%; width: 16px; height: 1px; background-color: #999; content: ""; }

.main-tooltip .tooltip-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.main-tooltip .tooltip-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.main-tooltip .tooltip-list { font-size: 14px; color: #111; line-height: 1.71; letter-spacing: -0.4px; }

.main-tooltip .tooltip-list li { position: relative; z-index: 1; padding-left: 14px; }

.main-tooltip .tooltip-list li:before { position: absolute; z-index: 1; left: 0; width: 14px; text-align: center; content: "•"; }

.main-tooltip p.tooltip-list { position: relative; z-index: 1; padding-left: 14px; font-size: 14px; color: #111; line-height: 1.71; letter-spacing: -0.4px; }

.main-tooltip p.tooltip-list:before { position: absolute; z-index: 1; left: 0; width: 14px; text-align: center; content: "•"; }

.main-tooltip__btn { margin-left: 5px; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -116px -164px; width: 22px; height: 22px; background-size: 219px 188px; overflow: hidden; line-height: 200px; }

.main-tooltip__btn--small { margin-left: 3px; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -198px -26px; width: 20px; height: 20px; background-size: 219px 188px; overflow: hidden; line-height: 200px; }

/* main content */
.main-seller { position: relative; z-index: 1; height: 90px; padding: 0 82px 0 35px; font-size: 0; zoom: 1; }

.main-seller:after { display: block; content: ""; clear: both; }

.main-seller .seller-profile { float: left; width: 27%; padding: 20px 0; }

.main-seller .seller-profile .seller-profile__noti { height: 28px; padding: 0 12px; position: absolute; z-index: 2; top: -20px; left: 35px; border-radius: 14px; background: rgba(0, 0, 0, 0.8); font-size: 12px; color: #fff; line-height: 26px; }

.main-seller .seller-profile .seller-profile__noti:after { position: absolute; left: 14px; bottom: -7px; width: 0; height: 0; content: ""; border-top: 7px solid rgba(0, 0, 0, 0.8); border-left: 6px solid transparent; border-right: 6px solid transparent; }

.main-seller .seller-profile .seller-profile__pic { position: relative; z-index: 1; float: left; width: 50px; height: 50px; }

.main-seller .seller-profile .seller-profile__pic .seller-pic { width: 50px; height: 50px; border-radius: 25px; overflow: hidden; }

.main-seller .seller-profile .seller-profile__pic .seller-pic .img { width: 50px; height: 50px; }

.main-seller .seller-profile .seller-profile__pic .store-admin { position: absolute; z-index: 1; right: -5px; bottom: -5px; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -163px -70px; width: 26px; height: 26px; background-size: 219px 188px; }

.main-seller .seller-profile .seller-profile__info { position: relative; float: left; width: 75%; padding-left: 20px; }

.main-seller .seller-profile .seller-profile__info .store-name { position: relative; display: inline-block; max-width: 100%; height: 27px; }

.main-seller .seller-profile .seller-profile__info .store-name .name { display: inline-block; max-width: 100%; height: 27px; padding-right: 12px; font-size: 18px; color: #111; font-weight: 500; text-align: left; letter-spacing: -1.3px; line-height: 27px; vertical-align: middle; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-seller .seller-profile .seller-profile__info .store-name:after { width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 2px; }

.main-seller .seller-profile .seller-profile__info .seller-profile__like { display: block; margin-top: 4px; color: rgba(102, 102, 102, 0.7); font-size: 15px; letter-spacign: -1.4px; }

.main-seller .seller-profile .seller-profile__info .seller-profile__like .count { margin-left: 3px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-seller .seller-profile .seller-profile__info .seller-profile__like .count.active { color: #0b83e6; }

.main-seller .seller-inquiry { float: left; width: 73%; min-height: 90px; position: relative; z-index: 1; }

.main-seller .seller-inquiry .list { width: 100%; height: 90px; display: inline-table; }

.main-seller .seller-inquiry .item { position: relative; z-index: 1; display: table-cell; width: 25%; min-width: 153px; height: 90px; padding: 0 5px; font-size: 15px; line-height: 40px; vertical-align: middle; text-align: center; color: #666; }

.main-seller .seller-inquiry .item:not(:first-child):after { display: inline-block; position: absolute; z-index: 1; top: 28px; left: 0; width: 1px; height: 34px; opacity: 0.44; background: #ddd; content: ""; }

.main-seller .seller-inquiry .item .title { display: inline-block; }

.main-seller .seller-inquiry .item .count { display: inline-block; margin-left: 18px; color: #ddd; font-family: "Roboto", Tahoma, sans-serif; font-size: 34px; line-height: 40px; vertical-align: top; }

.main-seller .seller-inquiry .item .count a { color: #ddd; }

.main-seller .seller-inquiry .item .count.active a { color: #ff0038; font-weight: bold; }

.main-seller .seller-inquiry .main-spinner p { display: inline-block; }

.main-seller .seller-inquiry .main-spinner .loading { margin-left: 18px; margin-top: 0; }

.main-seller .seller-inquiry:before, .main-seller .seller-inquiry:after { position: absolute; z-index: 1; top: 50%; right: 0; margin-top: -17px; width: 1px; height: 34px; opacity: 0.44; background-color: #eeeeee; content: ""; }

.main-seller .seller-inquiry:before { left: 0; }

.main-seller .btn-inquiry { position: absolute; z-index: 1; top: 50%; right: 30px; margin-top: -11px; }

.main-seller.ver2 { position: relative; z-index: 1; height: 150px; font-size: 0; padding: 0 0 0 25px; zoom: 1; }

.main-seller.ver2:after { display: block; content: ""; clear: both; }

.main-seller.ver2 .seller-profile { float: left; width: calc(25% - 30px); min-width: 220px; height: 150px; padding: 30px 10px 30px 0; border-right: 1px solid #eee; }

.main-seller.ver2 .seller-profile .seller-profile__noti { height: 28px; padding: 0 12px; position: absolute; z-index: 2; top: -20px; left: 50px; border-radius: 14px; background: rgba(0, 0, 0, 0.8); font-size: 12px; color: #fff; line-height: 26px; }

.main-seller.ver2 .seller-profile .seller-profile__noti:after { position: absolute; left: 14px; bottom: -7px; width: 0; height: 0; content: ""; border-top: 7px solid rgba(0, 0, 0, 0.8); border-left: 6px solid transparent; border-right: 6px solid transparent; }

.main-seller.ver2 .seller-profile .seller-profile__pic { position: relative; display: inline-block; width: 90px; height: 90px; }

.main-seller.ver2 .seller-profile .seller-profile__pic .seller-pic { width: 90px; height: 90px; border-radius: 45px; overflow: hidden; border: 1px solid rgba(0, 0, 0, 0.05); }

.main-seller.ver2 .seller-profile .seller-profile__pic .seller-pic .img { width: 90px; height: 90px; }

.main-seller.ver2 .seller-profile .seller-profile__pic .store-admin { position: absolute; z-index: 1; right: 0; bottom: 0; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -163px -70px; width: 26px; height: 26px; background-size: 219px 188px; }

.main-seller.ver2 .seller-profile .seller-profile__info { position: relative; float: left; top: 50%; -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); width: calc(100% - 90px); padding-left: 10px; }

.main-seller.ver2 .seller-profile .seller-profile__info .store-name { position: relative; display: inline-block; max-width: 100%; height: 27px; margin-bottom: 5px; }

.main-seller.ver2 .seller-profile .seller-profile__info .store-name .name { display: inline-block; max-width: 100%; height: 27px; padding-right: 12px; font-size: 18px; color: #111; font-weight: 500; text-align: left; letter-spacing: -1.3px; line-height: 27px; vertical-align: middle; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-seller.ver2 .seller-profile .seller-profile__info .store-name:after { width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 2px; }

.main-seller.ver2 .seller-profile .seller-profile__info .seller-profile__like { display: block; margin-top: 4px; color: rgba(102, 102, 102, 0.7); font-size: 15px; letter-spacing: -1.4px; }

.main-seller.ver2 .seller-profile .seller-profile__info .seller-profile__like .count { margin-left: 3px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-seller.ver2 .seller-profile .seller-profile__info .seller-profile__like .count.active { color: #0b83e6; }

.main-seller.ver2 .seller-inquiry { float: left; width: calc(75% - 64px); min-height: 90px; position: relative; z-index: 1; }

.main-seller.ver2 .seller-inquiry .list { width: 100%; height: 90px; display: inline-table; }

.main-seller.ver2 .seller-inquiry .item { position: relative; z-index: 1; display: table-cell; width: 25%; min-width: 153px; height: 90px; padding: 0; font-size: 15px; line-height: 40px; vertical-align: middle; text-align: center; color: #666; }

.main-seller.ver2 .seller-inquiry .item:not(:first-child):after { display: inline-block; position: absolute; z-index: 1; top: 28px; left: 0; width: 1px; height: 34px; opacity: 0.44; background: #ddd; content: ""; }

.main-seller.ver2 .seller-inquiry .item .title { display: inline-block; margin: 0 18px; }

.main-seller.ver2 .seller-inquiry .item .count { display: inline-block; margin: 0 18px; color: #ddd; font-family: "Roboto", Tahoma, sans-serif; font-size: 34px; line-height: 40px; vertical-align: top; max-width: 120px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-seller.ver2 .seller-inquiry .item .count a { color: #ddd; }

.main-seller.ver2 .seller-inquiry .item .count.active a { color: #ff0038; font-weight: bold; }

.main-seller.ver2 .seller-inquiry .main-spinner p { display: inline-block; }

.main-seller.ver2 .seller-inquiry .main-spinner .loading { margin-left: 18px; margin-top: 0; }

.main-seller.ver2 .seller-inquiry:before, .main-seller.ver2 .seller-inquiry:after { position: absolute; z-index: 1; top: 50%; right: 0; margin-top: -17px; width: 1px; height: 34px; opacity: 0.44; background-color: #eeeeee; content: ""; }

.main-seller.ver2 .seller-inquiry:before { left: 0; }

.main-seller.ver2 .btn-inquiry { position: absolute; z-index: 1; top: calc(50% - 29px); right: 30px; margin-top: -11px; }

.main-seller.ver2 .seller-11talk { position: relative; float: left; width: 76%; height: 59px; padding: 17px 0 17px 2%; border-top: 1px solid #fafafa; }

.main-seller.ver2 .seller-11talk .seller-11talk__title { display: inline-block; margin-right: 8px; vertical-align: bottom; font-size: 15px; font-family: "Roboto", Tahoma, sans-serif; font-weight: bold; letter-spacing: -1.38px; color: #000; }

.main-seller.ver2 .seller-11talk .seller-11talk__title::before { display: inline-block; margin: -2px 5px 0 0; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -170px -164px; width: 22px; height: 22px; background-size: 219px 188px; vertical-align: middle; content: ""; }

.main-seller.ver2 .seller-11talk .seller-11talk__time { display: inline-block; max-width: calc(100% - 60px); font-size: 13px; letter-spacing: -0.17px; color: #333; }

.main-seller.ver2 .seller-11talk .seller-11talk__time.on .state { background-color: #ff0038; }

.main-seller.ver2 .seller-11talk .seller-11talk__time.out { color: #bbb; }

.main-seller.ver2 .seller-11talk .seller-11talk__time.out .state { background-color: #bbb; }

.main-seller.ver2 .seller-11talk .seller-11talk__time.off .state { background-color: #333; }

.main-seller.ver2 .seller-11talk .seller-11talk__time .state { padding: 0 8px; margin-right: 2px; display: inline-block; vertical-align: middle; border-radius: 13px; text-align: center; color: #fff; line-height: 24px; }

.main-seller.ver2 .seller-11talk .seller-11talk__time .time { font-family: "Roboto", Tahoma, sans-serif; }

.main-seller.ver2 .seller-11talk .seller-11talk__time .btn-talk { font-size: 14px; letter-spacing: -1.4px; color: #333; vertical-align: middle; }

.main-seller.ver2 .seller-11talk .seller-11talk__time .btn-talk:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #111; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 2px; content: ""; }

.main-seller.ver2 .seller-11talk .seller-11talk__time .text { display: inline-block; margin-left: 14px; vertical-align: middle; font-weight: bold; font-size: 15px; letter-spacing: -1px; color: #333; }

.main-seller.ver2 .seller-11talk .seller-11talk__state { display: inline-block; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .state { display: inline-block; padding: 0 8px; margin-right: 2px; margin-left: 6px; display: inline-block; border-radius: 13px; text-align: center; line-height: 24px; font-size: 13px; background-color: #fff; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .state.level-1 { border: solid 1px rgba(55, 197, 144, 0.3); color: #37c590; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .state.level-2 { border: solid 1px rgba(248, 185, 7, 0.3); color: #f8b907; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .state.level-3 { border: solid 1px rgba(244, 49, 66, 0.3); color: #ff0038; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .grade { color: #666; letter-spacing: -1.5px; display: inline-block; font-size: 14px; }

.main-seller.ver2 .seller-11talk .seller-11talk__state .grade .number { font-family: "Roboto", Tahoma, sans-serif; font-size: 16px; font-weight: bold; color: #111; margin-left: 6px; }

.main-seller.ver2 .seller-11talk .btn-link { position: relative; z-index: 1; display: inline-block; width: 30px; height: 30px; margin: -14px 0 0 5px; overflow: hidden; line-height: 200px; vertical-align: middle; }

.main-seller.ver2 .seller-11talk .btn-link::after { display: block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 16px; }

.main-seller.ver2 .seller-11talk .link_guide { position: absolute; top: 20px; right: 12px; display: inline-block; font-size: 13px; color: #0b83e6; letter-spacing: -1px; }

.main-seller.ver2 .seller-11talk .link_guide::after { display: inline-block; margin: -1px -12px 0 0; width: 8px; height: 8px; border: 1px solid #0b83e6; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); }

.main-grade { min-height: 380px; max-height: 380px; padding: 30px 0 30px; zoom: 1; }

.main-grade:after { display: block; content: ""; clear: both; }

.main-grade .chart { width: calc(100% - 60px); margin: 40px auto 0; zoom: 1; }

.main-grade .chart:after { display: block; content: ""; clear: both; }

.main-grade .chart li { margin-bottom: 27px; zoom: 1; }

.main-grade .chart li:after { display: block; content: ""; clear: both; }

.main-grade .chart li:last-child { margin-bottom: 33px; }

.main-grade .chart li.level-1 .grade { color: #37c590; }

.main-grade .chart li.level-1 .bar_bg { border-color: #37c590; }

.main-grade .chart li.level-1 .bar { background-color: #37c590; }

.main-grade .chart li.level-2 .grade { color: #f8b907; }

.main-grade .chart li.level-2 .bar_bg { border-color: #f6c03a; }

.main-grade .chart li.level-2 .bar { background-color: #ffca44; }

.main-grade .chart li.level-3 .grade { color: #ff0038; }

.main-grade .chart li.level-3 .bar_bg { border-color: #d82c3b; }

.main-grade .chart li.level-3 .bar { background-color: #ff0038; }

.main-grade .chart .chart-grapic { float: left; width: 80%; height: 35px; }

.main-grade .chart .chart-grapic .title { font-size: 15px; color: #666; line-height: 22px; letter-spacing: -1.1px; }

.main-grade .chart .chart-grapic .bar { position: absolute; z-index: 1; top: 0; left: 0; display: block; height: 5px; border-radius: 3.5px; background: #999; border-color: #8b8b8b; }

.main-grade .chart .chart-grapic .bar_bg { position: relative; z-index: 1; width: 100%; height: 5px; margin-top: 8px; background-color: #ddd; border-radius: 3.5px; box-shadow: 0px 0px 0px 1px #d8d8d8 inset; overflow: hidden; }

.main-grade .chart .chart-grade { float: right; width: 20%; height: 35px; text-align: right; }

.main-grade .chart .chart-grade .grade { display: block; font-size: 14px; font-weight: bold; color: #999; }

.main-grade .chart .chart-grade .per { display: block; font-size: 12px; color: #333; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; font-weight: 500; }

.main-grade .info { width: calc(100% - 60px); margin: 0 auto; padding-top: 22px; color: #999; font-size: 12px; line-height: 18px; border-top: 1px solid #eee; }

.main-grade .info li { margin-top: 3px; }

.main-grade .info li:before { display: inline-block; width: 3px; height: 3px; background: #999; border-radius: 50%; content: ""; line-height: 18px; vertical-align: middle; margin-right: 5px; }

.main-grade .info li .date { font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; margin-left: 6px; }

.main-grade .noti { height: 75px; padding: 11px 30px; margin-top: 20px; border-top: 1px solid #f3ecce; border-bottom: 1px solid #f3ecce; background: #fffbe9; color: #999; line-height: 16px; font-size: 12px; letter-spacing: 0.4px; }

.main-grade .noti strong { display: block; margin-bottom: 4px; color: #000; font-size: 12px; line-height: 18px; letter-spacing: -0.4px; font-weight: bold; }

.main-grade .noti .vc { max-height: 53px; overflow: hidden; }

.main-grade .noti + .chart { margin-top: 8px; }

.main-grade .noti + .chart li { margin-bottom: 13px; }

.main-grade .noti ~ .info { padding-top: 11px; }

.main-order { min-height: 380px; max-height: 380px; padding-top: 30px; }

.main-order .inquiry-area { width: 100%; height: 321px; padding: 28px 30px 0; }

.main-order .inquiry-area .main-spinner { width: calc(100% - 60px); }

.main-order .list { width: 100%; }

.main-order .list li { width: 100%; padding: 12px 0; border-bottom: 1px solid #f5f5f5; zoom: 1; }

.main-order .list li:after { display: block; content: ""; clear: both; }

.main-order .list li:last-child { border-bottom: none; }

.main-order .list .title { position: relative; z-index: 1; float: left; padding-left: 46px; font-size: 15px; line-height: 30px; }

.main-order .list .title:before { position: absolute; z-index: 1; top: 0; left: 0; display: block; width: 30px; height: 30px; overflow: hidden; content: ""; }

.main-order .list .title.icon1:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -47px -95px; width: 30px; height: 30px; background-size: 219px 188px; }

.main-order .list .title.icon2:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -82px -95px; width: 30px; height: 30px; background-size: 219px 188px; }

.main-order .list .title.icon3:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -117px -95px; width: 30px; height: 30px; background-size: 219px 188px; }

.main-order .list .title.icon4:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -163px 0px; width: 30px; height: 30px; background-size: 219px 188px; }

.main-order .list .title.icon5:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -163px -35px; width: 30px; height: 30px; background-size: 219px 188px; }

.main-order .list .count { float: right; font-size: 18px; color: #0b83e6; font-weight: bold; line-height: 30px; letter-spacing: 0; }

.main-order .list .count a { font-family: "Roboto", Tahoma, sans-serif; color: #0b83e6; }

.main-request { min-height: 380px; max-height: 380px; padding: 30px 0 30px; }

.main-request .inquiry-area { width: 100%; height: 321px; padding: 27px 30px 0; }

.main-request .inquiry-area .main-spinner { width: calc(100% - 60px); }

.main-request .list li { width: 100%; padding: 26px 0; border-bottom: 1px solid #f5f5f5; zoom: 1; }

.main-request .list li:after { display: block; content: ""; clear: both; }

.main-request .list li a { display: block; }

.main-request .list li:last-child { border-bottom: none; }

.main-request .list li:nth-child(1):nth-last-child(4), .main-request .list li:nth-child(1):nth-last-child(4) ~ li { padding: 13px 0; }

.main-request .list .title { position: relative; z-index: 1; float: left; width: 60%; height: 44px; }

.main-request .list .text { display: block; font-weight: bold; font-size: 15px; line-height: 22px; color: #333; letter-spacing: -1.1px; }

.main-request .list .sub_text { display: block; font-size: 14px; line-height: 22px; color: #999; }

.main-request .list .sub_text a { display: inline-block; font-size: 14px; color: #999; font-weight: bold; margin-left: 5px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-request .list .sub_text .count { font-weight: bold; }

.main-request .list li > .count { width: 40%; float: right; color: #0b83e6; font-size: 18px; font-weight: bold; line-height: 30px; text-align: right; }

.main-request .list li > .count a { color: #0b83e6; font-size: 26px; font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; line-height: 44px; letter-spacing: 0; }

.main-request .list li > .count a:hover { text-decoration: underline; }

.main-cacl { min-height: 380px; max-height: 380px; padding: 30px 0 74px; }

.main-cacl .inquiry-area { width: calc(100% - 60px); margin: 23px auto 0; height: 298px; }

.main-cacl .calculate { width: 100%; position: relative; z-index: 1; height: 106px; }

.main-cacl .calculate dl { width: 100%; line-height: 52px; }

.main-cacl .calculate dl:after { position: absolute; top: 52px; left: 0; width: 100%; height: 1px; background-color: #eee; content: ""; }

.main-cacl .calculate dl dt { position: relative; float: left; width: 23%; color: #333; font-size: 15px; font-weight: bold; text-align: left; }

.main-cacl .calculate dl dd { float: right; width: 77%; text-align: right; }

.main-cacl .calculate dl dd a { color: #0b83e6; font-size: 18px; font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-cacl .calculate dl dd .unit { display: inline-block; margin-left: 2px; color: #0b83e6; font-size: 18px; line-height: 52px; }

.main-cacl .calculate .title { font-size: 15px; color: #666; letter-spacing: -1.2px; }

.main-cacl .calculate .title em { display: inline-block; font-weight: bold; }

.main-cacl .calculate .count { margin-top: 11px; display: block; color: #0b83e6; }

.main-cacl .calculate .count a { font-weight: bold; font-size: 26px; font-family: "Roboto", Tahoma, sans-serif; color: #0b83e6; line-height: 30px; letter-spacing: 0; }

.main-cacl .calculate .count .unit { font-size: 18px; margin-left: 4px; line-height: 30px; }

.main-cacl .list { width: 100%; padding: 14px 12px; background-color: #fafafa; zoom: 1; }

.main-cacl .list:after { display: block; content: ""; clear: both; }

.main-cacl .list li { zoom: 1; }

.main-cacl .list li:after { display: block; content: ""; clear: both; }

.main-cacl .list .c-tooltip { height: 22px; font-size: 0; vertical-align: middle; }

.main-cacl .list .title { float: left; width: 50%; color: #666; line-height: 22px; }

.main-cacl .list .text { display: inline-block; font-size: 14px; color: rgba(102, 102, 102, 0.8); line-height: 22px; vertical-align: middle; }

.main-cacl .list .count { float: right; width: 50%; padding-right: 1px; color: #333; font-size: 14px; text-align: right; line-height: 22px; }

.main-cacl .list .count a { font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; color: #333; letter-spacing: 0; }

.main-cacl .list .count .unit { display: inline-block; font-size: 14px; margin-left: 2px; line-height: 22px; }

.main-cacl .list .count .unit.point { font-weight: bold; }

.main-cacl .main-noti { margin-top: 6px; }

.main-cacl .btn-link { position: absolute; bottom: 30px; left: 0; width: 100%; height: 44px; border: solid 1px #ddd; background-color: #ffffff; font-size: 13px; color: #333; margin-top: 7px; letter-spacing: -1.2px; line-height: 44px; text-align: center; }

.main-cacl .btn-link:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); }

.main-cacl .btn-wrap { position: absolute; bottom: 30px; left: 0; width: 100%; box-sizing: border-box; }

.main-cacl .btn-wrap .btn-link__em { width: 100%; }

.main-cacl .main-info__1 { position: absolute; bottom: 30px; left: 0; }

.main-cacl.main-cacl--style2 { min-height: 380px; max-height: 380px; padding: 30px 0 74px; }

.main-cacl.main-cacl--style2 .inquiry-area { width: calc(100% - 60px); margin: 23px auto 0; height: 298px; }

.main-cacl.main-cacl--style2 .calculate { width: 100%; position: relative; z-index: 1; height: 99px; }

.main-cacl.main-cacl--style2 .calculate dl { width: 100%; line-height: 52px; }

.main-cacl.main-cacl--style2 .calculate dl:after { position: absolute; top: 52px; left: 0; width: 100%; height: 1px; background-color: #eee; content: ""; }

.main-cacl.main-cacl--style2 .calculate dl dt { position: relative; float: left; width: 23%; color: #333; font-size: 15px; font-weight: bold; text-align: left; line-height: 52px; }

.main-cacl.main-cacl--style2 .calculate dl dd { float: right; width: 77%; text-align: right; }

.main-cacl.main-cacl--style2 .calculate dl dd a { color: #0b83e6; font-size: 18px; font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-cacl.main-cacl--style2 .calculate dl dd .unit { display: inline-block; margin-left: 2px; color: #0b83e6; font-size: 18px; line-height: 49px; }

.main-cacl.main-cacl--style2 .calculate .title { font-size: 15px; color: #666; letter-spacing: -1.2px; }

.main-cacl.main-cacl--style2 .calculate .title em { display: inline-block; font-weight: bold; }

.main-cacl.main-cacl--style2 .calculate .count { margin-top: 11px; display: block; color: #0b83e6; }

.main-cacl.main-cacl--style2 .calculate .count a { font-weight: bold; font-size: 26px; font-family: "Roboto", Tahoma, sans-serif; color: #0b83e6; line-height: 30px; letter-spacing: 0; }

.main-cacl.main-cacl--style2 .calculate .count .unit { font-size: 18px; margin-left: 4px; line-height: 30px; }

.main-cacl.main-cacl--style2 .list { width: 100%; padding: 9px 12px; background-color: #fafafa; zoom: 1; }

.main-cacl.main-cacl--style2 .list:after { display: block; content: ""; clear: both; }

.main-cacl.main-cacl--style2 .list li { zoom: 1; }

.main-cacl.main-cacl--style2 .list li:after { display: block; content: ""; clear: both; }

.main-cacl.main-cacl--style2 .list .c-tooltip { height: 22px; font-size: 0; vertical-align: middle; }

.main-cacl.main-cacl--style2 .list .title { float: left; width: 50%; color: #666; line-height: 22px; }

.main-cacl.main-cacl--style2 .list .text { display: inline-block; font-size: 14px; color: rgba(102, 102, 102, 0.8); line-height: 22px; vertical-align: middle; }

.main-cacl.main-cacl--style2 .list .count { float: right; width: 50%; padding-right: 1px; color: #333; font-size: 14px; text-align: right; line-height: 22px; }

.main-cacl.main-cacl--style2 .list .count a { font-weight: bold; font-family: "Roboto", Tahoma, sans-serif; color: #333; letter-spacing: 0; }

.main-cacl.main-cacl--style2 .list .count .unit { display: inline-block; font-size: 14px; margin-left: 2px; line-height: 22px; }

.main-cacl.main-cacl--style2 .list .count .unit.point { font-weight: bold; }

.main-cacl.main-cacl--style2 .main-noti { margin-top: 6px; }

.main-cacl.main-cacl--style2 .btn-link { position: absolute; bottom: 30px; left: 0; width: 100%; height: 44px; border: solid 1px #ddd; background-color: #ffffff; font-size: 13px; color: #333; margin-top: 7px; letter-spacing: -1.2px; line-height: 44px; text-align: center; }

.main-cacl.main-cacl--style2 .btn-link:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); }

.main-cacl.main-cacl--style2 .btn-wrap { position: absolute; bottom: 25px; left: 0; width: 100%; box-sizing: border-box; }

.main-cacl.main-cacl--style2 .btn-wrap .btn-link__em { width: 100%; }

.main-cacl.main-cacl--style2 .main-info__1 { position: absolute; bottom: 30px; left: 0; }

.main-clean { min-height: 380px; max-height: 380px; padding-top: 30px; }

.main-clean .inquiry-area { width: 100%; height: 321px; padding: 20px 30px 0; }

.main-clean .inquiry-area .main-spinner { width: calc(100% - 60px); }

.main-clean .list { width: 100%; }

.main-clean .list div { width: 100%; padding: 11px 0; border-bottom: 1px solid #f5f5f5; zoom: 1; }

.main-clean .list div:after { display: block; content: ""; clear: both; }

.main-clean .list dt { position: relative; z-index: 1; float: left; font-size: 15px; line-height: 30px; color: #333; }

.main-clean .list .count { float: right; font-size: 18px; color: #0b83e6; font-weight: bold; line-height: 30px; letter-spacing: 0; }

.main-clean .list .count a { font-family: "Roboto", Tahoma, sans-serif; color: #0b83e6; }

.main-clean .info { width: 100%; margin: 4px auto 0; color: #999; font-size: 12px; line-height: 18px; }

.main-clean .info:first-of-type { margin-top: 15px; }

.main-clean .info:before { display: inline-block; width: 3px; height: 3px; margin: -1px 5px 0; background: #999; border-radius: 50%; line-height: 18px; vertical-align: middle; content: ""; }

.main-clean .info .date { font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; margin-left: 6px; }

.main-quicklink { min-height: 380px; max-height: 380px; border-radius: 3px !important; border: 1px solid #ddd; border-left: none; border-bottom: none; }

.main-quicklink ul { overflow: hidden; }

.main-quicklink ul li { width: 50%; height: 76px; float: left; border-bottom: 1px solid #ddd; border-left: 1px solid #ddd; }

.main-quicklink ul li a { display: block; line-height: 76px; padding: 0 15px; font-family: Roboto; font-size: 14px; color: #333; letter-spacing: -1.4px; }

.main-quicklink ul li a:before { content: ""; display: inline-block; margin-right: 4px; position: relative; top: 7px; }

.main-quicklink ul li:nth-child(1) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -163px -101px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(2) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: 0px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(3) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -29px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(4) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -58px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(5) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -87px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(6) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -116px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(7) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -145px -135px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(8) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: 0px -164px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(9) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -29px -164px; width: 24px; height: 24px; background-size: 219px 188px; }

.main-quicklink ul li:nth-child(10) a:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -58px -164px; width: 24px; height: 24px; background-size: 219px 188px; }

.btn-link__em { display: inline-block; min-width: 120px; height: 40px; padding: 0 10px 0; background-color: #ff0038; text-align: center; vertical-align: middle; color: #fff; font-weight: bold; font-size: 14px; line-height: 36px; letter-spacing: 0; cursor: pointer; }

.btn-link__em:after { display: inline-block; width: 10px; height: 10px; border: 1px solid #fff; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 3px; }

.btn-wrap { position: relative; text-align: center; }

.btn-wrap .text-guide { position: relative; top: -5px; display: inline-block; padding: 2px 8px; background-color: #fef0f2; border: solid 1px #ffe5e8; border-radius: 4px; color: #ff0038; font-size: 10px; letter-spacing: 0; }

.btn-wrap .text-guide:before { position: absolute; width: 7px; height: 7px; bottom: -4px; left: 50%; margin-left: -5px; border: solid 1px #ffe5e8; vertical-align: middle; transform: rotate(45deg); content: ""; }

.btn-wrap .text-guide:after { position: absolute; width: 7px; height: 7px; bottom: -3px; left: 50%; margin-left: -5px; background-color: #fef0f2; vertical-align: middle; transform: rotate(45deg); content: ""; }

.main-relation { font-size: 0; }

.main-relation ul { width: 100%; border: 1px solid #ddd; border-bottom: none; }

.main-relation li { display: inline-block; width: 20%; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; font-size: 14px; text-align: center; line-height: 53px; }

.main-relation li:nth-child(5n) { border-right: none; }

.main-relation li a { color: #333; }

.main-relation li a.kakao:before { position: relative; top: 4px; display: inline-block; margin-right: 5px; background-image: url("/img/selleroffice/sprites/sp_etc_2x_2021818_153330.png"); background-position: 0px 0px; width: 22px; height: 22px; background-size: 22px 22px; content: ""; }

.main-relation li a:hover { font-weight: bold; text-decoration: none; }

.main-tip { position: relative; z-index: 1; min-height: 380px; max-height: 380px; padding: 30px 0 25px; }

.main-tip .nav { display: inline-block; font-size: 0; vertical-align: middle; }

.main-tip .nav li { display: inline-block; }

.main-tip .nav li.active button span, .main-tip .nav li.active a span { background-color: #0b83e6; }

.main-tip .nav button, .main-tip .nav a { height: 22px; padding: 2px; }

.main-tip .nav button span, .main-tip .nav a span { display: block; width: 8px; height: 8px; background-color: #c4c8d4; border-radius: 4px; overflow: hidden; line-height: 200px; }

.main-tip .btn_autoplay { display: inline-block; overflow: hidden; position: relative; right: 0; bottom: 1px; width: 21px; height: 22px; line-height: 200px; z-index: 10; }

.main-tip .btn_autoplay:before { display: block; position: absolute; z-index: 1; top: calc(50% + 1px); left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 0; height: 0; border-top: 5px solid transparent; border-bottom: 5px solid transparent; border-left: 8px solid #333; border-radius: 1px; content: ""; }

.main-tip .btn_autoplay.stop:before { display: none; }

.main-tip .btn_autoplay.stop:after { display: block; position: absolute; z-index: 1; top: calc(50% + 1px); left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 7px; height: 8px; border-right: 2px solid #333; border-left: 2px solid #333; content: ""; }

.main-tip .noti { height: 75px; padding: 11px 30px; margin-top: 25px; border: 1px solid #f3ecce; border-left: 0; border-right: 0; background: #fffbe9; color: #333; font-weight: bold; font-size: 14px; line-height: 1.57; letter-spacing: -1px; }

.main-tip .noti .vc { max-height: 42px; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; }

.main-tip .noti strong { display: block; margin-bottom: 4px; color: #000; font-size: 12px; line-height: 18px; letter-spacing: -0.4px; font-weight: bold; }

.main-tip .explain { width: calc(100% - 60px); height: 112px; margin: 11px auto 0; font-size: 14px; color: #666; line-height: 2; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 4; -webkit-box-orient: vertical; white-space: initial; }

.main-tip .chart { width: calc(100% - 60px); margin: 0 auto; }

.main-tip .chart .chart-grapic { position: relative; z-index: 1; padding-top: 17px; }

.main-tip .chart .chart-grapic .bar_bg { width: 100%; padding-right: 70px; height: 3px; background-color: #37c590; }

.main-tip .chart .chart-grapic .bar { position: relative; z-index: 1; height: 3px; width: 100%; }

.main-tip .chart .chart-grapic .per { display: block; position: absolute; z-index: 2; top: -9px; left: 0; width: 40px; height: 18px; border: 2px solid transparent; border-radius: 16px; background-color: #fff; text-align: center; color: #fff; line-height: 14px; }

.main-tip .chart .chart-grapic .per em, .main-tip .chart .chart-grapic .per .unit { display: inline-block; vertical-align: middle; font-weight: 700; font-size: 15px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: -0.4px; }

.main-tip .chart .chart-grapic .target { margin-top: 2px; color: #37c590; text-align: right; font-size: 12px; font-family: "Roboto", Tahoma, sans-serif; line-height: 16px; letter-spacing: -0.6px; }

.main-tip .chart.level-3 .bar, .main-tip .chart.level-3 .per { color: #ff0038; border-color: #ff0038; }

.main-tip .chart.level-3 .bar { background-color: #ff0038; }

.main-tip .chart.level-2 .bar, .main-tip .chart.level-2 .per { color: #ffca44; border-color: #ffca44; }

.main-tip .chart.level-2 .bar { background-color: #ffca44; }

.main-tip .chart.level-2 .per { margin-left: -20px; }

.main-tip .chart.level-1 .bar_bg { padding-right: 40px; }

.main-tip .chart.level-1 .bar, .main-tip .chart.level-1 .per { color: #37c593; border-color: #37c593; }

.main-tip .chart.level-1 .bar { background-color: #37c593; }

.main-tip .btn-box { width: 100%; padding: 0 30px; position: absolute; z-index: 1; bottom: 25px; font-size: 0; }

.main-tip .btn-box .btn-link { display: inline-block; width: 100%; padding: 0 5px; height: 44px; border: solid 1px #ddd; background-color: #ffffff; font-size: 13px; letter-spacing: -1.1px; color: #333; line-height: 44px; text-align: center; white-space: nowrap; }

.main-tip .btn-box .btn-link:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); vertical-align: middle; margin: -2px 0 0 6px; content: ""; }

.main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2), .main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a { width: calc(50% - 3px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2):after, .main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a:after { display: none; }

.main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2) { float: left; }

.main-tip .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a { float: right; }

.main-tip .tip-wrap { display: none; margin: 0 auto; zoom: 1; }

.main-tip .tip-wrap:after { display: block; content: ""; clear: both; }

.main-tip .tip-wrap.open { display: block; }

.main-tip .list { display: table; width: 100%; margin-top: 10px; zoom: 1; }

.main-tip .list:after { display: block; content: ""; clear: both; }

.main-tip .list dt { float: left; display: table-cell; width: 70%; color: #666; font-size: 13px; line-height: 22px; letter-spacing: -1px; }

.main-tip .list dt:nth-child(1) { color: #333; font-size: 14px; font-weight: bold; }

.main-tip .list dd { float: right; display: table-cell; width: 30%; color: #666; font-size: 13px; font-weight: bold; line-height: 22px; letter-spacing: -1px; text-align: right; }

.main-tip .list dd:nth-child(2) { color: #333; font-size: 14px; font-weight: bold; }

.main-tip .info { margin-top: 4px; border-top: 1px solid #eee; font-size: 0; }

.main-tip .info:before { display: inline-block; width: 3px; height: 3px; margin-right: 4px; background-color: #999; border-radius: 1.5px; vertical-align: middle; content: ""; }

.main-tip .info .title { display: inline-block; margin-right: 9px; color: #999; font-size: 12px; line-height: 30px; letter-spacing: -1.1px; vertical-align: middle; }

.main-tip .info .date { display: inline-block; color: #999; font-size: 12px; font-family: "Roboto", Tahoma, sans-serif; line-height: 30px; letter-spacing: 0px; vertical-align: middle; }

.main-selling-coach { position: relative; z-index: 1; min-height: 380px; max-height: 380px; padding: 30px 0 25px; }

.main-selling-coach .main-box-tab { position: absolute; top: 20px; right: 30px; }

.main-selling-coach .btn-banner { display: block; height: 72px; border: 1px solid #eee; background-color: #fafafa; text-align: center; padding: 18px 0; font-family: "Noto Sans KR"; }

.main-selling-coach .btn-banner strong { display: inline-block; font-size: 14px; color: #333; font-weight: 700; }

.main-selling-coach .btn-banner strong em { color: #0b83e6; font-weight: 700; }

.main-selling-coach .btn-banner strong:before { display: inline-block; content: ""; background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: -95px 0px; width: 63px; height: 36px; background-size: 219px 188px; margin-right: 19px; vertical-align: middle; }

.main-selling-coach .btn-banner span { display: inline-block; font-size: 14px; color: #999; margin-left: 10px; }

.main-selling-coach .btn-banner span:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); vertical-align: middle; margin: -2px 0 0 3px; content: ""; }

.main-selling-coach .btn-banner--ai { padding: 8px 0; height: 52px; }

.main-selling-coach .btn-banner--ai strong:before { background-image: url("/img/selleroffice/sprites/sp_main_2x_2023329_143741.png"); background-position: 0px -95px; width: 42px; height: 35px; background-size: 219px 188px; margin-right: 4px; }

.main-selling-coach .selling-coach-list { height: 273px; margin: 28px 30px 25px; }

.main-selling-coach .selling-coach-list .nav-wrap { position: absolute; left: 30px; right: 30px; bottom: 90px; text-align: center; }

.main-selling-coach .selling-coach-list .nav-wrap .nav { font-size: 0; vertical-align: middle; display: inline-block; }

.main-selling-coach .selling-coach-list .nav-wrap .nav li { display: inline-block; }

.main-selling-coach .selling-coach-list .nav-wrap .nav li.active button span, .main-selling-coach .selling-coach-list .nav-wrap .nav li.active a span { background-color: #0b83e6; }

.main-selling-coach .selling-coach-list .nav-wrap .nav button, .main-selling-coach .selling-coach-list .nav-wrap .nav a { height: 22px; padding: 2px; }

.main-selling-coach .selling-coach-list .nav-wrap .nav button span, .main-selling-coach .selling-coach-list .nav-wrap .nav a span { display: block; width: 8px; height: 8px; background-color: #c4c8d4; border-radius: 4px; overflow: hidden; line-height: 200px; }

.main-selling-coach .selling-coach-list .nav-wrap .btn_autoplay { display: inline-block; overflow: hidden; position: relative; right: 0; bottom: 1px; width: 21px; height: 22px; line-height: 200px; z-index: 10; }

.main-selling-coach .selling-coach-list .nav-wrap .btn_autoplay:before { display: block; position: absolute; z-index: 1; top: calc(50% + 1px); left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 0; height: 0; border-top: 5px solid transparent; border-bottom: 5px solid transparent; border-left: 8px solid #333; border-radius: 1px; content: ""; }

.main-selling-coach .selling-coach-list .nav-wrap .btn_autoplay.stop:before { display: none; }

.main-selling-coach .selling-coach-list .nav-wrap .btn_autoplay.stop:after { display: block; position: absolute; z-index: 1; top: calc(50% + 1px); left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 7px; height: 8px; border-right: 2px solid #333; border-left: 2px solid #333; content: ""; }

.main-selling-coach .selling-coach-list .tip-wrap { display: none; margin: 0 auto; zoom: 1; }

.main-selling-coach .selling-coach-list .tip-wrap:after { display: block; content: ""; clear: both; }

.main-selling-coach .selling-coach-list .tip-wrap.open { display: block; }

.main-selling-coach .selling-coach-list .tip-wrap .noti { padding: 15px 20px; border: 1px solid #f3ecce; background: #fffbe9; color: #333; font-weight: bold; font-size: 14px; line-height: 1.57; letter-spacing: -1px; }

.main-selling-coach .selling-coach-list .tip-wrap .noti .vc { max-height: 42px; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; top: auto; -webkit-transform: none; -o-transform: none; transform: none; }

.main-selling-coach .selling-coach-list .tip-wrap .noti strong { display: block; margin-bottom: 4px; color: #000; font-size: 12px; line-height: 18px; letter-spacing: -0.4px; font-weight: bold; }

.main-selling-coach .selling-coach-list .tip-wrap .explain { height: 108px; margin: 16px auto 0; font-size: 14px; color: #666; line-height: 28px; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 3; -webkit-box-orient: vertical; white-space: initial; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box { z-index: 1; font-size: 0; position: absolute; bottom: 30px; left: 30px; right: 30px; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link { display: inline-block; width: 100%; padding: 0 5px; height: 44px; border: solid 1px #ddd; background-color: #ffffff; font-size: 13px; letter-spacing: -1.1px; color: #333; line-height: 44px; text-align: center; white-space: nowrap; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2), .main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a { width: calc(50% - 3px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2):after, .main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a:after { display: none; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2) { float: left; }

.main-selling-coach .selling-coach-list .tip-wrap .btn-box .btn-link:nth-child(1):nth-last-child(2) ~ a { float: right; }

.main-selling-coach .selling-coach-list .list { display: table; width: 100%; margin-top: 10px; zoom: 1; }

.main-selling-coach .selling-coach-list .list:after { display: block; content: ""; clear: both; }

.main-selling-coach .selling-coach-list .list dt { float: left; display: table-cell; width: 70%; color: #666; font-size: 13px; line-height: 22px; letter-spacing: -1px; }

.main-selling-coach .selling-coach-list .list dt:nth-child(1) { color: #333; font-size: 14px; font-weight: bold; }

.main-selling-coach .selling-coach-list .list dd { float: right; display: table-cell; width: 30%; color: #666; font-size: 13px; font-weight: bold; line-height: 22px; letter-spacing: -1px; text-align: right; }

.main-selling-coach .selling-coach-list .list dd:nth-child(2) { color: #333; font-size: 14px; font-weight: bold; }

.main-selling-coach .selling-coach-list .info { margin-top: 4px; border-top: 1px solid #eee; font-size: 0; }

.main-selling-coach .selling-coach-list .info:before { display: inline-block; width: 3px; height: 3px; margin-right: 4px; background-color: #999; border-radius: 1.5px; vertical-align: middle; content: ""; }

.main-selling-coach .selling-coach-list .info .title { display: inline-block; margin-right: 9px; color: #999; font-size: 12px; line-height: 30px; letter-spacing: -1.1px; vertical-align: middle; }

.main-selling-coach .selling-coach-list .info .date { display: inline-block; color: #999; font-size: 12px; font-family: "Roboto", Tahoma, sans-serif; line-height: 30px; letter-spacing: 0px; vertical-align: middle; }

.main-selling-coach .selling-coach-list .list-service { overflow: hidden; }

.main-selling-coach .selling-coach-list .list-service li { height: 80px; border-bottom: 1px solid #f4f4f4; position: relative; padding: 28px 0; }

.main-selling-coach .selling-coach-list .list-service li strong { display: inline-block; font-family: "Noto Sans KR"; font-size: 15px; color: #111; line-height: 24px; vertical-align: middle; }

.main-selling-coach .selling-coach-list .list-service li span.tag { display: inline-block; width: 75px; height: 24px; padding: 0 7px; line-height: 22px; border-radius: 12px; border: solid 1px rgba(11, 131, 230, 0.5); text-align: center; font-size: 11px; color: #0b83e6; letter-spacing: -1px; vertical-align: middle; margin-right: 5px; }

.main-selling-coach .selling-coach-list .list-service li span.desc { display: none; line-height: 24px; font-size: 12px; color: #999; margin-left: 8px; vertical-align: middle; }

@media (max-width: 1530px) { .main-selling-coach .selling-coach-list .list-service li span.desc { display: inline-block; } }

.main-selling-coach .selling-coach-list .list-service li .right { position: absolute; top: 20px; right: 0; }

.main-selling-coach .selling-coach-list .list-service li .right .price { display: inline-block; height: 40px; line-height: 40px; margin-right: 18px; font-size: 18px; color: #111; font-family: "Noto Sans KR"; }

.main-selling-coach .selling-coach-list .list-service li .right .price strong { font-size: 18px; color: #0b83e6; font-family: "Roboto"; font-weight: 700; display: inline-block; margin-right: 2px; }

.main-selling-coach .selling-coach-list .list-service li .right a { display: inline-block; width: 124px; height: 40px; line-height: 36px; text-align: center; border: 1px solid #ddd; font-size: 14px; color: #111; }

.main-selling-coach .selling-coach-list .list-service li .right a span:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); vertical-align: middle; margin: -2px 0 0 3px; content: ""; }

.main-selling-coach .selling-coach-list .list-ai li { display: table; padding: 20px 0; width: 100%; height: 84px; border-bottom: 1px solid #f4f4f4; }

.main-selling-coach .selling-coach-list .list-ai__tag { display: block; width: 36px; height: 24px; padding: 0 6px; line-height: 22px; border-radius: 12px; border: solid 1px rgba(11, 131, 230, 0.5); text-align: center; font-size: 11px; color: #0b83e6; letter-spacing: -1px; vertical-align: middle; }

.main-selling-coach .selling-coach-list .list-ai__title { display: block; margin-right: 8px; font-family: "Noto Sans KR"; font-size: 15px; color: #111; line-height: 24px; vertical-align: middle; }

.main-selling-coach .selling-coach-list .list-ai__title em { color: #0b83e6; font-weight: 700; }

.main-selling-coach .selling-coach-list .list-ai__desc { display: block; line-height: 18px; font-size: 12px; color: #949494; vertical-align: middle; }

.main-selling-coach .selling-coach-list .list-ai__desc em { color: #0b83e6; }

.main-selling-coach .selling-coach-list .list-ai__button { display: inline-block; width: 133px; height: 40px; line-height: 36px; text-align: center; border: 1px solid #ddd; font-size: 14px; color: #111; }

.main-selling-coach .selling-coach-list .list-ai__button span:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); vertical-align: middle; margin: -2px 0 0 3px; content: ""; }

.main-selling-coach .selling-coach-list .list-ai__button--disable { position: relative; padding-top: 17px; border-color: #f4f4f4; background-color: #fefefe; line-height: 1.5; color: #ccc; }

.main-selling-coach .selling-coach-list .list-ai__button--disable .sub-text { position: absolute; top: 4px; left: 0; display: block; width: 100%; color: #111; font-size: 10px; line-height: 15px; }

.main-selling-coach .selling-coach-list .list-ai__button--disable span:after { display: none; }

.main-selling-coach .selling-coach-list .list-ai__button--disable:hover { text-decoration: none; }

.main-selling-coach .selling-coach-list .list-ai__item { display: table-cell; width: 100%; vertical-align: top; }

.main-selling-coach .selling-coach-list .list-ai__item:nth-of-type(1) { width: 44px; }

.main-selling-coach .selling-coach-list .list-ai__item:nth-of-type(2) { width: calc(100% - 185px); }

.main-selling-coach .selling-coach-list .list-ai__item:nth-of-type(3) { width: 145px; text-align: right; }

.main-stats { min-height: 380px; max-height: 380px; padding: 30px 0 30px; position: relative; }

.main-stats .main-box-tab { position: absolute; top: 20px; right: 30px; }

.main-stats .chart-list { margin: 30px; height: 260px; }

.main-stats .list { margin-top: 20px; }

.main-stats .list li { width: calc(100% - 60px); margin: 0 auto; padding: 15px 0; border-bottom: 1px solid #f5f5f5; zoom: 1; }

.main-stats .list li:after { display: block; content: ""; clear: both; }

.main-stats .list li a { display: block; }

.main-stats .list li:last-child { border-bottom: none; }

.main-stats .list .title { position: relative; z-index: 1; float: left; width: 48%; height: 44px; }

.main-stats .list .text { display: block; font-weight: bold; font-size: 15px; line-height: 22px; color: #333; letter-spacing: -1.1px; }

.main-stats .list .sub_text { display: block; font-size: 14px; line-height: 22px; color: #999; }

.main-stats .list .sub_text a { display: inline-block; font-size: 14px; color: #999; font-weight: bold; margin-left: 5px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; }

.main-stats .list .sub_text .count { font-weight: bold; }

.main-stats .list li > .count { width: 52%; float: right; font-size: 20px; color: #111111; font-weight: bold; line-height: 30px; text-align: right; }

.main-stats .list li > .count a { width: 100%; font-size: 26px; color: #111111; font-weight: bold; line-height: 31px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; overflow: hidden; text-overflow: ellipsis; }

.main-stats .list li > .count a:hover { text-decoration: underline; }

.main-stats .list li > .count .up, .main-stats .list li > .count .down { display: block; font-family: "Roboto", Tahoma, sans-serif; font-size: 14px; color: #ff0038; line-height: 17px; }

.main-stats .list li > .count .down { color: #0b83e6; }

.main-stats .info { width: calc(100% - 60px); margin: 0 auto; padding-top: 20px; font-size: 12px; color: #999; line-height: 18px; border-top: 1px solid #eee; }

.main-stats .info:before { display: inline-block; width: 3px; height: 3px; background: #999; border-radius: 50%; content: ""; line-height: 18px; vertical-align: middle; margin-right: 5px; }

.main-stats .info .date { font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; margin-left: 6px; }

.main-notice { min-height: 380px; max-height: 380px; padding: 30px 0 0 0; }

.main-notice .main-tab { display: flex; flex-direction: row; width: 100%; margin: 25px 0 0; overflow: hidden; }

.main-notice .main-tab li { flex: 1; }

.main-notice .list { width: calc(100% - 60px); margin: 31px auto 0; }

.main-notice .list li { width: 100%; margin-top: 19px; zoom: 1; }

.main-notice .list li:after { display: block; content: ""; clear: both; }

.main-notice .list li.today .text a { font-weight: bold; color: #333; }

.main-notice .list li.today .date { color: #333; }

.main-notice .list .subject { float: left; width: 90%; }

.main-notice .list .noti { width: 57px; height: 24px; display: inline-block; border-radius: 12px; text-align: center; line-height: 22px; background: #fff; font-size: 11px; vertical-align: middle; border: 1px solid rgba(244, 49, 66, 0.5); }

.main-notice .list .noti.icon1 { border-color: rgba(102, 102, 102, 0.5); color: #333; }

.main-notice .list .noti.icon2 { border-color: rgba(255, 49, 66, 0.5); color: #ff0038; }

.main-notice .list .noti.icon3 { border-color: rgba(253, 57, 122, 0.5); color: #fd397a; }

.main-notice .list .noti.icon4 { border-color: rgba(100, 92, 161, 0.5); color: #645ca1; }

.main-notice .list .noti.icon5 { border-color: rgba(11, 131, 230, 0.5); color: #0b83e6; }

.main-notice .list .adv-noti { width: 57px; height: 24px; display: inline-block; border-radius: 12px; text-align: center; line-height: 22px; background: #fff; font-size: 11px; vertical-align: middle; border: 1px solid rgba(244, 49, 66, 0.5); }

.main-notice .list .adv-noti.icon1 { border-color: rgba(245, 109, 8, 0.5); color: #f56d08; }

.main-notice .list .adv-noti.icon2 { border-color: rgba(253, 67, 122, 0.5); color: #fd397a; }

.main-notice .list .adv-noti.icon3 { border-color: rgba(41, 179, 73, 0.5); color: #14af3d; }

.main-notice .list .adv-noti.icon4 { border-color: rgba(11, 85, 230, 0.5); color: #0b55e6; }

.main-notice .list .adv-noti.icon5 { border-color: rgba(17, 178, 213, 0.5); color: #11b2d5; }

.main-notice .list .adv-noti.icon6 { border-color: rgba(102, 102, 102, 0.5); color: #666; }

.main-notice .list .new { margin-left: 4px; font-family: "Roboto", Tahoma, sans-serif; font-size: 11px; font-weight: bold; color: #0b83e6; }

.main-notice .list .text { display: inline-block; max-width: 75%; height: 24px; margin-left: 9px; font-size: 14px; color: #666; overflow: hidden; vertical-align: middle; line-height: 24px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-notice .list .text a { color: #666; }

.main-notice .list .date { float: right; width: 10%; text-align: right; font-size: 13px; color: #999; line-height: 24px; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; vertical-align: middle; }

.main-edu { min-height: 410px; max-height: 410px; padding: 30px 0 0 0; }

.main-edu .main-tab { margin-top: 25px; }

.main-edu .main-tab li { width: 33%; }

.main-edu .main-tab li.active { width: 34%; }

.main-edu .main-tab .on_air { display: inline-block; min-width: 32px; height: 20px; margin: -2px 3px 0 0; padding: 0 6px; border-radius: 16px; border: solid 1px rgba(244, 49, 66, 0.2); background-color: #ff0038; color: #fff; font-family: "Roboto", Tahoma, sans-serif; font-weight: bold; font-size: 12px; line-height: 18px; letter-spacing: 0; vertical-align: middle; }

.main-edu .main-edu__board { position: relative; outline: none; }

.main-edu .main-edu__board .live { position: relative; width: calc(100% - 60px); height: 72px; padding: 0 20px; margin: 20px auto 0; background-color: rgba(244, 49, 66, 0.1); overflow: hidden; }

.main-edu .main-edu__board .live .vc { max-height: 42px; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; text-align: left; }

.main-edu .main-edu__board .live .live_date { position: absolute; bottom: 0; right: 0; height: 20px; padding: 0 1px; color: #666; font-size: 12px; background-color: #feeaec; line-height: 20px; box-sizing: border-box; }

.main-edu .main-edu__board .live .live_date::before { position: absolute; top: 0; left: -30px; background-image: url("/img/selleroffice/etc/shadow_red.png"); width: 30px; height: 20px; content: ""; }

.main-edu .main-edu__board .live strong { font-size: 14px; font-weight: bold; color: #ff0038; }

.main-edu .main-edu__board .live a { font-size: 14px; font-weight: bold; color: #333; }

.main-edu .main-edu__board .list { width: calc(100% - 60px); height: 72px; margin: 20px auto 0; position: relative; z-index: 1; border: 1px solid #e5f0f9; background-color: #eef6fd; }

.main-edu .main-edu__board .list li { position: absolute; z-index: 1; top: 0; left: 25px; display: none; width: calc(100% - 52px); height: 72px; line-height: 20px; }

.main-edu .main-edu__board .list li .vc { max-height: 42px; overflow: hidden; display: -webkit-box; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: initial; text-align: left; }

.main-edu .main-edu__board .list li.active { display: block; }

.main-edu .main-edu__board .list .live_date { position: absolute; bottom: 0; right: 0; height: 20px; padding: 0 1px; color: #666; font-size: 12px; background-color: #eef6fd; line-height: 20px; box-sizing: border-box; }

.main-edu .main-edu__board .list .live_date::before { position: absolute; top: 0; left: -30px; background-image: url("/img/selleroffice/etc/shadow_blue.png"); width: 30px; height: 20px; content: ""; }

.main-edu .main-edu__board .list strong { font-size: 14px; font-weight: bold; color: #0b83e6; }

.main-edu .main-edu__board .list a { font-size: 14px; font-weight: bold; color: #333; }

.main-edu .main-edu__board .list .btn-link { position: relative; margin-left: 18px; padding-right: 14px; font-size: 14px; color: #999; line-height: 52px; letter-spacing: -1.2px; }

.main-edu .main-edu__board .list .btn-link::after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 2px; }

.main-edu .main-edu__board .btn_arrow { position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateZ(0); }

.main-edu .main-edu__board .prev { width: 25px; height: 72px; top: 0; left: 0; }

.main-edu .main-edu__board .prev:before { display: block; width: 8px; height: 8px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); left: 25%; }

.main-edu .main-edu__board .next { width: 22px; height: 72px; top: 0; right: 0; }

.main-edu .main-edu__board .next:after { display: block; width: 8px; height: 8px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 25%; }

.main-edu .main-edu__apply { position: relative; z-index: 1; width: calc(100% - 60px); margin: 22px auto 0; zoom: 1; }

.main-edu .main-edu__apply:after { display: block; content: ""; clear: both; }

.main-edu .main-edu__apply .top .link { position: absolute; top: 0; right: 0; color: #333; font-size: 13px; letter-spacing: -1px; }

.main-edu .main-edu__apply .list { width: 100%; margin-top: 10px; zoom: 1; }

.main-edu .main-edu__apply .list:after { display: block; content: ""; clear: both; }

.main-edu .main-edu__apply .list li { width: 100%; zoom: 1; }

.main-edu .main-edu__apply .list li:after { display: block; content: ""; clear: both; }

.main-edu .main-edu__apply .list a { width: 100%; display: inline-block; color: #666; }

.main-edu .main-edu__apply .list a:hover { font-weight: bold; text-decoration: none; }

.main-edu .main-edu__apply .list .text { float: left; width: 75%; height: 22px; color: #666; font-size: 14px; font-weight: normal; line-height: 20px; overflow: hidden; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-edu .main-edu__apply .list .date { float: right; width: 25%; text-align: right; font-family: "Roboto", Tahoma, sans-serif; color: #999; font-size: 13px; letter-spacing: 0; line-height: 20px; }

.main-edu .main-edu__apply .info { position: relative; z-index: 1; width: 100%; min-height: 106px; padding: 20px 0; margin-top: 10px; background-color: #f4f4f4; color: #333; font-size: 13px; text-align: center; letter-spacing: -1.2px; line-height: 22px; }

.main-edu .main-edu__apply .info .link { color: #0b83e6; font-size: 13px; line-height: 19px; }

.main-edu .main-edu__apply .info .link:after { display: inline-block; width: 6px; height: 6px; border: 1px solid #0b83e6; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 2px; }

.main-edu .edu-list { width: calc(100% - 60px); margin: 25px auto; position: relative; z-index: 1; font-size: 0; }

.main-edu .edu-list .banner { width: calc(100% - 20px); max-height: 52px; margin: 0 auto; display: block; text-align: center; overflow: hidden; }

.main-edu .edu-list .list { width: calc(100% - 40px); height: 52px; margin: 0 auto; position: relative; z-index: 1; text-align: center; }

.main-edu .edu-list .list.live { width: 100%; padding: 0 20px; background-color: rgba(244, 49, 66, 0.1); line-height: 52px; }

.main-edu .edu-list .list .state1 { display: inline-block; margin-right: 4px; color: #0b83e6; font-size: 14px; font-weight: bold; vertical-align: middle; }

.main-edu .edu-list .list .state2 { display: inline-block; margin-right: 4px; color: #ff0038; font-size: 14px; font-weight: bold; vertical-align: middle; }

.main-edu .edu-list .list a { position: relative; max-width: 75%; display: inline-block; line-height: 52px; height: 52px; vertical-align: middle; text-align: left; }

.main-edu .edu-list .list .text { display: inline-block; max-width: 75%; padding: 0 3px; color: #333; font-weight: bold; font-size: 14px; vertical-align: middle; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-edu .edu-list .list .text:last-child { max-width: 100%; }

.main-edu .edu-list .list .date { display: inline-block; font-size: 14px; color: #666; margin-left: 5px; vertical-align: middle; }

.main-edu .edu-list .list .btn-link { position: relative; margin-left: 18px; padding-right: 14px; font-size: 14px; color: #999; line-height: 52px; letter-spacing: -1.2px; }

.main-edu .edu-list .list .btn-link::after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin: -2px 0 0 2px; }

.main-edu .edu-list .list li { width: 100%; height: 52px; position: absolute; z-index: 1; top: 0; left: 0; display: none; text-align: center; line-height: 52px; border: 1px solid #e5f0f9; background-color: rgba(11, 131, 230, 0.1); }

.main-edu .edu-list .list li.active { display: block; }

.main-edu .edu-list .btn_arrow { position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateZ(0); }

.main-edu .edu-list .prev { width: 22px; height: 52px; top: 0; left: 0; }

.main-edu .edu-list .prev:before { display: block; width: 6px; height: 6px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); left: 25%; }

.main-edu .edu-list .next { width: 22px; height: 52px; top: 0; right: 0; }

.main-edu .edu-list .next:after { display: block; width: 6px; height: 6px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 25%; }

.main-edu .edu-apply { position: relative; z-index: 1; width: calc(100% - 60px); margin: 28px auto 0; zoom: 1; }

.main-edu .edu-apply:after { display: block; content: ""; clear: both; }

.main-edu .edu-apply .main-subtit__right { position: absolute; top: 0; right: 0; color: #333; font-size: 13px; letter-spacing: -1px; }

.main-edu .edu-apply .list { width: 100%; margin-top: 13px; zoom: 1; }

.main-edu .edu-apply .list:after { display: block; content: ""; clear: both; }

.main-edu .edu-apply .list li { margin-top: 7px; width: 100%; zoom: 1; }

.main-edu .edu-apply .list li:after { display: block; content: ""; clear: both; }

.main-edu .edu-apply .list a { width: 100%; display: inline-block; color: #666; }

.main-edu .edu-apply .list a:hover { font-weight: bold; text-decoration: none; }

.main-edu .edu-apply .list .text { float: left; width: 70%; height: 22px; color: #666; font-size: 14px; font-weight: normal; line-height: 20px; overflow: hidden; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-edu .edu-apply .list .date { float: right; width: 30%; text-align: right; font-family: "Roboto", Tahoma, sans-serif; color: #999; font-size: 13px; letter-spacing: 0; line-height: 20px; }

.main-prd { position: relative; z-index: 2 !important; height: 100%; min-height: 410px; max-height: 410px; padding: 30px 0 30px; zoom: 1; }

.main-prd:after { display: block; content: ""; clear: both; }

.main-prd .inquiry-area { width: calc(100% - 60px); margin: 20px auto 0; }

.main-prd .list { display: flex; flex-direction: column; justify-content: space-between; width: 100%; height: 218px; zoom: 1; }

.main-prd .list:after { display: block; content: ""; clear: both; }

.main-prd .list li { zoom: 1; }

.main-prd .list li:after { display: block; content: ""; clear: both; }

.main-prd .list li:not(:first-child) { margin-top: 6px; }

.main-prd .list li .main-info__1:first-child { height: 124px; }

.main-prd .list .title { zoom: 1; }

.main-prd .list .title:after { display: block; content: ""; clear: both; }

.main-prd .list .title .text { float: left; width: 60%; font-size: 15px; color: #333; font-weight: bold; line-height: 22px; }

.main-prd .list .title .count { float: right; width: 40%; padding-right: 2px; text-align: right; font-size: 15px; font-family: "Roboto", Tahoma, sans-serif; font-weight: bold; line-height: 22px; letter-spacing: 0; }

.main-prd .list .title .count a { color: #333; }

.main-prd .sub_list { width: 100%; margin-top: 4px; zoom: 1; }

.main-prd .sub_list:after { display: block; content: ""; clear: both; }

.main-prd .sub_list li { margin-top: 5px; zoom: 1; }

.main-prd .sub_list li:after { display: block; content: ""; clear: both; }

.main-prd .sub_list li > .title { float: left; width: 50%; color: #666; font-size: 14px; line-height: 20px; }

.main-prd .sub_list li > .count { float: right; width: 50%; padding-right: 2px; text-align: right; color: #666; font-family: "Roboto", Tahoma, sans-serif; font-size: 13px; line-height: 20px; }

.main-prd .search { display: inline-block; width: calc(100% - 60px); height: 44px; margin: 0 30px; position: absolute; z-index: 1; bottom: 33px; border: 1px solid #ddd; border-radius: 2px; font-size: 0; line-height: 0; }

.main-prd .search.open .list { display: block; }

.main-prd .search.open .btn:after { margin-top: -4px; transform: rotate(180deg); }

.main-prd .search .btn { position: relative; z-index: 1; display: inline-block; width: 120px; padding-left: 12px; height: 42px; background-color: #fff; font-size: 14px; line-height: 16px; letter-spacing: -0.88px; color: #111; }

.main-prd .search .btn:after { display: block; position: absolute; z-index: 1; top: 50%; -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); right: 6px; width: 0; height: 0; border-left: 4px solid transparent; border-right: 4px solid transparent; border-top: 5px solid #333; content: ""; }

.main-prd .search .btn .text { display: block; width: 96px; height: 42px; line-height: 42px; text-align: left; letter-spacing: 0; }

.main-prd .search .list { display: none; position: absolute; z-index: 1; top: 42px; left: -1px; width: 120px; padding: 0; background-color: #fff; border: 1px solid #ddd; line-height: 32px; }

.main-prd .search .list li { margin-top: 0; }

.main-prd .search .list .link { width: 100%; height: 36px; padding: 0 10px; line-height: 36px; text-align: left; font-size: 14px; color: #333; }

.main-prd .search .list .link:hover { background-color: #fafafa; color: #111; }

.main-prd .search .list .link.active { color: #ff0038; }

.main-prd .search .inp { width: calc(100% - 155px); height: 42px; line-height: 42px; border: none; vertical-align: top; }

.main-prd .search .btn-search { position: absolute; top: 4px; right: 10px; margin: 7px 0; overflow: hidden; line-height: 200px; background-image: url("/img/selleroffice/sprites/sp_icon_2023712_140721.png"); background-position: 0px -168px; width: 22px; height: 22px; vertical-align: top; }

.main-adver { position: relative; min-height: 410px; max-height: 410px; padding: 32px 0 30px; }

.main-adver .inquiry-area { width: 100%; min-height: 230px; padding: 20px 30px 0; }

.main-adver .inquiry-area .main-spinner { width: calc(100% - 60px); }

.main-adver .btn-link { position: absolute; bottom: 33px; left: 30px; width: calc(100% - 60px); height: 44px; margin-top: 7px; border-radius: 2px; border: solid 1px #ddd; background-color: #ffffff; font-size: 14px; color: #333; line-height: 44px; text-align: center; }

.main-adver .btn-link:after { display: inline-block; width: 8px; height: 8px; border: 1px solid #999; border-width: 1px 1px 0 0; transform: rotate(45deg); vertical-align: middle; margin: -2px 0 0 6px; content: ""; }

.main-adver .cont-list { width: 100%; }

.main-adver .cont-list .list { width: 100%; }

.main-adver .cont-list .list li { zoom: 1; margin-top: 5px; }

.main-adver .cont-list .list li:after { display: block; content: ""; clear: both; }

.main-adver .cont-list .list li > .title { float: left; width: 60%; color: #666; font-size: 14px; }

.main-adver .cont-list .list li > .count { float: right; width: 40%; text-align: right; font-size: 14px; color: #333; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; font-weight: bold; }

.main-adver .cont-list .list li a { color: #333; }

.main-adver .cont-focus { width: 100%; padding-top: 20px; padding-bottom: 3px; margin: 21px auto 0; border-top: 1px solid #f4f4f4; }

.main-adver .cont-focus .main-subtit { margin-bottom: 8px; }

.main-adver .cont-focus .list { width: 100%; }

.main-adver .cont-focus .list > li { zoom: 1; margin-top: 5px; }

.main-adver .cont-focus .list > li:after { display: block; content: ""; clear: both; }

.main-adver .cont-focus .list > li > .title { float: left; width: 46%; color: #666; font-size: 14px; font-weight: bold; letter-spacing: -1.1px; line-height: 22px; }

.main-adver .cont-focus .list > li > .count { float: right; width: 34%; text-align: right; font-size: 14px; color: #666; letter-spacing: 0; }

.main-adver .cont-focus .list > li > .count a { font-family: "Roboto", Tahoma, sans-serif; font-weight: bold; color: #333; }

.main-adver .cont-focus .list > li:nth-last-child(1) > .title { width: calc(100% - 170px); }

.main-adver .cont-focus .sub_list { float: right; font-size: 0; }

.main-adver .cont-focus .sub_list li { display: inline-block; padding: 0; font-size: 0; zoom: 1; }

.main-adver .cont-focus .sub_list li:after { display: block; content: ""; clear: both; }

.main-adver .cont-focus .sub_list li:not(:first-child):before { display: inline-block; height: 14px; margin: 0 8px; width: 1px; background-color: #ccc; vertical-align: middle; content: ""; }

.main-adver .cont-focus .sub_list li > .title { display: inline-block; width: 39px; color: #666; font-size: 14px; vertical-align: middle; }

.main-adver .cont-focus .sub_list li > .count { display: inline-block; width: 35px; text-align: right; font-size: 14px; color: #333; font-family: "Roboto", Tahoma, sans-serif; letter-spacing: 0; vertical-align: middle; overflow: hidden; text-overflow: ellipsis; }

.main-adver .cont-focus .sub_list li > .count a { color: #333; }

.main-billboard { position: relative; width: 100%; height: 290px; padding: 0 0 46px 0; background: #eee; }

.main-billboard .menu { display: flex; flex-direction: row; position: absolute; left: 0; bottom: 0; right: 0; border-right: 1px solid #eee; z-index: 10; }

.main-billboard .menu > li { display: inline-block; flex: 1; position: relative; overflow: hidden; }

.main-billboard .menu li:nth-last-child(1) { margin-right: 46px; }

.main-billboard .menu li:nth-child(1):nth-last-child(1) { margin-right: 0; }

.main-billboard .lk { display: block; width: 100%; height: 46px; padding: 0 5px; line-height: 46px; text-align: center; border-left: 1px solid #eee; font-size: 15px; letter-spacing: -0.7px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-billboard .lk:hover { text-decoration: none; }

.main-billboard .btn_list { overflow: hidden; position: absolute; top: 0px; width: 30px; height: 40px; line-height: 200px; }

.main-billboard .btn_list.btn_prev { left: 0px; background: url("https://dummyimage.com/30x38/eeeeee/444444&text=%3C") no-repeat; }

.main-billboard .btn_list.btn_next { right: 1px; background: url("https://dummyimage.com/30x38/eeeeee/444444&text=%3E") no-repeat; }

.main-billboard .active .lk { background: #0b83e6; color: #fff; }

.main-billboard .active .lk:before { display: block; position: absolute; z-index: 1; top: -6px; left: 50%; transform: rotate(180deg); width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 6px solid #0b83e6; content: ""; }

.main-billboard .visual { position: absolute; left: 0; top: 0; right: 0; bottom: 46px; font-size: 0; text-align: center; display: none; }

.main-billboard .visual.active { display: block; }

.main-billboard .visual a { display: block; width: 100%; height: 244px; overflow: hidden; }

.main-billboard .visual a img { width: 910px; height: 245px; }

.main-billboard .btn_paging { display: none; overflow: hidden; position: absolute; top: 50%; width: 55px; height: 55px; margin: -50px 0 0 0; color: #fff; line-height: 200px; }

.main-billboard .btn_paging.btn_prev { background-color: rgba(17, 17, 17, 0.16); }

.main-billboard .btn_paging.btn_prev:before { position: absolute; z-index: 1; top: 50%; left: 50%; width: 16px; height: 16px; border: 2px solid white; border-width: 2px 2px 0 0; transform: rotate(-135deg) translateX(-65%) translateY(-35%); transform-origin: 0 0; content: ""; }

.main-billboard .btn_paging.btn_next { right: 0; background-color: rgba(17, 17, 17, 0.16); }

.main-billboard .btn_paging.btn_next:before { position: absolute; z-index: 1; top: 50%; left: 50%; width: 16px; height: 16px; border: 2px solid #eeeeee; border-width: 2px 2px 0 0; transform: rotate(45deg) translateX(-65%) translateY(-35%); transform-origin: 0 0; content: ""; }

.main-billboard .btn_autoplay { overflow: hidden; position: absolute; right: 0; bottom: 0; width: 46px; height: 46px; line-height: 200px; z-index: 10; border-left: 1px solid #eee; }

.main-billboard .btn_autoplay:before { display: block; position: absolute; z-index: 1; top: 50%; left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left: 13px solid #333; border-radius: 4px; content: ""; }

.main-billboard .btn_autoplay.stop:before { display: none; }

.main-billboard .btn_autoplay.stop:after { display: block; position: absolute; z-index: 1; top: 50%; left: 50%; transform: translate(-50%, -50%); transform-origin: 0 0; width: 12px; height: 16px; border-right: 4px solid #333; border-left: 4px solid #333; content: ""; }

.main-billboard:hover .btn_paging { display: block; }

.main-billboard:hover .btn_paging.btn_prev:hover, .main-billboard:hover .btn_paging.btn_next:hover { background-color: rgba(17, 17, 17, 0.3); }

.main-banner { position: relative; z-index: 1; height: 120px; }

.main-banner .navi { position: absolute; z-index: 2; top: 10px; right: 10px; }

.main-banner .navi .num { float: left; margin-right: 8px; font-family: "Roboto", Tahoma, sans-serif; font-size: 14px; line-height: 22px; color: #999; }

.main-banner .navi .num strong { color: #333; font-weight: bold; }

.main-banner .navi .num .skip { position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px; }

.main-banner .navi .btn_arrow { float: left; width: 22px; height: 22px; font-size: 0; line-height: 0; color: transparent; border: 1px solid #ddd; background: #fff; }

.main-banner .navi .btn_arrow:before { display: inline-block; content: ""; vertical-align: middle; }

.main-banner .navi .prev:before { width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(-135deg); margin-right: -3px; }

.main-banner .navi .next { border-left: none; }

.main-banner .navi .next:before { width: 6px; height: 6px; border: 1px solid #999; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); margin-left: -3px; }

.main-banner .list { position: relative; z-index: 1; }

.main-banner .list li { display: none; position: absolute; z-index: 1; top: 0; left: 0; width: 100%; height: 120px; overflow: hidden; border-radius: 2px; box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15); }

.main-banner .list .active { display: block; z-index: 2; }

.main-banner .list a { display: block; text-align: center; }

.main-banner .list a img { width: 595px; height: 120px; }

.main-pop { position: absolute; top: 244px; left: 255px; z-index: 100; width: 600px; border: 1px solid rgba(0, 0, 0, 0.1); box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24); box-sizing: border-box; }

.main-pop .pop-header { background-color: #ff0038; }

.main-pop .pop-header .pop-header__title { width: 500px; height: 58px; margin: 0 auto; display: block; font-size: 18px; color: #fff; line-height: 58px; letter-spacing: -0.56px; font-weight: 500; text-align: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-pop .pop-body { padding: 0; max-height: 635px; overflow: hidden; overflow-y: auto; background-color: #fff; }

.main-pop .pop-body iframe { vertical-align: top; }

.main-pop .pop-bottom { position: relative; z-index: 1; border-top: 1px solid #eee; background-color: #fff; padding: 32px 40px; text-align: center; zoom: 1; }

.main-pop .pop-bottom:after { display: block; content: ""; clear: both; }

.main-pop .pop-bottom button { margin: 0 5px; }

.main-pop .pop-bottom .pop-bottom__left { float: left; text-align: left; }

.main-pop .pop-bottom .pop-bottom__right { float: right; text-align: right; }

.main-pop .pop-bottom.style__2 .check-style__2 { position: absolute; z-index: 1; top: 50%; left: 30px; margin-top: -20px; }

.main-pop .pop-bottom.style__2 .lab { color: #666; font-size: 13px; letter-spacing: -0.5px; }

.main-pop .pop-bottom.style__3 { background-color: #f4f4f4; }

.main-pop .pop-bottom.style__3 .lab { color: #333; font-size: 16px; font-weight: bold; letter-spacing: -0.6px; }

.main-pop .pop-close { position: absolute; z-index: 1; top: 10px; right: 10px; line-height: 200px; overflow: hidden; background: transparent; width: 40px; height: 40px; overflow: hidden; line-height: 200px; }

.main-pop .pop-close:before, .main-pop .pop-close:after { position: absolute; top: 50%; left: 50%; width: 39px; height: 1px; background-color: #fff; content: ""; }

.main-pop .pop-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.main-pop .pop-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.main-notice-pop { position: absolute; top: 140px; left: 255px; z-index: 100; width: 600px; border: 1px solid rgba(0, 0, 0, 0.1); box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24); box-sizing: border-box; }

.main-notice-pop .pop-header { background-color: #ff0038; }

.main-notice-pop .pop-header .pop-header__title { width: 500px; height: 58px; margin: 0 auto; display: block; font-size: 18px; color: #fff; line-height: 58px; letter-spacing: -0.56px; font-weight: 500; text-align: center; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-notice-pop .pop-body { padding: 0; max-height: 530px; overflow: hidden; overflow-y: auto; background-color: #fff; }

.main-notice-pop .pop-body iframe { vertical-align: top; }

.main-notice-pop .pop-bottom { position: relative; z-index: 1; border-top: 1px solid rgba(0, 0, 0, 0.08); background-color: #fafafa; min-height: 62px; padding: 15px 30px; text-align: center; box-sizing: border-box; zoom: 1; }

.main-notice-pop .pop-bottom:after { display: block; content: ""; clear: both; }

.main-notice-pop .pop-bottom button { margin: 0 5px; }

.main-notice-pop .pop-bottom .pop-bottom__left { float: left; text-align: left; }

.main-notice-pop .pop-bottom .pop-bottom__right { float: right; text-align: right; }

.main-notice-pop .pop-bottom.style__2 .check-style__2 { position: absolute; z-index: 1; top: 50%; left: 20px; margin-top: -20px; }

.main-notice-pop .pop-bottom.style__2 .lab { color: #666; font-size: 14px; letter-spacing: -0.5px; }

.main-notice-pop .pop-bottom.style__2 .controls { position: absolute; top: 50%; right: 30px; transform: translateY(-50%); }

.main-notice-pop .pop-bottom.style__3 { background-color: #f4f4f4; }

.main-notice-pop .pop-bottom.style__3 .lab { color: #333; font-size: 16px; font-weight: bold; letter-spacing: -0.6px; }

.main-notice-pop .pop-close { position: absolute; z-index: 1; top: 10px; right: 10px; line-height: 200px; overflow: hidden; background: transparent; width: 40px; height: 40px; overflow: hidden; line-height: 200px; }

.main-notice-pop .pop-close:before, .main-notice-pop .pop-close:after { position: absolute; top: 50%; left: 50%; width: 39px; height: 1px; background-color: #fff; content: ""; }

.main-notice-pop .pop-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.main-notice-pop .pop-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.main-notice-pop .controls { position: relative; display: inline-block; }

.main-notice-pop .controls .controls-inner { position: relative; display: inline-block; }

.main-notice-pop .controls .controls-page { display: inline-block; padding: 0 38px; font-size: 15px; color: #666; vertical-align: middle; line-height: 28px; }

.main-notice-pop .controls .controls-page strong { color: #111; }

.main-notice-pop .controls .controls-page strong:after { content: "/"; display: inline-block; margin: 0 5px; color: #ccc; }

.main-notice-pop .controls .controls-navigator button { position: relative; width: 28px; height: 28px; margin: 0; border-radius: 50%; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4); background-color: #fff; line-height: 200px; overflow: hidden; }

.main-notice-pop .controls .controls-navigator button:after { content: ""; position: absolute; top: 50%; left: 50%; margin: -4px 0 0 -5px; width: 8px; height: 8px; border: 2px solid #111; vertical-align: middle; transform-origin: center center; transform: rotate(-135deg); }

.main-notice-pop .controls .controls-navigator button:first-child { position: absolute; top: 0; left: 0; }

.main-notice-pop .controls .controls-navigator button:first-child:after { margin-left: -3px; border-width: 2px 2px 0 0; }

.main-notice-pop .controls .controls-navigator button:last-child { position: absolute; top: 0; right: 0; }

.main-notice-pop .controls .controls-navigator button:last-child:after { margin-left: -5px; border-width: 0 0 2px 2px; }

.main-notice-pop .controls .controls-navigator button:disabled:after { border-color: #ccc; }

.main-notice-pop .controls .controls-btns { display: inline-block; margin-left: 10px; }

.main-notice-pop .controls .controls-btns button { position: relative; width: 28px; height: 28px; margin: 0; border-radius: 50%; box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4); background-color: #fff; line-height: 200px; overflow: hidden; }

.main-notice-pop .controls .controls-btns button.pause:before, .main-notice-pop .controls .controls-btns button.pause:after { content: ""; position: absolute; top: 50%; left: 50%; width: 2px; height: 10px; background: #000; }

.main-notice-pop .controls .controls-btns button.pause:before { margin: -5px 0 0 -3px; }

.main-notice-pop .controls .controls-btns button.pause:after { margin: -5px 0 0 2px; }

.main-notice-pop .controls .controls-btns button.play:after { content: ""; position: absolute; top: 50%; left: 50%; width: 0; height: 0; margin: -5px 0 0 -2px; border: 5.5px solid transparent; border-right: 0 solid transparent; border-left: 7px solid #000; vertical-align: middle; transform-origin: center center; transform: rotate(0deg); }

.main-lypop { position: absolute; top: 50%; left: 50%; z-index: 100; background-color: #fff; border: 1px solid #ddd; transform: translate(-50%, -50%); }

.main-lypop .check-style__2 { position: absolute; z-index: 1; bottom: 16px; left: 30px; }

.main-lypop .check-style__2 .lab { color: #666; font-size: 13px; }

.main-lypop .pop-close { position: absolute; z-index: 1; top: 40px; right: 40px; width: 30px; height: 30px; overflow: hidden; line-height: 200px; transform: translateZ(0); }

.main-lypop .pop-close:before, .main-lypop .pop-close:after { position: absolute; top: 50%; left: 50%; width: 33px; height: 1px; background-color: #9b9b9b; content: ""; }

.main-lypop .pop-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.main-lypop .pop-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.main-lypop.main-lypop__main { width: 800px; height: 600px; }

.main-lypop.main-lypop__main .swiper-container { overflow: hidden; position: relative; width: 798px; height: 568px; background: #fff; text-align: center; }

.main-lypop.main-lypop__main .list { width: 20000px; height: 100%; margin: auto; }

.main-lypop.main-lypop__main .list li { position: relative; height: 100%; float: left; }

.main-lypop.main-lypop__main .info { width: 800px; height: 600px; background-size: 800px 600px; overflow: hidden; line-height: 1000px; }

.main-lypop.main-lypop__main .info.info1 { background-image: url("/img/selleroffice/renewal_main/main_info.png"); }

.main-lypop.main-lypop__main .info.info2 { background-image: url("/img/selleroffice/renewal_main/main_info2.png"); }

.main-lypop.main-lypop__main .info.info3 { background-image: url("/img/selleroffice/renewal_main/main_info3.png"); }

.main-lypop.main-lypop__main .info.info4 { background-image: url("/img/selleroffice/renewal_main/main_info4.png"); }

.main-lypop.main-lypop__main .info.info5 { background-image: url("/img/selleroffice/renewal_main/main_info5.png"); }

.main-lypop.main-lypop__main .info.info6 { background-image: url("/img/selleroffice/renewal_main/main_info6.png"); }

.main-lypop.main-lypop__main .btn_arrow { width: 40px; height: 40px; position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateY(-48%); }

.main-lypop.main-lypop__main .prev { left: 16px; }

.main-lypop.main-lypop__main .prev:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); }

.main-lypop.main-lypop__main .next { right: 16px; }

.main-lypop.main-lypop__main .next:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); }

.main-lypop.main-lypop__main .swiper-button-disabled { opacity: 0.2; }

.main-lypop.main-lypop__main .pagination { position: absolute; bottom: 64px; width: 100%; margin-top: 8px; text-align: center; }

.main-lypop.main-lypop__main .pagination .swiper-pagination-bullet { display: inline-block; width: 8px; height: 8px; margin: 0 4px; border-radius: 50%; background: #c4c8d4; vertical-align: middle; opacity: 1; }

.main-lypop.main-lypop__main .pagination .swiper-pagination-bullet-active { background: #0b83e6; }

.main-lypop.main-lypop__11talk { width: 800px; height: 600px; }

.main-lypop.main-lypop__11talk .swiper-container { overflow: hidden; position: relative; width: 798px; height: 568px; background: #fff; text-align: center; }

.main-lypop.main-lypop__11talk .list { width: 20000px; height: 100%; margin: auto; }

.main-lypop.main-lypop__11talk .list li { position: relative; height: 100%; float: left; }

.main-lypop.main-lypop__11talk .info { width: 800px; height: 600px; background-size: 800px 600px; overflow: hidden; line-height: 1000px; }

.main-lypop.main-lypop__11talk .info.info1 { background-image: url("/img/selleroffice/main_lypop/talk_1.png"); }

.main-lypop.main-lypop__11talk .info.info2 { background-image: url("/img/selleroffice/main_lypop/talk_2.png"); }

.main-lypop.main-lypop__11talk .info.info3 { background-image: url("/img/selleroffice/main_lypop/talk_3.png"); }

.main-lypop.main-lypop__11talk .info.info4 { background-image: url("/img/selleroffice/main_lypop/talk_4.png"); }

.main-lypop.main-lypop__11talk .btn_arrow { width: 40px; height: 40px; position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateY(-48%); }

.main-lypop.main-lypop__11talk .prev { left: 16px; }

.main-lypop.main-lypop__11talk .prev:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); }

.main-lypop.main-lypop__11talk .next { right: 16px; }

.main-lypop.main-lypop__11talk .next:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); }

.main-lypop.main-lypop__11talk .swiper-button-disabled { opacity: 0.2; }

.main-lypop.main-lypop__11talk .pagination { position: absolute; bottom: 30px; width: 100%; margin-top: 8px; text-align: center; }

.main-lypop.main-lypop__11talk .pagination .swiper-pagination-bullet { display: inline-block; width: 8px; height: 8px; margin: 0 4px; border-radius: 50%; background: #c4c8d4; vertical-align: middle; opacity: 1; }

.main-lypop.main-lypop__11talk .pagination .swiper-pagination-bullet-active { background: #0b83e6; }

.main-lypop.main-lypop__11talk .btn-style__link { position: absolute; width: 180px; height: 48px; bottom: 87px; left: 50%; margin-left: -90px; padding: 0 15px; background-color: #ff0038; color: #fff; font-size: 16px; line-height: 48px; text-align: center; text-decoration: none; }

.main-lypop.main-lypop__store { width: 800px; height: 600px; }

.main-lypop.main-lypop__store .swiper-container { overflow: hidden; position: relative; width: 798px; height: 568px; background: #fff; text-align: center; }

.main-lypop.main-lypop__store .list { width: 20000px; height: 100%; margin: auto; }

.main-lypop.main-lypop__store .list li { position: relative; height: 100%; float: left; }

.main-lypop.main-lypop__store .info { width: 800px; height: 600px; background-size: 800px 600px; overflow: hidden; line-height: 1000px; }

.main-lypop.main-lypop__store .info.info1 { background-image: url("/img/selleroffice/main_lypop/img_smart_01.jpg"); }

.main-lypop.main-lypop__store .info.info2 { background-image: url("/img/selleroffice/main_lypop/img_smart_02.jpg"); }

.main-lypop.main-lypop__store .info.info3 { background-image: url("/img/selleroffice/main_lypop/img_smart_03.jpg"); }

.main-lypop.main-lypop__store .info.info4 { background-image: url("/img/selleroffice/main_lypop/img_smart_04.jpg"); }

.main-lypop.main-lypop__store .info.info5 { background-image: url("/img/selleroffice/main_lypop/img_smart_05.jpg"); }

.main-lypop.main-lypop__store .info .btn_box { position: absolute; bottom: 70px; left: 0px; width: 100%; height: 56px; line-height: 56px; text-align: center; }

.main-lypop.main-lypop__store .info .btn_box .btn-style__1, .main-lypop.main-lypop__store .info .btn_box .btn-style__3 { width: 200px; height: 56px; line-height: 53px; font-size: 19px; margin: 0 4px 0 5px; font-weight: bold; }

.main-lypop.main-lypop__store .btn_arrow { width: 40px; height: 40px; position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateY(-48%); }

.main-lypop.main-lypop__store .prev { left: 16px; }

.main-lypop.main-lypop__store .prev:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); }

.main-lypop.main-lypop__store .next { right: 16px; }

.main-lypop.main-lypop__store .next:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); }

.main-lypop.main-lypop__store .swiper-button-disabled { opacity: 0.2; }

.main-lypop.main-lypop__store .pagination { position: absolute; bottom: 36px; width: 100%; margin-top: 8px; text-align: center; }

.main-lypop.main-lypop__store .pagination .swiper-pagination-bullet { display: inline-block; width: 8px; height: 8px; margin: 0 4px; border-radius: 50%; background: #c4c8d4; vertical-align: middle; opacity: 1; }

.main-lypop.main-lypop__store .pagination .swiper-pagination-bullet-active { background: #0b83e6; }

.main-lypop.main-lypop__event { width: 1000px; height: 697px; }

.main-lypop.main-lypop__event .pop__title { padding-top: 70px; font-family: "11StreetGothic"; font-size: 38px; font-weight: 400; letter-spacing: -0.475px; text-align: center; color: #000; }

.main-lypop.main-lypop__event .pop__title--red { color: #f43142; }

.main-lypop.main-lypop__event .pop__board { margin-top: 25px; padding: 0 54px; box-sizing: border-box; text-align: center; }

.main-lypop.main-lypop__event .pop__board-ul { display: flex; align-items: center; justify-content: space-between; gap: 20px; }

.main-lypop.main-lypop__event .pop__board-li { display: flex; align-items: center; justify-content: flex-start; flex-direction: column; width: 284px; height: 320px; border-radius: 10px; background: #fafafa; }

.main-lypop.main-lypop__event .pop__board--type { margin-top: 20px; padding: 4px 12px; border-radius: 50px; background: #fff; color: #333; font-size: 13px; font-weight: 700; }

.main-lypop.main-lypop__event .pop__board--sub { margin-top: 5px; font-size: 17px; font-weight: 700; letter-spacing: -0.17px; color: #111; }

.main-lypop.main-lypop__event .pop__board--sub + img { width: 154px; height: auto; margin: 15px 0; }

.main-lypop.main-lypop__event .pop__board--point { color: #ff0038; }

.main-lypop.main-lypop__event .pop__board--text { color: #949494; text-align: center; font-size: 13px; font-weight: 400; }

.main-lypop.main-lypop__event .pop__board--link { display: block; margin-top: 8px; padding: 3px 8px 4px 8px; border: 1px solid #ccc; background: #fff; color: #111; font-size: 13px; font-weight: 400; letter-spacing: -0.52px; text-decoration: none; }

.main-lypop.main-lypop__event .pop__board-dl { margin-top: 20px; text-align: left; }

.main-lypop.main-lypop__event .pop__board-dt { margin-bottom: 5px; color: #666; font-size: 13px; font-weight: 700; }

.main-lypop.main-lypop__event .pop__board-dd { color: #949494; font-size: 13px; font-weight: 400; }

.main-lypop.main-lypop__event .pop__board-dd a { color: #0b83e6; font-size: 13px; font-weight: 400; text-decoration-line: underline; }

.main-lypop.main-lypop__event .pop__board .btn-style__3 { display: inline-flex; align-items: center; justify-content: center; margin-top: 20px; padding: 12px 28px 13px 30px; box-sizing: border-box; }

.main-lypop.main-lypop__event .pop__board .btn-style__3 svg { margin-left: 5px; }

.mov-pop { position: absolute; top: 50%; left: 50%; z-index: 100; width: 800px; height: 804px; background: #f4f4f4; transform: translate(-50%, -50%); }

.mov-pop .pop-close { z-index: 50; position: absolute; top: 30px; right: 30px; display: block; width: 40px; height: 40px; line-height: 200px; overflow: hidden; width: 39px; height: 39px; overflow: hidden; line-height: 200px; }

.mov-pop .pop-close:before, .mov-pop .pop-close:after { position: absolute; top: 50%; left: 50%; width: 37px; height: 1px; background-color: #a2a2a2; content: ""; }

.mov-pop .pop-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.mov-pop .pop-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.mov-pop .check-box { z-index: 50; position: absolute; bottom: 0; left: 0; background: #fff; height: 64px; width: 100%; }

.mov-pop .check-box .check-style__2 { margin: 12px 0 0 21px; }

.mov-pop .check-box .check-style__2 label { color: #666; }

.mov-pop .swiper-mov__box h4 { display: block; overflow: hidden; line-height: 200px; font-size: 0; margin: 0 0 35px; }

.mov-pop .swiper-mov__box .swiper-mov { position: relative; height: 740px; width: 800px; overflow: hidden; }

.mov-pop .swiper-mov__box .swiper-mov .swiper-wrapper { position: relative; height: 100%; width: 100%; z-index: 1; display: flex; box-sizing: content-box; }

.mov-pop .swiper-mov__box .swiper-mov .swiper-slide { position: relative; display: flex; align-items: center; flex-shrink: 0; padding: 0 0 0 90px; box-sizing: border-box; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__prev, .mov-pop .swiper-mov__box .swiper-mov .slide-btn__next { z-index: 50; position: absolute; top: 68px; bottom: 68px; display: block; width: 60px; background: #f4f4f4; text-align: center; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__prev button, .mov-pop .swiper-mov__box .swiper-mov .slide-btn__next button { position: absolute; top: 50%; display: block; width: 40px; height: 40px; margin-top: -20px; overflow: hidden; line-height: 200px; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__prev button.swiper-button-disabled, .mov-pop .swiper-mov__box .swiper-mov .slide-btn__next button.swiper-button-disabled { cursor: default; opacity: 0.3; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__prev button { left: 20px; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__prev button:before { display: block; width: 24px; height: 24px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); left: 25%; top: 18px; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__next { right: 0; width: 110px; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__next button { right: 20px; }

.mov-pop .swiper-mov__box .swiper-mov .slide-btn__next button:before { display: block; width: 24px; height: 24px; border: 1px solid #666; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); right: 25%; top: 18px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info h4 { background-image: url("/img/selleroffice/sprites/sp_main_pop_mov.png"); background-position: 0px 0px; width: 243px; height: 161px; margin: 0; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info2 h4 { background-image: url("/img/selleroffice/sprites/sp_main_pop_mov.png"); background-position: -253px 0px; width: 203px; height: 118px; margin-bottom: 23px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info2 .txt__p { font-size: 15px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info3 h4 { background-image: url("/img/selleroffice/sprites/sp_main_pop_mov.png"); background-position: 0px -171px; width: 219px; height: 79px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info4 h4 { background-image: url("/img/selleroffice/sprites/sp_main_pop_mov.png"); background-position: -229px -171px; width: 226px; height: 76px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info4 .txt__p { margin-bottom: 18px; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info4 .btn-style__2 { padding: 0 23px; font-weight: bold; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info4 .btn-style__2:hover { background: #fff; }

.mov-pop .swiper-mov__box .swiper-mov .mov-pop__info4 .btn-style__2:after { display: inline-block; margin-left: 10px; width: 8px; height: 8px; border: 1px solid #ff5462; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; vertical-align: middle; transform: translateZ(0) rotate(45deg); }

.mov-pop .swiper-mov__box .swiper-mov .txt__p { font-size: 16px; color: #666; line-height: 24px; letter-spacing: -0.6px; }

.mov-pop .swiper-mov__box .swiper-mov .txt__p strong { display: inline; font-weight: normal; color: #111; border-bottom: 1px solid #111; }

.mov-pop .swiper-mov__box .swiper-mov .txt__p strong em { font-weight: bold; }

.mov-pop .swiper-mov__box .swiper-mov .txt__p strong.txt-red { color: #ff0038; border-color: #ff0038; }

.mov-pop .swiper-mov__box .swiper-mov .txt__p-bg-red mark { display: inline; padding: 0 9px 0 1px; line-height: 24px; font-size: 15px; font-weight: bold; background-color: rgba(244, 49, 66, 0.2); }

.mov-pop .swiper-mov__box .swiper-mov .txt__p-bg-red mark strong { color: #ff0038; }

.mov-pop .swiper-mov__box .swiper-mov .txt__p-bg-red .txt__span-small { display: block; margin: 5px 0 24px 0; font-size: 11px; color: #666; letter-spacing: -0.4px; }

.mov-pop .swiper-mov__box .mov-box { z-index: 60; position: absolute; top: 63px; right: 110px; width: 285px; height: 615px; border-radius: 40px; box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2); background-color: #ffffff; }

.mov-pop .swiper-mov__box .mov-box:before { content: ""; position: absolute; top: 23px; left: 113px; display: block; width: 59px; height: 6px; opacity: 0.39; border-radius: 3.5px; background-color: #d8d8d8; }

.mov-pop .swiper-mov__box .mov-box .mov-box__in { position: absolute; top: 45px; left: 20px; width: 245px; height: 538px; background: #f4f4f4; overflow: hidden; }

.mov-pop .swiper-mov__box .mov-box .mov-box__in:after { content: ""; z-index: 10; position: absolute; top: 0; left: 0; right: 0; bottom: 0; border: 1px solid rgba(0, 0, 0, 0.1); }

.mov-pop .swiper-mov__box .mov-box .mov-box__in video { position: absolute; top: 0; left: 50%; display: block; width: auto; height: 538px; transform: translate(-50%, 0); }

.cloud-pop { position: absolute; top: 50%; left: 50%; z-index: 1000; background-color: #fff; border: 1px solid #ddd; transform: translate(-50%, -50%); }

.cloud-pop .pop-body { overflow: hidden; overflow-y: auto; max-height: 600px; }

.cloud-pop .pop-bottom { position: absolute; bottom: 0; left: 0; z-index: 50; height: 64px; }

.cloud-pop .pop-bottom .check-box { margin: 12px 0 0 21px; }

.cloud-pop .pop-bottom .check-box label { color: #666; font-size: 13px; }

.cloud-pop .pop-close { position: absolute; z-index: 1; top: 40px; right: 40px; width: 30px; height: 30px; overflow: hidden; line-height: 200px; transform: translateZ(0); }

.cloud-pop .pop-close:before, .cloud-pop .pop-close:after { position: absolute; top: 50%; left: 50%; width: 33px; height: 1px; background-color: #9b9b9b; content: ""; }

.cloud-pop .pop-close:before { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(45deg); transform: translateZ(0) translate(-50%, -50%) rotate(45deg); }

.cloud-pop .pop-close:after { -webkit-transform: translateZ(0) translate(-50%, -50%) rotate(135deg); transform: translateZ(0) translate(-50%, -50%) rotate(135deg); }

.cloud-pop.cloud-pop__main { width: 800px; height: 100vh; max-height: 602px; }

.cloud-pop.cloud-pop__main .swiper-container { overflow: hidden; position: relative; width: 798px; height: 600px; background: #fff; text-align: center; }

.cloud-pop.cloud-pop__main .list { width: 20000px; height: 100%; margin: auto; }

.cloud-pop.cloud-pop__main .list li { position: relative; height: 100%; float: left; }

.cloud-pop.cloud-pop__main .info { width: 800px; height: 600px; background-size: 800px 600px; overflow: hidden; line-height: 1000px; }

.cloud-pop.cloud-pop__main .info.info1 { background-image: url("/img/selleroffice/renewal_main/main_cloud21.png"); }

.cloud-pop.cloud-pop__main .info.info2 { background-image: url("/img/selleroffice/renewal_main/main_cloud12.png"); }

.cloud-pop.cloud-pop__main .info.info3 { background-image: url("/img/selleroffice/renewal_main/main_cloud13.png"); }

.cloud-pop.cloud-pop__main .info.info4 { background-image: url("/img/selleroffice/renewal_main/main_cloud14.png"); }

.cloud-pop.cloud-pop__main .btn_arrow { width: 40px; height: 40px; position: absolute; top: 50%; overflow: hidden; line-height: 200px; transform: translateY(-48%); }

.cloud-pop.cloud-pop__main .prev { left: 16px; }

.cloud-pop.cloud-pop__main .prev:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; left: 0; transform: translateZ(0) translate(25%, -40%) rotate(-135deg); border-bottom: 0; }

.cloud-pop.cloud-pop__main .next { right: 16px; }

.cloud-pop.cloud-pop__main .next:after { width: 20px; height: 20px; border: 1px solid #333; border-width: 1px 1px 0 0; transform-origin: center center; content: ""; position: absolute; top: 50%; right: 0; transform: translateZ(0) translate(-25%, -40%) rotate(45deg); border-bottom: 0; }

.cloud-pop.cloud-pop__main .info_more .btn-style__link { position: absolute; bottom: 17px; left: 50%; margin-left: -78px; padding: 0 15px; background-color: #fff; border: 1px solid #0b83e6; color: #0b83e6; font-size: 13px; line-height: 30px; }

.cloud-pop.cloud-pop__main .info_more .btn-style__link:after { display: inline-block; height: 8px; width: 8px; border: 1px solid #0b83e6; border-width: 0 1px 1px 0; vertical-align: 1px; transform: rotate(-45deg); content: ""; }

.cloud-pop.cloud-pop__main .swiper-button-disabled { opacity: 0.2; }

.cloud-pop.cloud-pop__main .pagination { position: absolute; bottom: 64px; width: 100%; margin-top: 8px; text-align: center; }

.cloud-pop.cloud-pop__main .pagination .swiper-pagination-bullet { display: inline-block; width: 8px; height: 8px; margin: 0 4px; border-radius: 50%; background: #c4c8d4; vertical-align: middle; opacity: 1; }

.cloud-pop.cloud-pop__main .pagination .swiper-pagination-bullet-active { background: #0b83e6; }

.calculate_guide_popup_wrap .pop-body { max-height: 602px; }

.calculate_guide_popup_wrap .pop-close { top: 9px; right: 18px; }

.calculate_guide_popup_wrap .pop-close:before, .calculate_guide_popup_wrap .pop-close:after { width: 35px; height: 2px; }

.main-nudge { position: relative; width: 100%; height: 52px; background-color: #f3f9fe !important; overflow: hidden; }

.main-nudge__item { display: flex; flex-shrink: 0; align-items: center; position: relative; width: 100%; max-width: 100%; height: 52px; font-size: 14px; line-height: 21px; padding: 0 12px 0 20px; }

.main-nudge__title { max-width: 26%; font-weight: 700; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.main-nudge__title em { color: #ff0038; }

.main-nudge__info { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 74%; }

.main-nudge__info::before { display: inline-block; width: 1px; height: 16px; margin: -2px 12px 0; background-color: #000; vertical-align: middle; content: ""; }

.main-nudge__link { display: flex; flex-shrink: 0; align-items: center; width: 100%; max-width: 100%; font-size: 14px; line-height: 21px; color: #000; }

.main-nudge__img { position: absolute; inset: 0 0 0 0; }

.main-nudge__img img { width: 100%; height: 100%; object-fit: contain; vertical-align: top; }

.main-nudge--rolling .main-nudge__item { position: absolute; padding: 0 12px 0 20px; top: -52px; left: 0; }

.main-nudge--rolling .main-nudge__item.rolling-prev { top: -52px; left: 0; transition: top 0.6s ease; }

.main-nudge--rolling .main-nudge__item.rolling-current { top: 0; transition: top 0.6s ease; }

.main-nudge--rolling .main-nudge__item.rolling-next { top: 52px; }

.main-nudge--rolling .main-nudge__title em { color: #000; }

.main-nudge--rolling::before { position: absolute; z-index: 1; left: 0; width: 100%; height: 14px; background: linear-gradient(180deg, #f3f9fe 0%, rgba(243, 249, 254, 0) 100%); content: ""; top: 0; }

.main-nudge--rolling::after { position: absolute; z-index: 1; left: 0; width: 100%; height: 14px; background: linear-gradient(360deg, #f3f9fe 0%, rgba(243, 249, 254, 0) 100%); content: ""; bottom: 0; }
