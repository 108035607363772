@charset "UTF-8";
@import "../selleroffice/mixins"; //믹스인모음
@import "../selleroffice/helpers/variables"; //변수모음
@import "../selleroffice/helpers/reset"; //스타일 초기화

@import "../selleroffice/sprites/sp_icon.scss";
@import "../selleroffice/sprites/sp_main_2x.scss";
@import "../selleroffice/sprites/sp_etc_2x";
@import "../selleroffice/sprites/sp_main_pop_mov.scss";

/* max-width: 1530px */
@media (max-width: 1530px) {
    .main-box__col:nth-child(1):nth-last-child(2),
    .main-box__col:nth-child(1):nth-last-child(2) ~ div {
        width: 100% !important;
    }
    .main-box__col:nth-child(1):nth-last-child(3),
    .main-box__col:nth-child(1):nth-last-child(3) ~ div {
        width: 50% !important;

        &.col__2 {
            width: 100% !important;
        }
    }
    .main-box__col:nth-child(1):nth-last-child(4),
    .main-box__col:nth-child(1):nth-last-child(4) ~ div {
        width: 50% !important;
    }
}

%main_btn_link {
    position: relative;
    margin-left: 23px;
    color: #999;
    font-size: 14px;
    letter-spacing: -1.2px;
    line-height: 24px;

    &:after {
        display: inline-block;
        @include ico-angle(right, 8px, #999, 1px, static);
        margin: -2px 0 0 5px;
    }
}

%main_sub_title {
    color: #333;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: -1.2px;
}

/* main layout */
.main-box__col:nth-child(1):nth-last-child(2),
.main-box__col:nth-child(1):nth-last-child(2) ~ div {
    width: 50%;
}

.main-box__col:nth-child(1):nth-last-child(3),
.main-box__col:nth-child(1):nth-last-child(3) ~ div {
    width: 25%;

    &.col__2 {
        width: 50%;
    }
}

.main-box__col:nth-child(1):nth-last-child(4),
.main-box__col:nth-child(1):nth-last-child(4) ~ div {
    width: 25%;
}

.main_wrap {
    padding: 0;
    min-width: 956px;

    .inquiry-area {
        position: relative;
        z-index: 1;

        .calculate {
            .info {
                position: absolute;
                top: -15px;
                left: -20px;
                width: 210px;
                height: 34px;
                font-size: 11px;
                font-weight: bold;
                line-height: 28px;
                color: $color-11st-red;
                letter-spacing: -0.5px;
                text-align: center;
                z-index: 2;
                background: url("/img/selleroffice/bg_info_tooltip.png") no-repeat 2px 0;
                background-size: 100% auto;
            }
        }
    }

    .btn-inquiry {
        @include sprite-retina($sp_main_2x_refresh);
        line-height: 200px;
        overflow: hidden;
    }
}

.main-box {
    display: block;
    width: 100%;
    font-size: 0;
}

.main-box__col {
    display: inline-block;
    width: 100%;
    padding: 6px 6px;
    font-size: 13px;
    vertical-align: top;

    > div {
        position: relative;
        z-index: 1;
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);
        background-color: #fff;

        > .main-spinner {
            top: 60px;
            height: calc(100% - 60px);
        }
    }
}

/* main common */
.main-tit {
    width: calc(100% - 60px);
    margin: 0 auto;
    @include clearfix();

    .main-tit__title {
        float: left;

        .main-tooltip__btn {
            margin-bottom: -1px;
        }
    }

    .main-tit__btn {
        position: relative;
        min-width: 99px;
        max-width: 55%;
        height: 24px;
        padding: 0 20px 0 10px;
        margin-left: 8px;
        border-radius: 13px;
        border: solid 1px rgba(244, 49, 66, 0.3);
        font-size: 12px;
        font-weight: bold;
        color: $color-11st-red;
        line-height: 22px;
        letter-spacing: 0px;

        .count {
            display: inline-block;
            margin-left: 2px;
            max-width: 30%;
            @include text-ellipsis;
            font-family: $font-family-number;
            font-size: 15px;
            font-weight: bold;
            vertical-align: bottom;
        }

        &:after {
            @include ico-angle(right, 6px, $color-11st-red, 1px, absolute);
            right: 10px;
        }
    }

    .text {
        float: left;
        font-size: 18px;
        color: #111;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: -1.4px;
    }

    .main-tit__right {
        float: right;
    }

    .main-tit__left {
        float: left;
    }

    .btn-link {
        @extend %main_btn_link;
    }

    .c-tooltip {
        vertical-align: middle;
        margin-left: 6px;
    }
}

.main-subtit {
    color: #333;
    font-size: 15px;
    font-weight: bold;
    line-height: 22px;
    letter-spacing: -1.2px;
}

.main-tab {
    width: 100%;
    height: 43px;
    font-size: 0;
    @include clearfix;

    li {
        position: relative;
        z-index: 1;
        float: left;
        height: 43px;
        border: 1px solid #eee;
        border-left: none;
        background-color: #fafafa;

        &:first-child {
            border-left: none;
        }
        &:last-child {
            border-right: none;
        }
        > a,
        > button {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 14px;
            line-height: 43px;
            letter-spacing: -1.2px;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .active {
        z-index: 2;
        border-bottom: 1px solid #fff;
        background-color: #fff;

        a,
        button {
            color: #0b83e6;
            font-weight: bold;
        }
    }
}

.main-box-tab {
    height: 42px;
    font-size: 0;
    @include clearfix;

    li {
        position: relative;
        z-index: 1;
        float: left;
        height: 42px;
        border: 1px solid #eee;
        background-color: #fafafa;

        & + li {
            margin-left: -1px;
        }

        > button {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 14px;
            letter-spacing: -1.2px;
            padding: 0 24px 2px;
            height: 42px;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .active {
        z-index: 2;
        border: 1px solid #92c8f3;
        background-color: #fff;

        a,
        button {
            color: #0b83e6;
            font-weight: bold;
        }
    }
}

.main-info__1 {
    width: 100%;
    min-height: 44px;
    padding: 0 12px;
    margin-top: 8px;
    background-color: #f4f4f4;
    border-radius: 2px;
    color: #333;
    font-size: 12px;
    text-align: center;
    letter-spacing: -1.2px;
    line-height: 44px;

    & + & {
        margin-top: 12px;
    }
    .link {
        position: relative;
        z-index: 1;
        color: #0b83e6;
        font-size: 12px;
        margin-left: 6px;
        padding-right: 10px;

        &:after {
            display: inline-block;
            @include ico-angle(right, 6px, $color-secondary, 1px, absolute);
        }
    }
    .text {
        display: inline-block;
        max-width: 51%;
        padding: 0 3px;
        @include text-ellipsis();
        vertical-align: middle;

        & + .link {
            display: inline-block;
            max-width: 40%;
            @include text-ellipsis();
            vertical-align: middle;
        }
    }
    .point {
        color: $color-11st-blue;
    }
    .vc {
        line-height: 1.5;
    }
}

.main-info__2 {
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 74px;
    padding: 17px 96px 17px 16px;
    margin-top: 14px;
    background-color: #f4f4f4;
    border-radius: 2px;
    color: #333;
    font-size: 13px;
    text-align: left;
    letter-spacing: -1.2px;
    line-height: 24px;

    .link {
        position: absolute;
        top: 15px;
        right: 16px;
        z-index: 1;
        color: #0b83e6;
        font-size: 13px;
        line-height: 19px;

        &:after {
            display: inline-block;
            @include ico-angle(right, 6px, $color-secondary, 1px, static);
            margin: -2px 0 0 2px;
        }
    }
}

.main-radio__tab {
    display: table;
    table-layout: fixed;

    .inp {
        position: absolute;
        display: inline-block;
        opacity: 0;
        width: 0;
        height: 0;
    }
    .lab {
        display: table-cell;
        position: relative;
        z-index: 1;
        float: left;
        margin-left: -1px;
        padding: 3px 10px;
        min-width: 103px;
        border: 1px solid #ddd;
        background: #fff;
        font-size: 13px;
        line-height: 29px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
        cursor: pointer;
        overflow: hidden;

        &:first-child {
            margin-left: 0px;
        }
    }
    .active {
        z-index: 2;
        color: #0b83e6;
        border: 1px solid #0b83e6;
    }
}

.main-spinner {
    display: none;
    position: absolute;
    z-index: 3;
    top: 0;
    height: 0;
    width: 100%;
    height: 100%;
    font-size: 15px;
    color: $color-11st-red;
    letter-spacing: -1.2px;
    line-height: 22px;
    background: #fff;
    text-align: center;

    p {
        display: block;
        vertical-align: middle;

        &:last-child {
            &:before {
                display: inline-block;
                margin: -2px 10px 0 0;
                vertical-align: middle;
                overflow: hidden;
                content: "";
                @include sprite-retina($sp_main_2x_info_spinner);
            }
        }
    }
    .loading {
        display: inline-block;
        vertical-align: middle;
        margin-top: 24px;
    }
    &.active {
        display: block;
    }
}

.main-tooltip {
    position: absolute;
    z-index: 50;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
    background-color: #fff;

    .tooltip-header {
        position: relative;
        z-index: 2;
        padding: 18px 49px 0px 20px;
        height: 60px;
        border-bottom: 1px solid #eee;
        text-align: center;
        background-color: #fff;

        .tooltip-header__title {
            font-weight: bold;
            font-size: 18px;
            color: #000;
            line-height: 1.44;
            letter-spacing: -0.6px;
        }
    }

    .tooltip-body {
        padding: 20px 18px;
    }

    .tooltip-close {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;
        @include ico-cancel(12px, 1px, #999, 14px);
    }

    .tooltip-list {
        font-size: 14px;
        color: #111;
        line-height: 1.71;
        letter-spacing: -0.4px;

        li {
            position: relative;
            z-index: 1;
            padding-left: 14px;

            &:before {
                position: absolute;
                z-index: 1;
                left: 0;
                width: 14px;
                text-align: center;
                content: "•";
            }
        }
    }

    p.tooltip-list {
        position: relative;
        z-index: 1;
        padding-left: 14px;
        font-size: 14px;
        color: #111;
        line-height: 1.71;
        letter-spacing: -0.4px;

        &:before {
            position: absolute;
            z-index: 1;
            left: 0;
            width: 14px;
            text-align: center;
            content: "•";
        }
    }
}

.main-tooltip__btn {
    margin-left: 5px;
    @include sprite-retina($sp_main_2x_icon_info);
    overflow: hidden;
    line-height: 200px;
}

.main-tooltip__btn--small {
    margin-left: 3px;
    @include sprite-retina($sp_main_2x_icon_info_s);
    overflow: hidden;
    line-height: 200px;
}

/* main content */
//11톡 반영 후 삭제
.main-seller {
    position: relative;
    z-index: 1;
    height: 90px;
    padding: 0 82px 0 35px;
    font-size: 0;
    @include clearfix();

    .seller-profile {
        float: left;
        width: 27%;
        padding: 20px 0;

        .seller-profile__noti {
            height: 28px;
            padding: 0 12px;
            position: absolute;
            z-index: 2;
            top: -20px;
            left: 35px;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.8);
            font-size: 12px;
            color: #fff;
            line-height: 26px;

            &:after {
                position: absolute;
                left: 14px;
                bottom: -7px;
                @include ico-triangle(down, 12px, 7px, rgba(0, 0, 0, 0.8), static);
            }
        }
        .seller-profile__pic {
            position: relative;
            z-index: 1;
            float: left;
            width: 50px;
            height: 50px;

            .seller-pic {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                overflow: hidden;

                .img {
                    width: 50px;
                    height: 50px;
                }
            }
            .store-admin {
                position: absolute;
                z-index: 1;
                right: -5px;
                bottom: -5px;
                @include sprite-retina($sp_main_2x_store_admin);
            }
        }
        .seller-profile__info {
            position: relative;
            float: left;
            width: 75%;
            padding-left: 20px;

            .store-name {
                position: relative;
                display: inline-block;
                max-width: 100%;
                height: 27px;

                .name {
                    display: inline-block;
                    max-width: 100%;
                    height: 27px;
                    padding-right: 12px;
                    font-size: 18px;
                    color: #111;
                    font-weight: 500;
                    text-align: left;
                    letter-spacing: -1.3px;
                    line-height: 27px;
                    vertical-align: middle;
                    @include text-ellipsis();
                }
                &:after {
                    @include ico-angle(right, 8px, #999, 1px, absolute);
                    right: 2px;
                }
            }
            .seller-profile__like {
                display: block;
                margin-top: 4px;
                color: rgba(102, 102, 102, 0.7);
                font-size: 15px;
                letter-spacign: -1.4px;

                .count {
                    margin-left: 3px;
                    font-family: $font-family-number;
                    letter-spacing: 0;

                    &.active {
                        color: #0b83e6;
                    }
                }
            }
        }
    }
    .seller-inquiry {
        float: left;
        width: 73%;
        min-height: 90px;
        position: relative;
        z-index: 1;

        .list {
            width: 100%;
            height: 90px;
            display: inline-table;
        }
        .item {
            position: relative;
            z-index: 1;
            display: table-cell;
            width: 25%;
            min-width: 153px;
            height: 90px;
            padding: 0 5px;
            font-size: 15px;
            line-height: 40px;
            vertical-align: middle;
            text-align: center;
            color: #666;

            &:not(:first-child):after {
                display: inline-block;
                position: absolute;
                z-index: 1;
                top: 28px;
                left: 0;
                width: 1px;
                height: 34px;
                opacity: 0.44;
                background: #ddd;
                content: "";
            }
            .title {
                display: inline-block;
            }
            .count {
                display: inline-block;
                margin-left: 18px;
                color: #ddd;
                font-family: $font-family-number;
                font-size: 34px;
                line-height: 40px;
                vertical-align: top;

                a {
                    color: #ddd;
                }
                &.active {
                    a {
                        color: $color-11st-red;
                        font-weight: bold;
                    }
                }
            }
        }
        .main-spinner {
            p {
                display: inline-block;
            }
            .loading {
                margin-left: 18px;
                margin-top: 0;
            }
        }
        &:before,
        &:after {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 0;
            margin-top: -17px;
            width: 1px;
            height: 34px;
            opacity: 0.44;
            background-color: #eeeeee;
            content: "";
        }
        &:before {
            left: 0;
        }
    }
    .btn-inquiry {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 30px;
        margin-top: -11px;
    }
}
//11톡추가
.main-seller.ver2 {
    position: relative;
    z-index: 1;
    height: 150px;
    font-size: 0;
    padding: 0 0 0 25px;
    @include clearfix();

    .seller-profile {
        float: left;
        width: calc(25% - 30px);
        min-width: 220px;
        height: 150px;
        padding: 30px 10px 30px 0;
        border-right: 1px solid #eee;

        .seller-profile__noti {
            height: 28px;
            padding: 0 12px;
            position: absolute;
            z-index: 2;
            top: -20px;
            left: 50px;
            border-radius: 14px;
            background: rgba(0, 0, 0, 0.8);
            font-size: 12px;
            color: #fff;
            line-height: 26px;

            &:after {
                position: absolute;
                left: 14px;
                bottom: -7px;
                @include ico-triangle(down, 12px, 7px, rgba(0, 0, 0, 0.8), static);
            }
        }
        .seller-profile__pic {
            position: relative;
            display: inline-block;
            width: 90px;
            height: 90px;

            .seller-pic {
                width: 90px;
                height: 90px;
                border-radius: 45px;
                overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.05);

                .img {
                    width: 90px;
                    height: 90px;
                }
            }
            .store-admin {
                position: absolute;
                z-index: 1;
                right: 0;
                bottom: 0;
                @include sprite-retina($sp_main_2x_store_admin);
            }
        }
        .seller-profile__info {
            position: relative;
            float: left;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            width: calc(100% - 90px);
            padding-left: 10px;

            .store-name {
                position: relative;
                display: inline-block;
                max-width: 100%;
                height: 27px;
                margin-bottom: 5px;

                .name {
                    display: inline-block;
                    max-width: 100%;
                    height: 27px;
                    padding-right: 12px;
                    font-size: 18px;
                    color: #111;
                    font-weight: 500;
                    text-align: left;
                    letter-spacing: -1.3px;
                    line-height: 27px;
                    vertical-align: middle;
                    @include text-ellipsis();
                }
                &:after {
                    @include ico-angle(right, 8px, #999, 1px, absolute);
                    right: 2px;
                }
            }
            .seller-profile__like {
                display: block;
                margin-top: 4px;
                color: rgba(102, 102, 102, 0.7);
                font-size: 15px;
                letter-spacing: -1.4px;

                .count {
                    margin-left: 3px;
                    font-family: $font-family-number;
                    letter-spacing: 0;

                    &.active {
                        color: #0b83e6;
                    }
                }
            }
        }
    }
    .seller-inquiry {
        float: left;
        width: calc(75% - 64px);
        min-height: 90px;
        position: relative;
        z-index: 1;

        .list {
            width: 100%;
            height: 90px;
            display: inline-table;
        }
        .item {
            position: relative;
            z-index: 1;
            display: table-cell;
            width: 25%;
            min-width: 153px;
            height: 90px;
            padding: 0;
            font-size: 15px;
            line-height: 40px;
            vertical-align: middle;
            text-align: center;
            color: #666;

            &:not(:first-child):after {
                display: inline-block;
                position: absolute;
                z-index: 1;
                top: 28px;
                left: 0;
                width: 1px;
                height: 34px;
                opacity: 0.44;
                background: #ddd;
                content: "";
            }
            .title {
                display: inline-block;
                margin: 0 18px;
            }
            .count {
                display: inline-block;
                margin: 0 18px;
                color: #ddd;
                font-family: $font-family-number;
                font-size: 34px;
                line-height: 40px;
                vertical-align: top;
                max-width: 120px;
                @include text-ellipsis();

                a {
                    color: #ddd;
                }
                &.active {
                    a {
                        color: $color-11st-red;
                        font-weight: bold;
                    }
                }
            }
        }
        .main-spinner {
            p {
                display: inline-block;
            }
            .loading {
                margin-left: 18px;
                margin-top: 0;
            }
        }
        &:before,
        &:after {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: 0;
            margin-top: -17px;
            width: 1px;
            height: 34px;
            opacity: 0.44;
            background-color: #eeeeee;
            content: "";
        }
        &:before {
            left: 0;
        }
    }
    .btn-inquiry {
        position: absolute;
        z-index: 1;
        top: calc(50% - 29px);
        right: 30px;
        margin-top: -11px;
    }
    .seller-11talk {
        position: relative;
        float: left;
        width: 76%;
        height: 59px;
        padding: 17px 0 17px 2%;
        border-top: 1px solid #fafafa;

        .seller-11talk__title {
            display: inline-block;
            margin-right: 8px;
            vertical-align: bottom;
            font-size: 15px;
            font-family: $font-family-number;
            font-weight: bold;
            letter-spacing: -1.38px;
            color: #000;

            &::before {
                display: inline-block;
                margin: -2px 5px 0 0;
                @include sprite-retina($sp_main_2x_talk);
                vertical-align: middle;
                content: "";
            }
        }
        .seller-11talk__time {
            display: inline-block;
            max-width: calc(100% - 60px);
            font-size: 13px;
            letter-spacing: -0.17px;
            color: #333;

            &.on {
                .state {
                    background-color: $color-11st-red;
                }
            }
            &.out {
                color: #bbb;

                .state {
                    background-color: #bbb;
                }
            }
            &.off {
                .state {
                    background-color: #333;
                }
            }
            .state {
                padding: 0 8px;
                margin-right: 2px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 13px;
                text-align: center;
                color: #fff;
                line-height: 24px;
            }
            .time {
                font-family: $font-family-number;
            }
            .btn-talk {
                font-size: 14px;
                letter-spacing: -1.4px;
                color: #333;
                vertical-align: middle;

                &:after {
                    display: inline-block;
                    @include ico-angle(right, 8px, #111, 1px, static);
                    margin: -2px 0 0 2px;
                    content: "";
                }
            }
            .text {
                display: inline-block;
                margin-left: 14px;
                vertical-align: middle;
                font-weight: bold;
                font-size: 15px;
                letter-spacing: -1px;
                color: #333;
            }
        }
        .seller-11talk__state {
            display: inline-block;

            .state {
                display: inline-block;
                padding: 0 8px;
                margin-right: 2px;
                margin-left: 6px;
                display: inline-block;
                border-radius: 13px;
                text-align: center;
                line-height: 24px;
                font-size: 13px;
                background-color: #fff;

                &.level-1 {
                    border: solid 1px rgba(55, 197, 144, 0.3);
                    color: #37c590;
                }
                &.level-2 {
                    border: solid 1px rgba(248, 185, 7, 0.3);
                    color: #f8b907;
                }
                &.level-3 {
                    border: solid 1px rgba(244, 49, 66, 0.3);
                    color: $color-11st-red;
                }
            }

            .grade {
                color: #666;
                letter-spacing: -1.5px;
                display: inline-block;
                font-size: 14px;

                .number {
                    font-family: $font-family-number;
                    font-size: 16px;
                    font-weight: bold;
                    color: #111;
                    margin-left: 6px;
                }
            }
        }
        .btn-link {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: -14px 0 0 5px;
            overflow: hidden;
            line-height: 200px;
            vertical-align: middle;

            &::after {
                display: block;
                @include ico-angle(right, 8px, #999, 1px, absolute);
                right: 16px;
            }
        }
        .link_guide {
            position: absolute;
            top: 20px;
            right: 12px;
            display: inline-block;
            font-size: 13px;
            color: #0b83e6;
            letter-spacing: -1px;

            &::after {
                display: inline-block;
                margin: -1px -12px 0 0;
                @include ico-angle(right, 8px, #0b83e6, 1px, absolute);
            }
        }
    }
}

.main-grade {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 30px;
    @include clearfix();

    .chart {
        width: calc(100% - 60px);
        margin: 40px auto 0;
        @include clearfix();

        li {
            margin-bottom: 27px;
            @include clearfix();

            &:last-child {
                margin-bottom: 33px;
            }
            &.level-1 {
                .grade {
                    color: #37c590;
                }
                .bar_bg {
                    border-color: #37c590;
                }
                .bar {
                    background-color: #37c590;
                }
            }
            &.level-2 {
                .grade {
                    color: #f8b907;
                }
                .bar_bg {
                    border-color: #f6c03a;
                }
                .bar {
                    background-color: #ffca44;
                }
            }
            &.level-3 {
                .grade {
                    color: $color-11st-red;
                }
                .bar_bg {
                    border-color: #d82c3b;
                }
                .bar {
                    background-color: $color-11st-red;
                }
            }
        }
        .chart-grapic {
            float: left;
            width: 80%;
            height: 35px;

            .title {
                font-size: 15px;
                color: #666;
                line-height: 22px;
                letter-spacing: -1.1px;
            }
            .bar {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                height: 5px;
                border-radius: 3.5px;
                background: #999;
                border-color: #8b8b8b;
            }
            .bar_bg {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 5px;
                margin-top: 8px;
                background-color: #ddd;
                border-radius: 3.5px;
                box-shadow: 0px 0px 0px 1px #d8d8d8 inset;
                overflow: hidden;
            }
        }
        .chart-grade {
            float: right;
            width: 20%;
            height: 35px;
            text-align: right;

            .grade {
                display: block;
                font-size: 14px;
                font-weight: bold;
                color: #999;
            }
            .per {
                display: block;
                font-size: 12px;
                color: #333;
                font-family: $font-family-number;
                letter-spacing: 0;
                font-weight: 500;
            }
        }
    }
    .info {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-top: 22px;
        color: #999;
        font-size: 12px;
        line-height: 18px;
        border-top: 1px solid #eee;

        li {
            margin-top: 3px;

            &:before {
                display: inline-block;
                width: 3px;
                height: 3px;
                background: #999;
                border-radius: 50%;
                content: "";
                line-height: 18px;
                vertical-align: middle;
                margin-right: 5px;
            }
            .date {
                font-family: $font-family-number;
                letter-spacing: 0;
                margin-left: 6px;
            }
        }
    }
    .noti {
        height: 75px;
        padding: 11px 30px;
        margin-top: 20px;
        border-top: 1px solid #f3ecce;
        border-bottom: 1px solid #f3ecce;
        background: #fffbe9;
        color: #999;
        line-height: 16px;
        font-size: 12px;
        letter-spacing: 0.4px;

        strong {
            display: block;
            margin-bottom: 4px;
            color: #000;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.4px;
            font-weight: bold;
        }
        .vc {
            max-height: 53px;
            overflow: hidden;
        }
    }
    .noti + .chart {
        margin-top: 8px;

        li {
            margin-bottom: 13px;
        }
    }
    .noti ~ .info {
        padding-top: 11px;
    }
}

.main-order {
    min-height: 380px;
    max-height: 380px;
    padding-top: 30px;

    .inquiry-area {
        width: 100%;
        height: 321px;
        padding: 28px 30px 0;

        .main-spinner {
            width: calc(100% - 60px);
        }
    }
    .list {
        width: 100%;

        li {
            width: 100%;
            padding: 12px 0;
            border-bottom: 1px solid #f5f5f5;
            @include clearfix();

            &:last-child {
                border-bottom: none;
            }
        }
        .title {
            position: relative;
            z-index: 1;
            float: left;
            padding-left: 46px;
            font-size: 15px;
            line-height: 30px;

            &:before {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: block;
                width: 30px;
                height: 30px;
                overflow: hidden;
                content: "";
            }
            &.icon1 {
                &:before {
                    @include sprite-retina($sp_main_2x_icon_order1);
                }
            }
            &.icon2 {
                &:before {
                    @include sprite-retina($sp_main_2x_icon_order2);
                }
            }
            &.icon3 {
                &:before {
                    @include sprite-retina($sp_main_2x_icon_order3);
                }
            }
            &.icon4 {
                &:before {
                    @include sprite-retina($sp_main_2x_icon_order4);
                }
            }
            &.icon5 {
                &:before {
                    @include sprite-retina($sp_main_2x_icon_order5);
                }
            }
        }
        .count {
            float: right;
            font-size: 18px;
            color: #0b83e6;
            font-weight: bold;
            line-height: 30px;
            letter-spacing: 0;

            a {
                font-family: $font-family-number;
                color: #0b83e6;
            }
        }
    }
}

.main-request {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 30px;

    .inquiry-area {
        width: 100%;
        height: 321px;
        padding: 27px 30px 0;

        .main-spinner {
            width: calc(100% - 60px);
        }
    }

    .list {
        li {
            width: 100%;
            padding: 26px 0;
            border-bottom: 1px solid #f5f5f5;
            @include clearfix();

            a {
                display: block;
            }

            &:last-child {
                border-bottom: none;
            }
        }

        li:nth-child(1):nth-last-child(4),
        li:nth-child(1):nth-last-child(4) ~ li {
            padding: 13px 0;
        }

        .title {
            position: relative;
            z-index: 1;
            float: left;
            width: 60%;
            height: 44px;
        }
        .text {
            display: block;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #333;
            letter-spacing: -1.1px;
        }
        .sub_text {
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: #999;

            a {
                display: inline-block;
                font-size: 14px;
                color: #999;
                font-weight: bold;
                margin-left: 5px;
                font-family: $font-family-number;
                letter-spacing: 0;
            }
            .count {
                font-weight: bold;
            }
        }
        li > .count {
            width: 40%;
            float: right;
            color: #0b83e6;
            font-size: 18px;
            font-weight: bold;
            line-height: 30px;
            text-align: right;

            a {
                color: #0b83e6;
                font-size: 26px;
                font-weight: bold;
                font-family: $font-family-number;
                line-height: 44px;
                letter-spacing: 0;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.main-cacl {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 74px;

    .inquiry-area {
        width: calc(100% - 60px);
        margin: 23px auto 0;
        height: 298px;
    }

    .calculate {
        width: 100%;
        position: relative;
        z-index: 1;
        height: 106px;

        dl {
            width: 100%;
            line-height: 52px;

            &:after {
                position: absolute;
                top: 52px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #eee;
                content: "";
            }

            dt {
                position: relative;
                float: left;
                width: 23%;
                color: #333;
                font-size: 15px;
                font-weight: bold;
                text-align: left;
            }

            dd {
                float: right;
                width: 77%;
                text-align: right;

                a {
                    color: #0b83e6;
                    font-size: 18px;
                    font-weight: bold;
                    font-family: $font-family-number;
                    letter-spacing: 0;
                }

                .unit {
                    display: inline-block;
                    margin-left: 2px;
                    color: #0b83e6;
                    font-size: 18px;
                    line-height: 52px;
                }
            }
        }

        .title {
            font-size: 15px;
            color: #666;
            letter-spacing: -1.2px;

            em {
                display: inline-block;
                font-weight: bold;
            }
        }

        .count {
            margin-top: 11px;
            display: block;
            color: #0b83e6;

            a {
                font-weight: bold;
                font-size: 26px;
                font-family: $font-family-number;
                color: #0b83e6;
                line-height: 30px;
                letter-spacing: 0;
            }
            .unit {
                font-size: 18px;
                margin-left: 4px;
                line-height: 30px;
            }
        }
    }

    .list {
        width: 100%;
        padding: 14px 12px;
        background-color: #fafafa;
        @include clearfix();

        li {
            @include clearfix();
        }

        .c-tooltip {
            height: 22px;
            font-size: 0;
            vertical-align: middle;
        }

        .title {
            float: left;
            width: 50%;
            color: #666;
            line-height: 22px;
        }

        .text {
            display: inline-block;
            font-size: 14px;
            color: rgba(102, 102, 102, 0.8);
            line-height: 22px;
            vertical-align: middle;
        }

        .count {
            float: right;
            width: 50%;
            padding-right: 1px;
            color: #333;
            font-size: 14px;
            text-align: right;
            line-height: 22px;

            a {
                font-weight: bold;
                font-family: $font-family-number;
                color: #333;
                letter-spacing: 0;
            }

            .unit {
                display: inline-block;
                font-size: 14px;
                margin-left: 2px;
                line-height: 22px;

                &.point {
                    font-weight: bold;
                }
            }
        }
    }

    .main-noti {
        margin-top: 6px;
    }

    .btn-link {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        height: 44px;
        border: solid 1px #ddd;
        background-color: #ffffff;
        font-size: 13px;
        color: #333;
        margin-top: 7px;
        letter-spacing: -1.2px;
        line-height: 44px;
        text-align: center;

        &:after {
            display: inline-block;
            @include ico-angle(right, 8px, #999, 1px, static);
        }
    }

    .btn-wrap {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        box-sizing: border-box;

        .btn-link__em {
            width: 100%;
        }
    }

    .main-info__1 {
        position: absolute;
        bottom: 30px;
        left: 0;
    }
}

.main-cacl.main-cacl--style2 {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 74px;

    .inquiry-area {
        width: calc(100% - 60px);
        margin: 23px auto 0;
        height: 298px;
    }

    .calculate {
        width: 100%;
        position: relative;
        z-index: 1;
        height: 99px;

        dl {
            width: 100%;
            line-height: 52px;

            &:after {
                position: absolute;
                top: 52px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #eee;
                content: "";
            }

            dt {
                position: relative;
                float: left;
                width: 23%;
                color: #333;
                font-size: 15px;
                font-weight: bold;
                text-align: left;
                line-height: 52px;
            }

            dd {
                float: right;
                width: 77%;
                text-align: right;

                a {
                    color: #0b83e6;
                    font-size: 18px;
                    font-weight: bold;
                    font-family: $font-family-number;
                    letter-spacing: 0;
                }

                .unit {
                    display: inline-block;
                    margin-left: 2px;
                    color: #0b83e6;
                    font-size: 18px;
                    line-height: 49px;
                }
            }
        }

        .title {
            font-size: 15px;
            color: #666;
            letter-spacing: -1.2px;

            em {
                display: inline-block;
                font-weight: bold;
            }
        }

        .count {
            margin-top: 11px;
            display: block;
            color: #0b83e6;

            a {
                font-weight: bold;
                font-size: 26px;
                font-family: $font-family-number;
                color: #0b83e6;
                line-height: 30px;
                letter-spacing: 0;
            }
            .unit {
                font-size: 18px;
                margin-left: 4px;
                line-height: 30px;
            }
        }
    }

    .list {
        width: 100%;
        padding: 9px 12px;
        background-color: #fafafa;
        @include clearfix();

        li {
            @include clearfix();
        }

        .c-tooltip {
            height: 22px;
            font-size: 0;
            vertical-align: middle;
        }

        .title {
            float: left;
            width: 50%;
            color: #666;
            line-height: 22px;
        }

        .text {
            display: inline-block;
            font-size: 14px;
            color: rgba(102, 102, 102, 0.8);
            line-height: 22px;
            vertical-align: middle;
        }

        .count {
            float: right;
            width: 50%;
            padding-right: 1px;
            color: #333;
            font-size: 14px;
            text-align: right;
            line-height: 22px;

            a {
                font-weight: bold;
                font-family: $font-family-number;
                color: #333;
                letter-spacing: 0;
            }

            .unit {
                display: inline-block;
                font-size: 14px;
                margin-left: 2px;
                line-height: 22px;

                &.point {
                    font-weight: bold;
                }
            }
        }
    }

    .main-noti {
        margin-top: 6px;
    }

    .btn-link {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        height: 44px;
        border: solid 1px #ddd;
        background-color: #ffffff;
        font-size: 13px;
        color: #333;
        margin-top: 7px;
        letter-spacing: -1.2px;
        line-height: 44px;
        text-align: center;

        &:after {
            display: inline-block;
            @include ico-angle(right, 8px, #999, 1px, static);
        }
    }

    .btn-wrap {
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        box-sizing: border-box;

        .btn-link__em {
            width: 100%;
        }
    }

    .main-info__1 {
        position: absolute;
        bottom: 30px;
        left: 0;
    }
}

.main-clean {
    min-height: 380px;
    max-height: 380px;
    padding-top: 30px;

    .inquiry-area {
        width: 100%;
        height: 321px;
        padding: 20px 30px 0;

        .main-spinner {
            width: calc(100% - 60px);
        }
    }

    .list {
        width: 100%;

        div {
            width: 100%;
            padding: 11px 0;
            border-bottom: 1px solid #f5f5f5;
            @include clearfix();
        }

        dt {
            position: relative;
            z-index: 1;
            float: left;
            font-size: 15px;
            line-height: 30px;
            color: #333;
        }

        .count {
            float: right;
            font-size: 18px;
            color: #0b83e6;
            font-weight: bold;
            line-height: 30px;
            letter-spacing: 0;

            a {
                font-family: $font-family-number;
                color: #0b83e6;
            }
        }
    }

    .info {
        width: 100%;
        margin: 4px auto 0;
        color: #999;
        font-size: 12px;
        line-height: 18px;

        &:first-of-type {
            margin-top: 15px;
        }

        &:before {
            display: inline-block;
            width: 3px;
            height: 3px;
            margin: -1px 5px 0;
            background: #999;
            border-radius: 50%;
            line-height: 18px;
            vertical-align: middle;
            content: "";
        }

        .date {
            font-family: $font-family-number;
            letter-spacing: 0;
            margin-left: 6px;
        }
    }
}

.main-quicklink {
    min-height: 380px;
    max-height: 380px;
    border-radius: 3px !important;
    border: 1px solid #ddd;
    border-left: none;
    border-bottom: none;

    ul {
        overflow: hidden;

        li {
            width: 50%;
            height: 76px;
            float: left;
            border-bottom: 1px solid #ddd;
            border-left: 1px solid #ddd;

            a {
                display: block;
                line-height: 76px;
                padding: 0 15px;
                font-family: Roboto;
                font-size: 14px;
                color: #333;
                letter-spacing: -1.4px;

                &:before {
                    content: "";
                    display: inline-block;
                    margin-right: 4px;
                    position: relative;
                    top: 7px;
                }
            }

            &:nth-child(1) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink01);
            }
            &:nth-child(2) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink02);
            }
            &:nth-child(3) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink03);
            }
            &:nth-child(4) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink04);
            }
            &:nth-child(5) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink05);
            }
            &:nth-child(6) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink06);
            }
            &:nth-child(7) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink07);
            }
            &:nth-child(8) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink08);
            }
            &:nth-child(9) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink09);
            }
            &:nth-child(10) a:before {
                @include sprite-retina($sp_main_2x_ico_quicklink10);
            }
        }
    }
}

.btn-link__em {
    display: inline-block;
    min-width: 120px;
    height: 40px;
    padding: 0 10px 0;
    background-color: $color-11st-red;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0;
    cursor: pointer;

    &:after {
        display: inline-block;
        @include ico-angle(right, 10px, #fff, 1px, static);
        margin: -2px 0 0 3px;
    }
}

.btn-wrap {
    position: relative;
    text-align: center;

    .text-guide {
        position: relative;
        top: -5px;
        display: inline-block;
        padding: 2px 8px;
        background-color: #fef0f2;
        border: solid 1px #ffe5e8;
        border-radius: 4px;
        color: $color-11st-red;
        font-size: 10px;
        letter-spacing: 0;

        &:before {
            position: absolute;
            width: 7px;
            height: 7px;
            bottom: -4px;
            left: 50%;
            margin-left: -5px;
            border: solid 1px #ffe5e8;
            vertical-align: middle;
            transform: rotate(45deg);
            content: "";
        }

        &:after {
            position: absolute;
            width: 7px;
            height: 7px;
            bottom: -3px;
            left: 50%;
            margin-left: -5px;
            background-color: #fef0f2;
            vertical-align: middle;
            transform: rotate(45deg);
            content: "";
        }
    }
}

.main-relation {
    font-size: 0;

    ul {
        width: 100%;
        border: 1px solid #ddd;
        border-bottom: none;
    }

    li {
        display: inline-block;
        width: 20%;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        font-size: 14px;
        text-align: center;
        line-height: 53px;

        &:nth-child(5n) {
            border-right: none;
        }

        a {
            color: #333;

            &.kakao {
                &:before {
                    position: relative;
                    top: 4px;
                    display: inline-block;
                    margin-right: 5px;
                    @include sprite-retina($sp_etc_2x_ico_kakao);
                    content: "";
                }
            }

            &:hover {
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}

.main-tip {
    position: relative;
    z-index: 1;
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 25px;

    .nav {
        display: inline-block;
        font-size: 0;
        vertical-align: middle;

        li {
            display: inline-block;

            &.active {
                button,
                a {
                    span {
                        background-color: #0b83e6;
                    }
                }
            }
        }
        button,
        a {
            height: 22px;
            padding: 2px;

            span {
                display: block;
                width: 8px;
                height: 8px;
                background-color: #c4c8d4;
                border-radius: 4px;
                overflow: hidden;
                line-height: 200px;
            }
        }
    }
    .btn_autoplay {
        display: inline-block;
        overflow: hidden;
        position: relative;
        right: 0;
        bottom: 1px;
        width: 21px;
        height: 22px;
        line-height: 200px;
        z-index: 10;

        &:before {
            display: block;
            position: absolute;
            z-index: 1;
            top: calc(50% + 1px);
            left: 50%;
            transform: translate(-50%, -50%);
            transform-origin: 0 0;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 8px solid #333;
            border-radius: 1px;
            content: "";
        }
        &.stop {
            &:before {
                display: none;
            }
            &:after {
                display: block;
                position: absolute;
                z-index: 1;
                top: calc(50% + 1px);
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: 0 0;
                width: 7px;
                height: 8px;
                border-right: 2px solid #333;
                border-left: 2px solid #333;
                content: "";
            }
        }
    }
    .noti {
        height: 75px;
        padding: 11px 30px;
        margin-top: 25px;
        border: 1px solid #f3ecce;
        border-left: 0;
        border-right: 0;
        background: #fffbe9;
        color: #333;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: -1px;

        .vc {
            max-height: 42px;
            overflow: hidden;
            @include text-ellipsis-multiple(2);
        }
        strong {
            display: block;
            margin-bottom: 4px;
            color: #000;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.4px;
            font-weight: bold;
        }
    }
    .explain {
        width: calc(100% - 60px);
        height: 112px;
        margin: 11px auto 0;
        font-size: 14px;
        color: #666;
        line-height: 2;
        overflow: hidden;
        @include text-ellipsis-multiple(4);
    }
    .chart {
        width: calc(100% - 60px);
        margin: 0 auto;

        .chart-grapic {
            position: relative;
            z-index: 1;
            padding-top: 17px;

            .bar_bg {
                width: 100%;
                padding-right: 70px;
                height: 3px;
                background-color: #37c590;
            }
            .bar {
                position: relative;
                z-index: 1;
                height: 3px;
                width: 100%;
            }
            .per {
                display: block;
                position: absolute;
                z-index: 2;
                top: -9px;
                left: 0;
                width: 40px;
                height: 18px;
                border: 2px solid transparent;
                border-radius: 16px;
                background-color: #fff;
                text-align: center;
                color: #fff;
                line-height: 14px;

                em,
                .unit {
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: 700;
                    font-size: 15px;
                    font-family: $font-family-number;
                    letter-spacing: -0.4px;
                }
            }
            .target {
                margin-top: 2px;
                color: #37c590;
                text-align: right;
                font-size: 12px;
                font-family: $font-family-number;
                line-height: 16px;
                letter-spacing: -0.6px;
            }
        }
        &.level-3 {
            .bar,
            .per {
                color: $color-11st-red;
                border-color: $color-11st-red;
            }
            .bar {
                background-color: $color-11st-red;
            }
        }
        &.level-2 {
            .bar,
            .per {
                color: #ffca44;
                border-color: #ffca44;
            }
            .bar {
                background-color: #ffca44;
            }
            .per {
                margin-left: -20px;
            }
        }
        &.level-1 {
            .bar_bg {
                padding-right: 40px;
            }
            .bar,
            .per {
                color: #37c593;
                border-color: #37c593;
            }
            .bar {
                background-color: #37c593;
            }
        }
    }
    .btn-box {
        width: 100%;
        padding: 0 30px;
        position: absolute;
        z-index: 1;
        bottom: 25px;
        font-size: 0;

        .btn-link {
            display: inline-block;
            width: 100%;
            padding: 0 5px;
            height: 44px;
            border: solid 1px #ddd;
            background-color: #ffffff;
            font-size: 13px;
            letter-spacing: -1.1px;
            color: #333;
            line-height: 44px;
            text-align: center;
            white-space: nowrap;

            &:after {
                display: inline-block;
                width: 8px;
                height: 8px;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                vertical-align: middle;
                margin: -2px 0 0 6px;
                content: "";
            }
        }
        .btn-link:nth-child(1):nth-last-child(2),
        .btn-link:nth-child(1):nth-last-child(2) ~ a {
            width: calc(50% - 3px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:after {
                display: none;
            }
        }
        .btn-link:nth-child(1):nth-last-child(2) {
            float: left;
        }
        .btn-link:nth-child(1):nth-last-child(2) ~ a {
            float: right;
        }
    }
    .tip-wrap {
        display: none;
        margin: 0 auto;
        @include clearfix();

        &.open {
            display: block;
        }
    }
    .list {
        display: table;
        width: 100%;
        margin-top: 10px;
        @include clearfix();

        dt {
            float: left;
            display: table-cell;
            width: 70%;
            color: #666;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: -1px;

            &:nth-child(1) {
                color: #333;
                font-size: 14px;
                font-weight: bold;
            }
        }
        dd {
            float: right;
            display: table-cell;
            width: 30%;
            color: #666;
            font-size: 13px;
            font-weight: bold;
            line-height: 22px;
            letter-spacing: -1px;
            text-align: right;

            &:nth-child(2) {
                color: #333;
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .info {
        margin-top: 4px;
        border-top: 1px solid #eee;
        font-size: 0;

        &:before {
            display: inline-block;
            width: 3px;
            height: 3px;
            margin-right: 4px;
            background-color: #999;
            border-radius: 1.5px;
            vertical-align: middle;
            content: "";
        }
        .title {
            display: inline-block;
            margin-right: 9px;
            color: #999;
            font-size: 12px;
            line-height: 30px;
            letter-spacing: -1.1px;
            vertical-align: middle;
        }
        .date {
            display: inline-block;
            color: #999;
            font-size: 12px;
            font-family: $font-family-number;
            line-height: 30px;
            letter-spacing: 0px;
            vertical-align: middle;
        }
    }
}

.main-selling-coach {
    position: relative;
    z-index: 1;
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 25px;

    .main-box-tab {
        position: absolute;
        top: 20px;
        right: 30px;
    }

    .btn-banner {
        display: block;
        height: 72px;
        border: 1px solid #eee;
        background-color: #fafafa;
        text-align: center;
        padding: 18px 0;
        font-family: "Noto Sans KR";

        strong {
            display: inline-block;
            font-size: 14px;
            color: #333;
            font-weight: 700;
            em {
                color: #0b83e6;
                font-weight: 700;
            }
            &:before {
                display: inline-block;
                content: "";
                @include sprite-retina($sp_main_2x_ico_coupon);
                margin-right: 19px;
                vertical-align: middle;
            }
        }
        span {
            display: inline-block;
            font-size: 14px;
            color: #999;
            margin-left: 10px;

            &:after {
                display: inline-block;
                width: 8px;
                height: 8px;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                vertical-align: middle;
                margin: -2px 0 0 3px;
                content: "";
            }
        }

        &--ai {
            padding: 8px 0;
            height: 52px;
            strong {
                &:before {
                    @include sprite-retina($sp_main_2x_ico_ai);
                    margin-right: 4px;
                }
            }
        }
    }

    .selling-coach-list {
        height: 273px;
        margin: 28px 30px 25px;

        .nav-wrap {
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: 90px;
            text-align: center;

            .nav {
                font-size: 0;
                vertical-align: middle;
                display: inline-block;

                li {
                    display: inline-block;

                    &.active {
                        button,
                        a {
                            span {
                                background-color: #0b83e6;
                            }
                        }
                    }
                }
                button,
                a {
                    height: 22px;
                    padding: 2px;

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        background-color: #c4c8d4;
                        border-radius: 4px;
                        overflow: hidden;
                        line-height: 200px;
                    }
                }
            }
            .btn_autoplay {
                display: inline-block;
                overflow: hidden;
                position: relative;
                right: 0;
                bottom: 1px;
                width: 21px;
                height: 22px;
                line-height: 200px;
                z-index: 10;

                &:before {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: calc(50% + 1px);
                    left: 50%;
                    transform: translate(-50%, -50%);
                    transform-origin: 0 0;
                    width: 0;
                    height: 0;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-left: 8px solid #333;
                    border-radius: 1px;
                    content: "";
                }
                &.stop {
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        z-index: 1;
                        top: calc(50% + 1px);
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transform-origin: 0 0;
                        width: 7px;
                        height: 8px;
                        border-right: 2px solid #333;
                        border-left: 2px solid #333;
                        content: "";
                    }
                }
            }
        }

        .tip-wrap {
            display: none;
            margin: 0 auto;
            @include clearfix();

            &.open {
                display: block;
            }
            .noti {
                padding: 15px 20px;
                border: 1px solid #f3ecce;
                background: #fffbe9;
                color: #333;
                font-weight: bold;
                font-size: 14px;
                line-height: 1.57;
                letter-spacing: -1px;

                .vc {
                    max-height: 42px;
                    overflow: hidden;
                    @include text-ellipsis-multiple(2);
                    top: auto;
                    -webkit-transform: none;
                    -o-transform: none;
                    transform: none;
                }
                strong {
                    display: block;
                    margin-bottom: 4px;
                    color: #000;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: -0.4px;
                    font-weight: bold;
                }
            }
            .explain {
                height: 108px;
                margin: 16px auto 0;
                font-size: 14px;
                color: #666;
                line-height: 28px;
                overflow: hidden;
                @include text-ellipsis-multiple(3);
            }
            .btn-box {
                z-index: 1;
                font-size: 0;
                position: absolute;
                bottom: 30px;
                left: 30px;
                right: 30px;

                .btn-link {
                    display: inline-block;
                    width: 100%;
                    padding: 0 5px;
                    height: 44px;
                    border: solid 1px #ddd;
                    background-color: #ffffff;
                    font-size: 13px;
                    letter-spacing: -1.1px;
                    color: #333;
                    line-height: 44px;
                    text-align: center;
                    white-space: nowrap;
                }

                .btn-link:nth-child(1):nth-last-child(2),
                .btn-link:nth-child(1):nth-last-child(2) ~ a {
                    width: calc(50% - 3px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:after {
                        display: none;
                    }
                }
                .btn-link:nth-child(1):nth-last-child(2) {
                    float: left;
                }
                .btn-link:nth-child(1):nth-last-child(2) ~ a {
                    float: right;
                }
            }
        }
        .list {
            display: table;
            width: 100%;
            margin-top: 10px;
            @include clearfix();

            dt {
                float: left;
                display: table-cell;
                width: 70%;
                color: #666;
                font-size: 13px;
                line-height: 22px;
                letter-spacing: -1px;

                &:nth-child(1) {
                    color: #333;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
            dd {
                float: right;
                display: table-cell;
                width: 30%;
                color: #666;
                font-size: 13px;
                font-weight: bold;
                line-height: 22px;
                letter-spacing: -1px;
                text-align: right;

                &:nth-child(2) {
                    color: #333;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
        .info {
            margin-top: 4px;
            border-top: 1px solid #eee;
            font-size: 0;

            &:before {
                display: inline-block;
                width: 3px;
                height: 3px;
                margin-right: 4px;
                background-color: #999;
                border-radius: 1.5px;
                vertical-align: middle;
                content: "";
            }
            .title {
                display: inline-block;
                margin-right: 9px;
                color: #999;
                font-size: 12px;
                line-height: 30px;
                letter-spacing: -1.1px;
                vertical-align: middle;
            }
            .date {
                display: inline-block;
                color: #999;
                font-size: 12px;
                font-family: $font-family-number;
                line-height: 30px;
                letter-spacing: 0px;
                vertical-align: middle;
            }
        }
        .list-service {
            overflow: hidden;

            li {
                height: 80px;
                border-bottom: 1px solid #f4f4f4;
                position: relative;
                padding: 28px 0;

                strong {
                    display: inline-block;
                    font-family: "Noto Sans KR";
                    font-size: 15px;
                    color: #111;
                    line-height: 24px;
                    vertical-align: middle;
                }
                span.tag {
                    display: inline-block;
                    width: 75px;
                    height: 24px;
                    padding: 0 7px;
                    line-height: 22px;
                    border-radius: 12px;
                    border: solid 1px rgba(11, 131, 230, 0.5);
                    text-align: center;
                    font-size: 11px;
                    color: #0b83e6;
                    letter-spacing: -1px;
                    vertical-align: middle;
                    margin-right: 5px;
                }
                span.desc {
                    display: none;
                    line-height: 24px;
                    font-size: 12px;
                    color: #999;
                    margin-left: 8px;
                    vertical-align: middle;
                }

                @media (max-width: 1530px) {
                    span.desc {
                        display: inline-block;
                    }
                }

                .right {
                    position: absolute;
                    top: 20px;
                    right: 0;

                    .price {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        margin-right: 18px;
                        font-size: 18px;
                        color: #111;
                        font-family: "Noto Sans KR";

                        strong {
                            font-size: 18px;
                            color: #0b83e6;
                            font-family: "Roboto";
                            font-weight: 700;
                            display: inline-block;
                            margin-right: 2px;
                        }
                    }
                    a {
                        display: inline-block;
                        width: 124px;
                        height: 40px;
                        line-height: 36px;
                        text-align: center;
                        border: 1px solid #ddd;
                        font-size: 14px;
                        color: #111;

                        span:after {
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            border: 1px solid #999;
                            border-width: 1px 1px 0 0;
                            transform: rotate(45deg);
                            vertical-align: middle;
                            margin: -2px 0 0 3px;
                            content: "";
                        }
                    }
                }
            }
        }

        .list-ai {
            li {
                display: table;
                padding: 20px 0;
                width: 100%;
                height: 84px;
                border-bottom: 1px solid #f4f4f4;
            }

            &__tag {
                display: block;
                width: 36px;
                height: 24px;
                padding: 0 6px;
                line-height: 22px;
                border-radius: 12px;
                border: solid 1px rgba(11, 131, 230, 0.5);
                text-align: center;
                font-size: 11px;
                color: $color-11st-blue;
                letter-spacing: -1px;
                vertical-align: middle;
            }

            &__title {
                display: block;
                margin-right: 8px;
                font-family: "Noto Sans KR";
                font-size: 15px;
                color: $color-gray-02;
                line-height: 24px;
                vertical-align: middle;

                em {
                    color: $color-11st-blue;
                    font-weight: 700;
                }
            }

            &__desc {
                display: block;
                line-height: 18px;
                font-size: 12px;
                color: $color-gray-06;
                vertical-align: middle;

                em {
                    color: $color-11st-blue;
                }
            }

            &__button {
                display: inline-block;
                width: 133px;
                height: 40px;
                line-height: 36px;
                text-align: center;
                border: 1px solid #ddd;
                font-size: 14px;
                color: $color-gray-02;

                span:after {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border: 1px solid #999;
                    border-width: 1px 1px 0 0;
                    transform: rotate(45deg);
                    vertical-align: middle;
                    margin: -2px 0 0 3px;
                    content: "";
                }

                &--disable {
                    position: relative;
                    padding-top: 17px;
                    border-color: $color-gray-12;
                    background-color: #fefefe;
                    line-height: 1.5;
                    color: $color-gray-09;

                    .sub-text {
                        position: absolute;
                        top: 4px;
                        left: 0;
                        display: block;
                        width: 100%;
                        color: $color-gray-02;
                        font-size: 10px;
                        line-height: 15px;
                    }

                    span:after {
                        display: none;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &__item {
                display: table-cell;
                width: 100%;
                vertical-align: top;

                &:nth-of-type(1) {
                    width: 44px;
                }

                &:nth-of-type(2) {
                    width: calc(100% - 185px);
                }

                &:nth-of-type(3) {
                    width: 145px;
                    text-align: right;
                }
            }
        }
    }
}

.main-stats {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 30px;
    position: relative;

    .main-box-tab {
        position: absolute;
        top: 20px;
        right: 30px;
    }

    .chart-list {
        margin: 30px;
        height: 260px;
    }

    .list {
        margin-top: 20px;

        li {
            width: calc(100% - 60px);
            margin: 0 auto;
            padding: 15px 0;
            border-bottom: 1px solid #f5f5f5;
            @include clearfix();

            a {
                display: block;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .title {
            position: relative;
            z-index: 1;
            float: left;
            width: 48%;
            height: 44px;
        }
        .text {
            display: block;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #333;
            letter-spacing: -1.1px;
        }
        .sub_text {
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: #999;

            a {
                display: inline-block;
                font-size: 14px;
                color: #999;
                font-weight: bold;
                margin-left: 5px;
                font-family: $font-family-number;
                letter-spacing: 0;
            }
            .count {
                font-weight: bold;
            }
        }
        li > .count {
            width: 52%;
            float: right;
            font-size: 20px;
            color: #111111;
            font-weight: bold;
            line-height: 30px;
            text-align: right;

            a {
                width: 100%;
                font-size: 26px;
                color: #111111;
                font-weight: bold;
                line-height: 31px;
                font-family: $font-family-number;
                letter-spacing: 0;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    text-decoration: underline;
                }
            }
            .up,
            .down {
                display: block;
                font-family: $font-family-number;
                font-size: 14px;
                color: $color-11st-red;
                line-height: 17px;
            }
            .down {
                color: #0b83e6;
            }
        }
    }

    .info {
        width: calc(100% - 60px);
        margin: 0 auto;
        padding-top: 20px;
        font-size: 12px;
        color: #999;
        line-height: 18px;
        border-top: 1px solid #eee;

        &:before {
            display: inline-block;
            width: 3px;
            height: 3px;
            background: #999;
            border-radius: 50%;
            content: "";
            line-height: 18px;
            vertical-align: middle;
            margin-right: 5px;
        }
        .date {
            font-family: $font-family-number;
            letter-spacing: 0;
            margin-left: 6px;
        }
    }
}

//공지사항
.main-notice {
    min-height: 380px;
    max-height: 380px;
    padding: 30px 0 0 0;

    .main-tab {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 25px 0 0;
        overflow: hidden;

        li {
            flex: 1;
        }
    }
    .list {
        width: calc(100% - 60px);
        margin: 31px auto 0;

        li {
            width: 100%;
            margin-top: 19px;
            @include clearfix();

            &.today {
                .text a {
                    font-weight: bold;
                    color: #333;
                }
                .date {
                    color: #333;
                }
            }
        }
        .subject {
            float: left;
            width: 90%;
        }
        .noti {
            width: 57px;
            height: 24px;
            display: inline-block;
            border-radius: 12px;
            text-align: center;
            line-height: 22px;
            background: #fff;
            font-size: 11px;
            vertical-align: middle;
            border: 1px solid rgba(244, 49, 66, 0.5);

            &.icon1 {
                border-color: rgba(102, 102, 102, 0.5);
                color: #333;
            }
            &.icon2 {
                border-color: rgba(355, 49, 66, 0.5);
                color: $color-11st-red;
            }
            &.icon3 {
                border-color: rgba(253, 57, 122, 0.5);
                color: #fd397a;
            }
            &.icon4 {
                border-color: rgba(100, 92, 161, 0.5);
                color: #645ca1;
            }
            &.icon5 {
                border-color: rgba(11, 131, 230, 0.5);
                color: #0b83e6;
            }
        }
        .adv-noti {
            width: 57px;
            height: 24px;
            display: inline-block;
            border-radius: 12px;
            text-align: center;
            line-height: 22px;
            background: #fff;
            font-size: 11px;
            vertical-align: middle;
            border: 1px solid rgba(244, 49, 66, 0.5);

            &.icon1 {
                border-color: rgba(245, 109, 8, 0.5);
                color: #f56d08;
            }
            &.icon2 {
                border-color: rgba(253, 67, 122, 0.5);
                color: #fd397a;
            }
            &.icon3 {
                border-color: rgba(41, 179, 73, 0.5);
                color: #14af3d;
            }
            &.icon4 {
                border-color: rgba(11, 85, 230, 0.5);
                color: #0b55e6;
            }
            &.icon5 {
                border-color: rgba(17, 178, 213, 0.5);
                color: #11b2d5;
            }
            &.icon6 {
                border-color: rgba(102, 102, 102, 0.5);
                color: #666;
            }
        }
        .new {
            margin-left: 4px;
            font-family: $font-family-number;
            font-size: 11px;
            font-weight: bold;
            color: #0b83e6;
        }
        .text {
            display: inline-block;
            max-width: 75%;
            height: 24px;
            margin-left: 9px;
            font-size: 14px;
            color: #666;
            overflow: hidden;
            vertical-align: middle;
            line-height: 24px;
            @include text-ellipsis();

            a {
                color: #666;
            }
        }
        .date {
            float: right;
            width: 10%;
            text-align: right;
            font-size: 13px;
            color: #999;
            line-height: 24px;
            font-family: $font-family-number;
            letter-spacing: 0;
            vertical-align: middle;
        }
    }
}

//셀러존 교육
.main-edu {
    min-height: 410px;
    max-height: 410px;
    padding: 30px 0 0 0;

    .main-tab {
        margin-top: 25px;

        li {
            width: 33%;
            &.active {
                width: 34%;
            }
        }

        .on_air {
            display: inline-block;
            min-width: 32px;
            height: 20px;
            margin: -2px 3px 0 0;
            padding: 0 6px;
            border-radius: 16px;
            border: solid 1px rgba(244, 49, 66, 0.2);
            background-color: $color-11st-red;
            color: #fff;
            font-family: $font-family-number;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0;
            vertical-align: middle;
        }
    }

    .main-edu__board {
        position: relative;
        outline: none;

        .live {
            position: relative;
            width: calc(100% - 60px);
            height: 72px;
            padding: 0 20px;
            margin: 20px auto 0;
            background-color: rgba(244, 49, 66, 0.1);
            overflow: hidden;

            .vc {
                max-height: 42px;
                overflow: hidden;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: initial;
                text-align: left;
            }

            .live_date {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 20px;
                padding: 0 1px;
                color: #666;
                font-size: 12px;
                background-color: #feeaec;
                line-height: 20px;
                box-sizing: border-box;

                &::before {
                    position: absolute;
                    top: 0;
                    left: -30px;
                    background-image: url("/img/selleroffice/etc/shadow_red.png");
                    width: 30px;
                    height: 20px;
                    content: "";
                }
            }

            strong {
                font-size: 14px;
                font-weight: bold;
                color: $color-11st-red;
            }

            a {
                font-size: 14px;
                font-weight: bold;
                color: #333;
            }
        }

        .list {
            width: calc(100% - 60px);
            height: 72px;
            margin: 20px auto 0;
            position: relative;
            z-index: 1;
            border: 1px solid #e5f0f9;
            background-color: #eef6fd;

            li {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 25px;
                display: none;
                width: calc(100% - 52px);
                height: 72px;
                line-height: 20px;

                .vc {
                    max-height: 42px;
                    overflow: hidden;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: initial;
                    text-align: left;
                }

                &.active {
                    display: block;
                }
            }

            .live_date {
                position: absolute;
                bottom: 0;
                right: 0;
                height: 20px;
                padding: 0 1px;
                color: #666;
                font-size: 12px;
                background-color: #eef6fd;
                line-height: 20px;
                box-sizing: border-box;

                &::before {
                    position: absolute;
                    top: 0;
                    left: -30px;
                    background-image: url("/img/selleroffice/etc/shadow_blue.png");
                    width: 30px;
                    height: 20px;
                    content: "";
                }
            }

            strong {
                font-size: 14px;
                font-weight: bold;
                color: #0b83e6;
            }

            a {
                font-size: 14px;
                font-weight: bold;
                color: #333;
            }

            .btn-link {
                position: relative;
                margin-left: 18px;
                padding-right: 14px;
                font-size: 14px;
                color: #999;
                line-height: 52px;
                letter-spacing: -1.2px;

                &::after {
                    display: inline-block;
                    @include ico-angle(right, 8px, #999, 1px, static);
                    margin: -2px 0 0 2px;
                }
            }
        }

        .btn_arrow {
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateZ(0);
        }

        .prev {
            width: 25px;
            height: 72px;
            top: 0;
            left: 0;

            &:before {
                display: block;
                @include ico-angle(left, 8px, #666, 1px, absolute);
                left: 25%;
            }
        }

        .next {
            width: 22px;
            height: 72px;
            top: 0;
            right: 0;

            &:after {
                display: block;
                @include ico-angle(right, 8px, #666, 1px, absolute);
                right: 25%;
            }
        }
    }

    .main-edu__apply {
        position: relative;
        z-index: 1;
        width: calc(100% - 60px);
        margin: 22px auto 0;
        @include clearfix();

        .top {
            .title {
                @extend %main_sub_title;
            }

            .link {
                position: absolute;
                top: 0;
                right: 0;
                color: #333;
                font-size: 13px;
                letter-spacing: -1px;

                .btn-link {
                    @extend %main_btn_link;
                }
            }
        }

        .list {
            width: 100%;
            margin-top: 10px;
            @include clearfix();

            li {
                width: 100%;
                @include clearfix();
            }

            a {
                width: 100%;
                display: inline-block;
                color: #666;

                &:hover {
                    font-weight: bold;
                    text-decoration: none;
                }
            }

            .text {
                float: left;
                width: 75%;
                height: 22px;
                color: #666;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                overflow: hidden;
                @include text-ellipsis();
            }

            .date {
                float: right;
                width: 25%;
                text-align: right;
                font-family: $font-family-number;
                color: #999;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }

        .info {
            position: relative;
            z-index: 1;
            width: 100%;
            min-height: 106px;
            padding: 20px 0;
            margin-top: 10px;
            background-color: #f4f4f4;
            color: #333;
            font-size: 13px;
            text-align: center;
            letter-spacing: -1.2px;
            line-height: 22px;

            .link {
                color: #0b83e6;
                font-size: 13px;
                line-height: 19px;

                &:after {
                    display: inline-block;
                    @include ico-angle(right, 6px, $color-secondary, 1px, static);
                    margin: -2px 0 0 2px;
                }
            }
        }
    }

    .edu-list {
        width: calc(100% - 60px);
        margin: 25px auto;
        position: relative;
        z-index: 1;
        font-size: 0;

        .banner {
            width: calc(100% - 20px);
            max-height: 52px;
            margin: 0 auto;
            display: block;
            text-align: center;
            overflow: hidden;
        }

        .list {
            width: calc(100% - 40px);
            height: 52px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            text-align: center;

            &.live {
                width: 100%;
                padding: 0 20px;
                background-color: rgba(244, 49, 66, 0.1);
                line-height: 52px;
            }

            .state1 {
                display: inline-block;
                margin-right: 4px;
                color: #0b83e6;
                font-size: 14px;
                font-weight: bold;
                vertical-align: middle;
            }

            .state2 {
                display: inline-block;
                margin-right: 4px;
                color: $color-11st-red;
                font-size: 14px;
                font-weight: bold;
                vertical-align: middle;
            }

            a {
                position: relative;
                max-width: 75%;
                display: inline-block;
                line-height: 52px;
                height: 52px;
                vertical-align: middle;
                text-align: left;
            }

            .text {
                display: inline-block;
                max-width: 75%;
                padding: 0 3px;
                color: #333;
                font-weight: bold;
                font-size: 14px;
                vertical-align: middle;
                @include text-ellipsis();

                &:last-child {
                    max-width: 100%;
                }
            }

            .date {
                display: inline-block;
                font-size: 14px;
                color: #666;
                margin-left: 5px;
                vertical-align: middle;
            }

            .btn-link {
                position: relative;
                margin-left: 18px;
                padding-right: 14px;
                font-size: 14px;
                color: #999;
                line-height: 52px;
                letter-spacing: -1.2px;

                &::after {
                    display: inline-block;
                    @include ico-angle(right, 8px, #999, 1px, static);
                    margin: -2px 0 0 2px;
                }
            }

            li {
                width: 100%;
                height: 52px;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                display: none;
                text-align: center;
                line-height: 52px;
                border: 1px solid #e5f0f9;
                background-color: rgba(11, 131, 230, 0.1);

                &.active {
                    display: block;
                }
            }
        }

        .btn_arrow {
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateZ(0);
        }

        .prev {
            width: 22px;
            height: 52px;
            top: 0;
            left: 0;

            &:before {
                display: block;
                @include ico-angle(left, 6px, #666, 1px, absolute);
                left: 25%;
            }
        }

        .next {
            width: 22px;
            height: 52px;
            top: 0;
            right: 0;

            &:after {
                display: block;
                @include ico-angle(right, 6px, #666, 1px, absolute);
                right: 25%;
            }
        }
    }

    .edu-apply {
        position: relative;
        z-index: 1;
        width: calc(100% - 60px);
        margin: 28px auto 0;
        @include clearfix();

        .main-subtit__right {
            position: absolute;
            top: 0;
            right: 0;
            color: #333;
            font-size: 13px;
            letter-spacing: -1px;
        }

        .list {
            width: 100%;
            margin-top: 13px;
            @include clearfix();

            li {
                margin-top: 7px;
                width: 100%;
                @include clearfix();
            }

            a {
                width: 100%;
                display: inline-block;
                color: #666;

                &:hover {
                    font-weight: bold;
                    text-decoration: none;
                }
            }

            .text {
                float: left;
                width: 70%;
                height: 22px;
                color: #666;
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
                overflow: hidden;
                @include text-ellipsis();
            }

            .date {
                float: right;
                width: 30%;
                text-align: right;
                font-family: $font-family-number;
                color: #999;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 20px;
            }
        }
    }
}

// 상품
.main-prd {
    position: relative;
    z-index: 2 !important;
    height: 100%;
    min-height: 410px;
    max-height: 410px;
    padding: 30px 0 30px;
    @include clearfix();

    .inquiry-area {
        width: calc(100% - 60px);
        margin: 20px auto 0;
    }
    .list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 218px;
        @include clearfix();

        li {
            @include clearfix();

            &:not(:first-child) {
                margin-top: 6px;
            }

            .main-info__1:first-child {
                height: 124px;
            }
        }
        .title {
            @include clearfix();
            .text {
                float: left;
                width: 60%;
                font-size: 15px;
                color: #333;
                font-weight: bold;
                line-height: 22px;
            }
            .count {
                float: right;
                width: 40%;
                padding-right: 2px;
                text-align: right;
                font-size: 15px;
                font-family: $font-family-number;
                font-weight: bold;
                line-height: 22px;
                letter-spacing: 0;

                a {
                    color: #333;
                }
            }
        }
    }
    .sub_list {
        width: 100%;
        margin-top: 4px;
        @include clearfix();

        li {
            margin-top: 5px;
            @include clearfix();

            & > .title {
                float: left;
                width: 50%;
                color: #666;
                font-size: 14px;
                line-height: 20px;
            }
            & > .count {
                float: right;
                width: 50%;
                padding-right: 2px;
                text-align: right;
                color: #666;
                font-family: $font-family-number;
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
    .search {
        display: inline-block;
        width: calc(100% - 60px);
        height: 44px;
        margin: 0 30px;
        position: absolute;
        z-index: 1;
        bottom: 33px;
        border: 1px solid #ddd;
        border-radius: 2px;
        font-size: 0;
        line-height: 0;

        &.open {
            .list {
                display: block;
            }
            .btn {
                &:after {
                    margin-top: -4px;
                    transform: rotate(180deg);
                }
            }
        }
        .btn {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 120px;
            padding-left: 12px;
            height: 42px;
            background-color: #fff;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.88px;
            color: #111;

            &:after {
                display: block;
                position: absolute;
                z-index: 1;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                right: 6px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid #333;
                content: "";
            }
            .text {
                display: block;
                width: 96px;
                height: 42px;
                line-height: 42px;
                text-align: left;
                letter-spacing: 0;
            }
        }
        .list {
            display: none;
            position: absolute;
            z-index: 1;
            top: 42px;
            left: -1px;
            width: 120px;
            padding: 0;
            background-color: #fff;
            border: 1px solid #ddd;
            line-height: 32px;
            //box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 4px 0 rgba(0, 0, 0, 0.15);

            li {
                margin-top: 0;
            }
            .link {
                width: 100%;
                height: 36px;
                padding: 0 10px;
                line-height: 36px;
                text-align: left;
                font-size: 14px;
                color: #333;

                &:hover {
                    background-color: #fafafa;
                    color: #111;
                }
                &.active {
                    color: $color-11st-red;
                }
            }
        }
        .inp {
            width: calc(100% - 155px);
            height: 42px;
            line-height: 42px;
            border: none;
            vertical-align: top;
        }
        .btn-search {
            position: absolute;
            top: 4px;
            right: 10px;
            margin: 7px 0;
            overflow: hidden;
            line-height: 200px;
            @include sprite($sp_icon_search);
            vertical-align: top;
        }
    }
}

//광고
.main-adver {
    position: relative;
    min-height: 410px;
    max-height: 410px;
    padding: 32px 0 30px;

    .inquiry-area {
        width: 100%;
        min-height: 230px;
        padding: 20px 30px 0;

        .main-spinner {
            width: calc(100% - 60px);
        }
    }
    .btn-link {
        position: absolute;
        bottom: 33px;
        left: 30px;
        width: calc(100% - 60px);
        height: 44px;
        margin-top: 7px;
        border-radius: 2px;
        border: solid 1px #ddd;
        background-color: #ffffff;
        font-size: 14px;
        color: #333;
        line-height: 44px;
        text-align: center;

        &:after {
            display: inline-block;
            width: 8px;
            height: 8px;
            border: 1px solid #999;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            vertical-align: middle;
            margin: -2px 0 0 6px;
            content: "";
        }
    }
    .cont-list {
        width: 100%;

        .list {
            width: 100%;

            li {
                @include clearfix();
                margin-top: 5px;

                & > .title {
                    float: left;
                    width: 60%;
                    color: #666;
                    font-size: 14px;
                }
                & > .count {
                    float: right;
                    width: 40%;
                    text-align: right;
                    font-size: 14px;
                    color: #333;
                    font-family: $font-family-number;
                    letter-spacing: 0;
                    font-weight: bold;
                }
                a {
                    color: #333;
                }
            }
        }
    }
    .cont-focus {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 3px;
        margin: 21px auto 0;
        border-top: 1px solid #f4f4f4;

        .main-subtit {
            margin-bottom: 8px;
        }
        .list {
            width: 100%;

            > li {
                @include clearfix();
                margin-top: 5px;

                & > .title {
                    float: left;
                    width: 46%;
                    color: #666;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: -1.1px;
                    line-height: 22px;
                }
                & > .count {
                    float: right;
                    width: 34%;
                    text-align: right;
                    font-size: 14px;
                    color: #666;
                    letter-spacing: 0;

                    a {
                        font-family: $font-family-number;
                        font-weight: bold;
                        color: #333;
                    }
                }
                &:nth-last-child(1) {
                    & > .title {
                        width: calc(100% - 170px);
                    }
                }
            }
        }
        .sub_list {
            float: right;
            font-size: 0;

            li {
                display: inline-block;
                padding: 0;
                font-size: 0;
                @include clearfix();

                &:not(:first-child) {
                    &:before {
                        display: inline-block;
                        height: 14px;
                        margin: 0 8px;
                        width: 1px;
                        background-color: #ccc;
                        vertical-align: middle;
                        content: "";
                    }
                }
                & > .title {
                    display: inline-block;
                    width: 39px;
                    color: #666;
                    font-size: 14px;
                    vertical-align: middle;
                }
                & > .count {
                    display: inline-block;
                    width: 35px;
                    text-align: right;
                    font-size: 14px;
                    color: #333;
                    font-family: $font-family-number;
                    letter-spacing: 0;
                    vertical-align: middle;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    a {
                        color: #333;
                    }
                }
            }
        }
    }
}

//빌보드
.main-billboard {
    position: relative;
    width: 100%;
    height: 290px;
    padding: 0 0 46px 0;
    background: #eee;

    .menu {
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        border-right: 1px solid #eee;
        z-index: 10;

        > li {
            display: inline-block;
            flex: 1;
            position: relative;
            overflow: hidden;
        }
        li:nth-last-child(1) {
            margin-right: 46px;
        }
        li:nth-child(1):nth-last-child(1) {
            margin-right: 0;
        }
    }
    .lk {
        display: block;
        width: 100%;
        height: 46px;
        padding: 0 5px;
        line-height: 46px;
        text-align: center;
        border-left: 1px solid #eee;
        font-size: 15px;
        letter-spacing: -0.7px;
        @include text-ellipsis();

        &:hover {
            text-decoration: none;
        }
    }
    .btn_list {
        overflow: hidden;
        position: absolute;
        top: 0px;
        width: 30px;
        height: 40px;
        line-height: 200px;

        &.btn_prev {
            left: 0px;
            background: url("https://dummyimage.com/30x38/eeeeee/444444&text=%3C") no-repeat;
        }

        &.btn_next {
            right: 1px;
            background: url("https://dummyimage.com/30x38/eeeeee/444444&text=%3E") no-repeat;
        }
    }
    .active {
        .lk {
            background: #0b83e6;
            color: #fff;

            &:before {
                display: block;
                position: absolute;
                z-index: 1;
                top: -6px;
                left: 50%;
                transform: rotate(180deg);
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 6px solid #0b83e6;
                content: "";
            }
        }
    }
    .visual {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 46px;
        font-size: 0;
        text-align: center;
        display: none;

        &.active {
            display: block;
        }
        a {
            display: block;
            width: 100%;
            height: 244px;
            overflow: hidden;

            img {
                width: 910px;
                height: 245px;
            }
        }
    }
    .btn_paging {
        display: none;
        overflow: hidden;
        position: absolute;
        top: 50%;
        width: 55px;
        height: 55px;
        margin: -50px 0 0 0;
        color: #fff;
        line-height: 200px;

        &.btn_prev {
            background-color: rgba(17, 17, 17, 0.16);

            &:before {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                width: 16px;
                height: 16px;
                border: 2px solid rgba(255, 255, 255, 1);
                border-width: 2px 2px 0 0;
                transform: rotate(-135deg) translateX(-65%) translateY(-35%);
                transform-origin: 0 0;
                content: "";
            }
        }
        &.btn_next {
            right: 0;
            background-color: rgba(17, 17, 17, 0.16);

            &:before {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                width: 16px;
                height: 16px;
                border: 2px solid rgba(238, 238, 238, 1);
                border-width: 2px 2px 0 0;
                transform: rotate(45deg) translateX(-65%) translateY(-35%);
                transform-origin: 0 0;
                content: "";
            }
        }
    }
    .btn_autoplay {
        overflow: hidden;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 46px;
        height: 46px;
        line-height: 200px;
        z-index: 10;
        border-left: 1px solid #eee;

        &:before {
            display: block;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transform-origin: 0 0;
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 13px solid #333;
            border-radius: 4px;
            content: "";
        }
        &.stop {
            &:before {
                display: none;
            }
            &:after {
                display: block;
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transform-origin: 0 0;
                width: 12px;
                height: 16px;
                border-right: 4px solid #333;
                border-left: 4px solid #333;
                content: "";
            }
        }
    }
    &:hover {
        .btn_paging {
            display: block;
            &.btn_prev,
            &.btn_next {
                &:hover {
                    background-color: rgba(17, 17, 17, 0.3);
                }
            }
        }
    }
}

//배너
.main-banner {
    position: relative;
    z-index: 1;
    height: 120px;

    .navi {
        position: absolute;
        z-index: 2;
        top: 10px;
        right: 10px;

        .num {
            float: left;
            margin-right: 8px;
            font-family: $font-family-number;
            font-size: 14px;
            line-height: 22px;
            color: #999;

            strong {
                color: #333;
                font-weight: bold;
            }
            .skip {
                position: absolute;
                overflow: hidden;
                clip: rect(0 0 0 0);
                margin: -1px;
                width: 1px;
                height: 1px;
            }
        }
        .btn_arrow {
            float: left;
            width: 22px;
            height: 22px;
            font-size: 0;
            line-height: 0;
            color: transparent;
            border: 1px solid #ddd;
            background: #fff;

            &:before {
                display: inline-block;
                content: "";
                vertical-align: middle;
            }
        }
        .prev {
            &:before {
                @include ico-angle(left, 6px, #999, 1px, static);
                margin-right: -3px;
            }
        }
        .next {
            border-left: none;
            &:before {
                @include ico-angle(right, 6px, #999, 1px, static);
                margin-left: -3px;
            }
        }
    }
    .list {
        position: relative;
        z-index: 1;

        li {
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 120px;
            overflow: hidden;
            border-radius: 2px;
            box-shadow: 0 2px 4px 0 rgba(29, 29, 29, 0.04), 0 0 1px 0 rgba(0, 0, 0, 0.15);
        }
        .active {
            display: block;
            z-index: 2;
        }
        a {
            display: block;
            text-align: center;

            img {
                width: 595px;
                height: 120px;
            }
        }
    }
}

.main-pop {
    position: absolute;
    top: 244px;
    left: 255px;
    z-index: 100;
    width: 600px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
    box-sizing: border-box;

    .pop-header {
        background-color: $color-11st-red;

        .pop-header__title {
            width: 500px;
            height: 58px;
            margin: 0 auto;
            display: block;
            font-size: 18px;
            color: #fff;
            line-height: 58px;
            letter-spacing: -0.56px;
            font-weight: 500;
            text-align: center;
            @include text-ellipsis();
        }
    }

    .pop-body {
        padding: 0;
        max-height: 635px;
        overflow: hidden;
        overflow-y: auto;
        background-color: #fff;

        iframe {
            vertical-align: top;
        }
    }

    .pop-bottom {
        position: relative;
        z-index: 1;
        border-top: 1px solid #eee;
        background-color: #fff;
        padding: 32px 40px;
        text-align: center;
        @include clearfix();

        button {
            margin: 0 5px;
        }
        .pop-bottom__left {
            float: left;
            text-align: left;
        }
        .pop-bottom__right {
            float: right;
            text-align: right;
        }
        &.style__2 {
            .check-style__2 {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 30px;
                margin-top: -20px;
            }
            .lab {
                color: #666;
                font-size: 13px;
                letter-spacing: -0.5px;
            }
        }
        &.style__3 {
            background-color: #f4f4f4;

            .lab {
                color: #333;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: -0.6px;
            }
        }
    }

    .pop-close {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px;
        line-height: 200px;
        overflow: hidden;
        background: transparent;
        @include ico-cancel(28px, 1px, #fff, 6px);
    }
}

.main-notice-pop {
    position: absolute;
    top: 140px;
    left: 255px;
    z-index: 100;
    width: 600px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
    box-sizing: border-box;

    .pop-header {
        background-color: $color-11st-red;

        .pop-header__title {
            width: 500px;
            height: 58px;
            margin: 0 auto;
            display: block;
            font-size: 18px;
            color: #fff;
            line-height: 58px;
            letter-spacing: -0.56px;
            font-weight: 500;
            text-align: center;
            @include text-ellipsis();
        }
    }

    .pop-body {
        padding: 0;
        max-height: 530px;
        overflow: hidden;
        overflow-y: auto;
        background-color: #fff;

        iframe {
            vertical-align: top;
        }
    }

    .pop-bottom {
        position: relative;
        z-index: 1;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        background-color: #fafafa;
        min-height: 62px;
        padding: 15px 30px;
        text-align: center;
        box-sizing: border-box;
        @include clearfix();

        button {
            margin: 0 5px;
        }
        .pop-bottom__left {
            float: left;
            text-align: left;
        }
        .pop-bottom__right {
            float: right;
            text-align: right;
        }
        &.style__2 {
            .check-style__2 {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 20px;
                margin-top: -20px;
            }
            .lab {
                color: #666;
                font-size: 14px;
                letter-spacing: -0.5px;
            }

            .controls {
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
            }
        }
        &.style__3 {
            background-color: #f4f4f4;

            .lab {
                color: #333;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: -0.6px;
            }
        }
    }

    .pop-close {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px;
        line-height: 200px;
        overflow: hidden;
        background: transparent;
        @include ico-cancel(28px, 1px, #fff, 6px);
    }

    .controls {
        position: relative;
        display: inline-block;

        .controls-inner {
            position: relative;
            display: inline-block;
        }

        .controls-page {
            display: inline-block;
            padding: 0 38px;
            font-size: 15px;
            color: #666;
            vertical-align: middle;
            line-height: 28px;

            strong {
                color: #111;

                &:after {
                    content: "/";
                    display: inline-block;
                    margin: 0 5px;
                    color: #ccc;
                }
            }
        }

        .controls-navigator {
            button {
                position: relative;
                width: 28px;
                height: 28px;
                margin: 0;
                border-radius: 50%;
                box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4);
                background-color: #fff;
                line-height: 200px;
                overflow: hidden;

                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -4px 0 0 -5px;
                    width: 8px;
                    height: 8px;
                    border: 2px solid #111;
                    vertical-align: middle;
                    transform-origin: center center;
                    transform: rotate(-135deg);
                }

                &:first-child {
                    position: absolute;
                    top: 0;
                    left: 0;

                    &:after {
                        margin-left: -3px;
                        border-width: 2px 2px 0 0;
                    }
                }

                &:last-child {
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:after {
                        margin-left: -5px;
                        border-width: 0 0 2px 2px;
                    }
                }

                &:disabled {
                    &:after {
                        border-color: #ccc;
                    }
                }
            }
        }

        .controls-btns {
            display: inline-block;
            margin-left: 10px;

            button {
                position: relative;
                width: 28px;
                height: 28px;
                margin: 0;
                border-radius: 50%;
                box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.4);
                background-color: #fff;
                line-height: 200px;
                overflow: hidden;

                &.pause {
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 2px;
                        height: 10px;
                        background: #000;
                    }
                    &:before {
                        margin: -5px 0 0 -3px;
                    }

                    &:after {
                        margin: -5px 0 0 2px;
                    }
                }

                &.play {
                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 0;
                        height: 0;
                        margin: -5px 0 0 -2px;
                        border: 5.5px solid transparent;
                        border-right: 0 solid transparent;
                        border-left: 7px solid #000;
                        vertical-align: middle;
                        transform-origin: center center;
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }
}

.main-lypop {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    background-color: #fff;
    border: 1px solid #ddd;
    transform: translate(-50%, -50%);

    .check-style__2 {
        position: absolute;
        z-index: 1;
        bottom: 16px;
        left: 30px;

        .lab {
            color: #666;
            font-size: 13px;
        }
    }
    .pop-close {
        position: absolute;
        z-index: 1;
        top: 40px;
        right: 40px;
        @include ico-cancel(24px, 1px, #9b9b9b, 3px);
        transform: translateZ(0);
    }

    &.main-lypop__main {
        width: 800px;
        height: 600px;

        .swiper-container {
            overflow: hidden;
            position: relative;
            width: 798px;
            height: 568px;
            background: #fff;
            text-align: center;
        }
        .list {
            width: 20000px;
            height: 100%;
            margin: auto;

            li {
                position: relative;
                height: 100%;
                float: left;
            }
        }
        .info {
            width: 800px;
            height: 600px;
            background-size: 800px 600px;
            overflow: hidden;
            line-height: 1000px;

            &.info1 {
                background-image: url("/img/selleroffice/renewal_main/main_info.png");
            }
            &.info2 {
                background-image: url("/img/selleroffice/renewal_main/main_info2.png");
            }
            &.info3 {
                background-image: url("/img/selleroffice/renewal_main/main_info3.png");
            }
            &.info4 {
                background-image: url("/img/selleroffice/renewal_main/main_info4.png");
            }
            &.info5 {
                background-image: url("/img/selleroffice/renewal_main/main_info5.png");
            }
            &.info6 {
                background-image: url("/img/selleroffice/renewal_main/main_info6.png");
            }
        }
        .btn_arrow {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateY(-48%);
        }
        .prev {
            left: 16px;

            &:after {
                @include ico-angle(left, 20px, #333, 1px, absolute);
            }
        }
        .next {
            right: 16px;

            &:after {
                @include ico-angle(right, 20px, #333, 1px, absolute);
            }
        }
        .swiper-button-disabled {
            opacity: 0.2;
        }
        .pagination {
            position: absolute;
            bottom: 64px;
            width: 100%;
            margin-top: 8px;
            text-align: center;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 4px;
                border-radius: 50%;
                background: #c4c8d4;
                vertical-align: middle;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background: #0b83e6;
            }
        }
    }

    &.main-lypop__11talk {
        width: 800px;
        height: 600px;

        .swiper-container {
            overflow: hidden;
            position: relative;
            width: 798px;
            height: 568px;
            background: #fff;
            text-align: center;
        }
        .list {
            width: 20000px;
            height: 100%;
            margin: auto;

            li {
                position: relative;
                height: 100%;
                float: left;
            }
        }
        .info {
            width: 800px;
            height: 600px;
            background-size: 800px 600px;
            overflow: hidden;
            line-height: 1000px;

            &.info1 {
                background-image: url("/img/selleroffice/main_lypop/talk_1.png");
            }
            &.info2 {
                background-image: url("/img/selleroffice/main_lypop/talk_2.png");
            }
            &.info3 {
                background-image: url("/img/selleroffice/main_lypop/talk_3.png");
            }
            &.info4 {
                background-image: url("/img/selleroffice/main_lypop/talk_4.png");
            }
        }
        .btn_arrow {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateY(-48%);
        }
        .prev {
            left: 16px;

            &:after {
                @include ico-angle(left, 20px, #333, 1px, absolute);
            }
        }
        .next {
            right: 16px;

            &:after {
                @include ico-angle(right, 20px, #333, 1px, absolute);
            }
        }
        .swiper-button-disabled {
            opacity: 0.2;
        }
        .pagination {
            position: absolute;
            bottom: 30px;
            width: 100%;
            margin-top: 8px;
            text-align: center;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 4px;
                border-radius: 50%;
                background: #c4c8d4;
                vertical-align: middle;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background: #0b83e6;
            }
        }
        .btn-style__link {
            position: absolute;
            width: 180px;
            height: 48px;
            bottom: 87px;
            left: 50%;
            margin-left: -90px;
            padding: 0 15px;
            background-color: $color-11st-red;
            color: #fff;
            font-size: 16px;
            line-height: 48px;
            text-align: center;
            text-decoration: none;
        }
    }

    &.main-lypop__store {
        width: 800px;
        height: 600px;

        .swiper-container {
            overflow: hidden;
            position: relative;
            width: 798px;
            height: 568px;
            background: #fff;
            text-align: center;
        }

        .list {
            width: 20000px;
            height: 100%;
            margin: auto;

            li {
                position: relative;
                height: 100%;
                float: left;
            }
        }

        .info {
            width: 800px;
            height: 600px;
            background-size: 800px 600px;
            overflow: hidden;
            line-height: 1000px;

            &.info1 {
                background-image: url("/img/selleroffice/main_lypop/img_smart_01.jpg");
            }

            &.info2 {
                background-image: url("/img/selleroffice/main_lypop/img_smart_02.jpg");
            }

            &.info3 {
                background-image: url("/img/selleroffice/main_lypop/img_smart_03.jpg");
            }

            &.info4 {
                background-image: url("/img/selleroffice/main_lypop/img_smart_04.jpg");
            }

            &.info5 {
                background-image: url("/img/selleroffice/main_lypop/img_smart_05.jpg");
            }

            .btn_box {
                position: absolute;
                bottom: 70px;
                left: 0px;
                width: 100%;
                height: 56px;
                line-height: 56px;
                text-align: center;

                .btn-style__1,
                .btn-style__3 {
                    width: 200px;
                    height: 56px;
                    line-height: 53px;
                    font-size: 19px;
                    margin: 0 4px 0 5px;
                    font-weight: bold;
                }
            }
        }

        .btn_arrow {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateY(-48%);
        }

        .prev {
            left: 16px;

            &:after {
                @include ico-angle(left, 20px, #333, 1px, absolute);
            }
        }

        .next {
            right: 16px;

            &:after {
                @include ico-angle(right, 20px, #333, 1px, absolute);
            }
        }

        .swiper-button-disabled {
            opacity: 0.2;
        }

        .pagination {
            position: absolute;
            bottom: 36px;
            width: 100%;
            margin-top: 8px;
            text-align: center;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 4px;
                border-radius: 50%;
                background: #c4c8d4;
                vertical-align: middle;
                opacity: 1;
            }

            .swiper-pagination-bullet-active {
                background: #0b83e6;
            }
        }
    }
    &.main-lypop__event {
        width: 1000px;
        height: 697px;
        .pop {
            &__title {
                padding-top: 70px;
                font-family: "11StreetGothic";
                font-size: 38px;
                font-weight: 400;
                letter-spacing: -0.475px;
                text-align: center;
                color: $color-gray-01;
                &--red {
                    color: #f43142;
                }
            }
            &__board {
                margin-top: 25px;
                padding: 0 54px;
                box-sizing: border-box;
                text-align: center;
                &-ul {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 20px;
                }
                &-li {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 284px;
                    height: 320px;
                    border-radius: 10px;
                    background: #fafafa;
                }
                &--type {
                    margin-top: 20px;
                    padding: 4px 12px;
                    border-radius: 50px;
                    background: #fff;
                    color: $color-gray-03;
                    font-size: 13px;
                    font-weight: 700;
                }
                &--sub {
                    margin-top: 5px;
                    font-size: 17px;
                    font-weight: 700;
                    letter-spacing: -0.17px;
                    color: $color-gray-02;

                    + img {
                        width: 154px;
                        height: auto;
                        margin: 15px 0;
                    }
                }
                &--point {
                    color: #ff0038;
                }
                &--text {
                    color: #949494;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 400;
                }
                &--link {
                    display: block;
                    margin-top: 8px;
                    padding: 3px 8px 4px 8px;
                    border: 1px solid #ccc;
                    background: #fff;
                    color: #111;
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: -0.52px;
                    text-decoration: none;
                }
                &-dl {
                    margin-top: 20px;
                    text-align: left;
                }
                &-dt {
                    margin-bottom: 5px;
                    color: #666;
                    font-size: 13px;
                    font-weight: 700;
                }
                &-dd {
                    color: #949494;
                    font-size: 13px;
                    font-weight: 400;
                    a {
                        color: #0b83e6;
                        font-size: 13px;
                        font-weight: 400;
                        text-decoration-line: underline;
                    }
                }
                .btn-style__3 {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    padding: 12px 28px 13px 30px;
                    box-sizing: border-box;
                    svg {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.mov-pop {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    width: 800px;
    height: 804px;
    background: #f4f4f4;
    transform: translate(-50%, -50%);

    .pop-close {
        z-index: 50;
        position: absolute;
        top: 30px;
        right: 30px;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 200px;
        overflow: hidden;
        @include ico-cancel(27px, 1px, #a2a2a2, 6px);
    }

    .check-box {
        z-index: 50;
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
        height: 64px;
        width: 100%;

        .check-style__2 {
            margin: 12px 0 0 21px;

            label {
                color: #666;
            }
        }
    }

    .swiper-mov__box {
        h4 {
            display: block;
            overflow: hidden;
            line-height: 200px;
            font-size: 0;
            margin: 0 0 35px;
        }

        .swiper-mov {
            position: relative;
            height: 740px;
            width: 800px;
            overflow: hidden;

            .swiper-wrapper {
                position: relative;
                height: 100%;
                width: 100%;
                z-index: 1;
                display: flex;
                box-sizing: content-box;
            }

            .swiper-slide {
                position: relative;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                padding: 0 0 0 90px;
                box-sizing: border-box;
            }

            .slide-btn__prev,
            .slide-btn__next {
                z-index: 50;
                position: absolute;
                top: 68px;
                bottom: 68px;
                display: block;
                width: 60px;
                background: #f4f4f4;
                text-align: center;

                button {
                    position: absolute;
                    top: 50%;
                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-top: -20px;
                    overflow: hidden;
                    line-height: 200px;

                    &.swiper-button-disabled {
                        cursor: default;
                        opacity: 0.3;
                    }
                }
            }

            .slide-btn__prev {
                button {
                    left: 20px;

                    &:before {
                        display: block;
                        @include ico-angle(left, 24px, #666, 1px, absolute);
                        left: 25%;
                        top: 18px;
                    }
                }
            }

            .slide-btn__next {
                right: 0;
                width: 110px;

                button {
                    right: 20px;

                    &:before {
                        display: block;
                        @include ico-angle(right, 24px, #666, 1px, absolute);
                        right: 25%;
                        top: 18px;
                    }
                }
            }

            .mov-pop__info {
                h4 {
                    @include sprite($sp_main_pop_mov_tit_info);
                    margin: 0;
                }
            }

            .mov-pop__info2 {
                h4 {
                    @include sprite($sp_main_pop_mov_tit_info2);
                    margin-bottom: 23px;
                }

                .txt__p {
                    font-size: 15px;
                }
            }

            .mov-pop__info3 {
                h4 {
                    @include sprite($sp_main_pop_mov_tit_info3);
                }
            }

            .mov-pop__info4 {
                h4 {
                    @include sprite($sp_main_pop_mov_tit_info4);
                }

                .txt__p {
                    margin-bottom: 18px;
                }

                .btn-style__2 {
                    padding: 0 23px;
                    font-weight: bold;

                    &:hover {
                        background: #fff;
                    }

                    &:after {
                        display: inline-block;
                        margin-left: 10px;
                        @include ico-angle(right, 8px, #ff5462, 1px, static);
                    }
                }
            }

            .txt__p {
                font-size: 16px;
                color: #666;
                line-height: 24px;
                letter-spacing: -0.6px;

                strong {
                    display: inline;
                    font-weight: normal;
                    color: #111;
                    border-bottom: 1px solid #111;

                    em {
                        font-weight: bold;
                    }

                    &.txt-red {
                        color: $color-11st-red;
                        border-color: $color-11st-red;
                    }
                }
            }

            .txt__p-bg-red {
                mark {
                    display: inline;
                    padding: 0 9px 0 1px;
                    line-height: 24px;
                    font-size: 15px;
                    font-weight: bold;
                    background-color: rgba(244, 49, 66, 0.2);

                    strong {
                        color: $color-11st-red;
                    }
                }

                .txt__span-small {
                    display: block;
                    margin: 5px 0 24px 0;
                    font-size: 11px;
                    color: #666;
                    letter-spacing: -0.4px;
                }
            }
        }

        .mov-box {
            z-index: 60;
            position: absolute;
            top: 63px;
            right: 110px;
            width: 285px;
            height: 615px;
            border-radius: 40px;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
            background-color: #ffffff;

            &:before {
                content: "";
                position: absolute;
                top: 23px;
                left: 113px;
                display: block;
                width: 59px;
                height: 6px;
                opacity: 0.39;
                border-radius: 3.5px;
                background-color: #d8d8d8;
            }

            .mov-box__in {
                position: absolute;
                top: 45px;
                left: 20px;
                width: 245px;
                height: 538px;
                background: #f4f4f4;
                overflow: hidden;

                &:after {
                    content: "";
                    z-index: 10;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                }

                video {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    display: block;
                    width: auto;
                    height: 538px;
                    transform: translate(-50%, 0);
                }
            }
        }
    }
}

// 이미지클라우드 안내
.cloud-pop {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ddd;
    transform: translate(-50%, -50%);
    .pop-body {
        overflow: hidden;
        overflow-y: auto;
        max-height: 600px;
    }
    .pop-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 50;
        height: 64px;
        .check-box {
            margin: 12px 0 0 21px;
            label {
                color: #666;
                font-size: 13px;
            }
        }
    }
    .pop-close {
        position: absolute;
        z-index: 1;
        top: 40px;
        right: 40px;
        @include ico-cancel(24px, 1px, #9b9b9b, 3px);
        transform: translateZ(0);
    }

    &.cloud-pop__main {
        width: 800px;
        height: 100vh;
        max-height: 602px;

        .swiper-container {
            overflow: hidden;
            position: relative;
            width: 798px;
            height: 600px;
            background: #fff;
            text-align: center;
        }
        .list {
            width: 20000px;
            height: 100%;
            margin: auto;

            li {
                position: relative;
                height: 100%;
                float: left;
            }
        }
        .info {
            width: 800px;
            height: 600px;
            background-size: 800px 600px;
            overflow: hidden;
            line-height: 1000px;

            &.info1 {
                background-image: url("/img/selleroffice/renewal_main/main_cloud21.png");
            }
            &.info2 {
                background-image: url("/img/selleroffice/renewal_main/main_cloud12.png");
            }
            &.info3 {
                background-image: url("/img/selleroffice/renewal_main/main_cloud13.png");
            }
            &.info4 {
                background-image: url("/img/selleroffice/renewal_main/main_cloud14.png");
            }
        }
        .btn_arrow {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            overflow: hidden;
            line-height: 200px;
            transform: translateY(-48%);
        }
        .prev {
            left: 16px;

            &:after {
                @include ico-angle(left, 20px, #333, 1px, absolute);
                border-bottom: 0;
            }
        }
        .next {
            right: 16px;

            &:after {
                @include ico-angle(right, 20px, #333, 1px, absolute);
                border-bottom: 0;
            }
        }
        .info_more {
            .btn-style__link {
                position: absolute;
                bottom: 17px;
                left: 50%;
                margin-left: -78px;
                padding: 0 15px;
                background-color: #fff;
                border: 1px solid #0b83e6;
                color: #0b83e6;
                font-size: 13px;
                line-height: 30px;
                &:after {
                    display: inline-block;
                    height: 8px;
                    width: 8px;
                    border: 1px solid #0b83e6;
                    border-width: 0 1px 1px 0;
                    vertical-align: 1px;
                    transform: rotate(-45deg);
                    content: "";
                }
            }
        }
        .swiper-button-disabled {
            opacity: 0.2;
        }
        .pagination {
            position: absolute;
            bottom: 64px;
            width: 100%;
            margin-top: 8px;
            text-align: center;

            .swiper-pagination-bullet {
                display: inline-block;
                width: 8px;
                height: 8px;
                margin: 0 4px;
                border-radius: 50%;
                background: #c4c8d4;
                vertical-align: middle;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background: #0b83e6;
            }
        }
    }
}

// [팝업] 빠른정산 서비스
.calculate_guide_popup_wrap {
    .pop-body {
        max-height: 602px;
    }

    .pop-close {
        top: 9px;
        right: 18px;

        &:before,
        &:after {
            width: 35px;
            height: 2px;
        }
    }
}

// 넛징
.main-nudge {
    position: relative;
    width: 100%;
    height: 52px;
    background-color: #f3f9fe !important;
    overflow: hidden;

    &__item {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: 52px;
        font-size: 14px;
        line-height: 21px;

        padding: 0 12px 0 20px;
    }

    &__title {
        max-width: 26%;
        font-weight: 700;
        @include text-ellipsis();

        em {
            color: $color-11st-red;
        }
    }

    &__info {
        @include text-ellipsis();
        max-width: 74%;

        &::before {
            display: inline-block;
            width: 1px;
            height: 16px;
            margin: -2px 12px 0;
            background-color: $color-gray-01;
            vertical-align: middle;
            content: "";
        }
    }

    &__link {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: 100%;
        max-width: 100%;
        font-size: 14px;
        line-height: 21px;
        color: $color-gray-01;
    }
    &__img {
        position: absolute;
        inset: 0 0 0 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            vertical-align: top;
        }
    }

    &--rolling {
        .main-nudge {
            &__item {
                position: absolute;
                padding: 0 12px 0 20px;
                top: -52px;
                left: 0;

                //이전
                &.rolling-prev {
                    top: -52px;
                    left: 0;
                    transition: top 0.6s ease;
                }
                //현재
                &.rolling-current {
                    top: 0;
                    transition: top 0.6s ease;
                }
                //다음
                &.rolling-next {
                    top: 52px;
                }
            }

            &__title {
                em {
                    color: $color-gray-01;
                }
            }
        }

        @mixin main-nudge-gradient-bg($deg) {
            position: absolute;
            z-index: 1;
            left: 0;
            width: 100%;
            height: 14px;
            background: linear-gradient(#{$deg}deg, #f3f9fe 0%, rgba(243, 249, 254, 0) 100%);
            content: "";
        }
        &::before {
            @include main-nudge-gradient-bg(180);
            top: 0;
        }
        &::after {
            @include main-nudge-gradient-bg(360);
            bottom: 0;
        }
    }
}
